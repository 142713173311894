import React from 'react';

import { 
  Box,
  Skeleton
} from '@mui/material';

import Linkify from 'react-linkify';
import { emojify } from '../../utils/emojii';

export default function LoadableText(props) {
  return (
    <Box data-testid={props.dataTestId}>
      {props.isLoading && 
      <Box sx={{ minWidth: props.minWidth ?? 0 }}>
        <Skeleton />
      </Box> 
      }
      {!props.isLoading && props.text &&
      <Linkify properties={{ target: '_blank' }}>
        {emojify(props.text)}
      </Linkify>
      }
    </Box>
  );
}