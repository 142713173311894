import React, { useCallback, useContext, useEffect, useState } from 'react';

import { 
  Box,
  Divider,
  Typography,
  Stack,
  Card
} from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import ForumIcon from '@mui/icons-material/Forum';

import { useHistory } from 'react-router-dom';

import { ServicesContext, UserContext } from '@context';
import { sortByUpdatedAt } from '@helpers/sorter';
import { LoadableList, LoadableText } from '@components/atoms';
import { firstName } from '@helpers/text';

import { lightBlue } from '@mui/material/colors';

export default function CommunityFeed(props){
  const COMMUNITY_SECTION_HEIGHT_IN_PX = 53;
  const { height } = props;
  var history = useHistory();
  var { user, getUserToken } = useContext(UserContext);
  var { api } = useContext(ServicesContext);
  const [isLoading, setIsLoading] = useState(true);
  var [ numberOfRows, setNumberOfRows ] = useState(2);
  const [feed, setFeed] = useState([]);

  const loadFeed = async () => {
    setIsLoading(true);
    var result = await api.get({ url: 'community/feed', getUserToken });
    setFeed(sortByUpdatedAt(result.results));
    setIsLoading(false);
    
  };

  useEffect(() => {
    if(user?.id){
      loadFeed();
    }
  }, [user]);

  useEffect(() => {
    if(height > 0){
      // Minus Heading and Padding
      setNumberOfRows((height - 26 - 16) / COMMUNITY_SECTION_HEIGHT_IN_PX);
    }
  }, [height]);

  const getIcon = (feedResult) => {
    if(feedResult.type === 'COMMENT')
      return <InsertCommentIcon sx={{ color: 'primary.main' }}/>;
    if(feedResult.type === 'LIKE')
      return <ThumbUpIcon sx={{ color: lightBlue[500] }}/>;
    if(feedResult.type === 'POST')
      return <ForumIcon sx={{ color: 'warning.light' }}/>;
  };

  const FeedRow = (props) => {
    const { feedResult } = props;

    const handleClick = () => {
      if(feedResult.type == 'POST'){
        if(feedResult.object.metadata?.type == 'NEW_TOPIC'){
          history.push(`community?topicId=${feedResult.object.metadata.topicId}`);
        }else{
          history.push(`community?checkInId=${feedResult.id}`);
        }
      }
      if(feedResult.type == 'COMMENT'){
        history.push(`community?checkInId=${feedResult.object.parentId}`);
      }
      if(feedResult.type == 'LIKE'){
        var postId = null;
        if(feedResult.object.parentType == 'COMMENT'){
          postId = feed.filter(fr => fr.id == feedResult.object.parentId)[0].object.parentId;
        }else{
          postId = feedResult.object.parentId;
        }
        history.push(`community?checkInId=${postId}`);
      }
    };

    var message = <></>;

    switch(feedResult.type){
    case 'COMMENT':
      message = <>{`${firstName(feedResult.object?.user?.name)} just commented on a post!`}</>;
      break;
    case 'LIKE':
      message = <>{`${firstName(feedResult.object?.user?.name)} just liked a post!`}</>;
      break;
    case 'POST':
      if(feedResult.object.metadata?.type == 'NEW_TOPIC'){
        message = <>{`${firstName(feedResult.object?.user?.name)} just created a new discussion!`}</>;
      }else{
        message = <>{`${firstName(feedResult.object?.user?.name)} just posted in the community!`}</>;
      }
      break;

    }

    return (
      <Card variant='outlined' sx={{ p: 1, display: 'flex', alignItems: 'middle' }} onClick={handleClick}>
        <Box sx={{ lineHeight: 1 }}>{getIcon(feedResult)}</Box>
        <Box sx={{ paddingLeft: 1, flexGrow: 1 }}>
          <LoadableText isLoading={isLoading} text={message}/>
        </Box>
      </Card>
    );
  };

  return (
    <Box p={1}
      paddingTop={0}
      sx={{ display: 'flex', flexDirection: 'column', height: '100%', maxHeight: '100%' }}
    >
      <Box sx={{ display : 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 1 }}>
        <Typography onClick={() => history.push('/community')} variant='h6' sx={{ lineHeight: 1, flexGrow: 1, fontWeight:'medium' }}>Brace Community</Typography>
      </Box>
      <Divider flexItem/>
      <Stack spacing={1}
        p={1}
        id='feed-container'
        sx={{ flexGrow: 1 }}>
        <LoadableList 
          numberOfRows={2}
          isLoading={isLoading}
          rows={feed.slice(0, numberOfRows)}
          getRow={(feedResult) => <FeedRow key={feedResult?.id} feedResult={feedResult} /> } />
      </Stack>
    </Box>
      
  );
}

