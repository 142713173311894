import React, { useState } from 'react';

import { 
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

import CourseContentStep from './step';
import { isStepComplete, isFinalStep } from '../helpers';

export default function CourseContent(props) {
  const { course, completeStep } = props;
  const [openStep, setOpenStep] = useState(null);

  return (
    <Box sx={{ p: 1 }}>
      {course?.content?.steps.map(step => (
        <Accordion key={step.number}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Box marginRight={0.5}>
              {isStepComplete(course, step.number) ? <CheckCircleIcon color='primary' /> : <RadioButtonUncheckedIcon color='primary'  />}
            </Box>
            {step.title}
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              {step.description}
            </Box>
            <Box sx={{ display: 'flex', width: '100%', flexDirection:'row-reverse' }}>
              <Button size='small' variant='contained' onClick={() => setOpenStep(step.number)}>Open</Button>
            </Box>
           
          </AccordionDetails>
        </Accordion>
      ))}
      <CourseContentStep 
        completeStep={() => {
          if(!isStepComplete(course, openStep)){
            completeStep(openStep);
          }
          
          if(isFinalStep(course, openStep)){
            setOpenStep(null);
          }else{
            setOpenStep(openStep + 1);
          }
          
        }}
        course={course}
        openStep={openStep}
        close={() => setOpenStep(null)}/>
    </Box>
  );
}