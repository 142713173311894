import React, { useContext } from 'react';

import {
  Box
} from '@mui/material';
import { Paywall } from '@components/templates';
import { ServicesContext } from '@context';
import { useHistory } from 'react-router-dom';
import { FeaturesContext } from '@context/features';
import { useDispatch } from 'react-redux';
import { UserContext } from '@context/user';
import { deleteAccount } from './../../../../actions/users';
import { EventsContext } from '@context/events';

export default function Step3(){
  var { handleLogout } = useContext(UserContext);
  var { payments } = useContext(ServicesContext);
  var { isFeatureEnabled } = useContext(FeaturesContext);
  var { trackEvent } = useContext(EventsContext);
  var history = useHistory();
  var dispatch = useDispatch();

  if(!payments.isAvailable() || !isFeatureEnabled('billing')){
    history.push('/');
    return <></>;
  }
  
  const handleDelete = (feedback) => {
    trackEvent('Account:Deleted');
    return dispatch(deleteAccount(feedback))
      .then(() => {
        handleLogout(() => history.push('/'));
      });
  };

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Paywall handleDelete={handleDelete}/>
    </Box>
  );
}

