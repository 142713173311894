import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { 
  Card,
  Stack,
  Box,
  Typography,
  TableContainer,
  TableRow,
  TableHead,
  TableCell,
  Table,
  TableBody,
  Checkbox,
  IconButton,
  Button
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { svgToImage } from './../../../../utils/images';
import Page from '../../../../components/page/page';
import SubPage from '../../../../components/page/sub-page';
import { UserContext, ServicesContext } from '@context';
import { useParams } from 'react-router-dom';
import { toShortDateString } from '@helpers/dates';
import { titleCase } from '@helpers/text';
import { LoadableText } from '@components/atoms';
import { ConfirmationModal, ExerciseInfoDialog } from '@components/molecules';
import { useHistory } from 'react-router-dom';
import { EventsContext } from '@context/events';
import { useDispatch } from 'react-redux';
import { openSuccessModal, closeModal } from './../../../../actions/modal';
import { NewPostContext } from '@components/organisms';

const translateMeasurementType = (measurementType) => {
  switch(measurementType){
  case 'SECONDS':
    return 'Sec';
  case 'MINUTES':
    return 'Min';
  case 'METRES':
    return 'M';
  case 'KILOMETRES':
    return 'KM';
  case 'WEIGHT':
  default:
    return 'KG';
  }
};

export default function ViewSession(){
  let { id } = useParams();
  const { getUserToken } = useContext(UserContext);
  const { trackEvent } = useContext(EventsContext);
  const { openNewPostDialog } = useContext(NewPostContext);
  var dispatch = useDispatch();
  const history = useHistory();
  const { api } = useContext(ServicesContext);
  const [session, setSession] = useState(null);
  const [isLoadingSession, setIsLoadingSession] = useState(true);
  const [shouldShowDeletedSessionDialog, setShouldShowDeletedSessionDialog] = useState(false);
  const [hasSessionBeenCompleted, setHasSessionBeenCompleted] = useState(null);

  const savePost = async (data) => {
    const formData = new FormData();
    formData.append('message', data.message);

    if(data.image){
      formData.append('image', data.image);
    }

    if(data.organisation){
      formData.append('organisationId', data.organisation.id);
    }

    if(data.team){
      var teamIds = [ data.team.id ];
      formData.append('teamIds', teamIds);
    }

    await api.post({ data: formData, url: 'community/posts', getUserToken });
    dispatch(closeModal());
  };

  const openCreatePost = (i) => {
    openNewPostDialog({ 
      title: 'New Check-In', 
      placeholder: 'Your Post..', 
      buttonAction: savePost, 
      successToastMessage : 'Check-in Created!', 
      isAccessControlEnabled: true,
      isPrivateEnabled: true,
      initialImage: i
    });
  };

  const displayCompleteModal = () => {
    dispatch(openSuccessModal('Plan Completed For Today',  (<><p>Well done - you are now one step closer to your recovery goal!</p><p>Why not post about it to the Brace community?</p></>), 'confetti', 
      { text: 'Post To Community', 
        onClick: () => {
          axios({
            method: 'get',
            url: 'https://bracesocialprod.blob.core.windows.net/static/testing_svg.svg'
          })
            .then(async(response) => {
              var svg = response.data.replace('$$PLAN_NAME_GOES_HERE$$', session.plan.planName);
              var i = await svgToImage({ svg, mimetype: 'image/png', quality: 1, outputFormat: 'blob' });

              openCreatePost(i);
            });

        } }));
  };

  const loadSession = async () => {
    setIsLoadingSession(true);
    var result = await api.get({ url: `recovery/sessions/${id}`, getUserToken });
    setSession(result);
    setIsLoadingSession(false);
  };

  const deleteSession = async () => {
    await api.delete({ url: `recovery/sessions/${id}`, getUserToken });
    history.goBack();
  };

  const completeSet = async (exerciseId, setNumber) => {
    var result = await api.put({ url: `recovery/sessions/${id}/exercises/${exerciseId}/sets/${setNumber}/complete`, getUserToken });
    setSession(result);
  };

  const completeAll = async () => {
    var result = await api.put({ url: `recovery/sessions/${id}/exercises/completeAll`, getUserToken });
    setSession(result);
  };

  useEffect(() => {
    if(id){
      trackEvent('Recovery:Sessions:View');
      loadSession();
    }

  }, [id]);

  useEffect(() => {
    if(session){
      if(hasSessionBeenCompleted == null){
        setHasSessionBeenCompleted(session.plan.isComplete);
      }else if(hasSessionBeenCompleted == false && session.plan.isComplete){
        setHasSessionBeenCompleted(true);
        displayCompleteModal();
      }
    }
  }, [session]);

  const Exercise = (props) => {
    const { exercise } = props;
    return (
      <Card sx={{ p: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography fontWeight='Medium' component='div' sx={{ flexGrow: 1 }}>
            <LoadableText text={ titleCase(exercise.definition.name)} isLoading={isLoadingSession}/>
          </Typography>
          <ExerciseInfoDialog exercise={exercise.definition} disabled={isLoadingSession}/>
        </Box> 
        <TableContainer>
          <Table  
            size="small"
            aria-label="a dense table"   
            sx={{
              ['& .MuiTableCell-root']: {
                borderBottom: 'none'
              }
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">Set</TableCell>
                <TableCell align="center">Reps</TableCell>
                <TableCell align="center"> {translateMeasurementType(exercise.measurementType)}</TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {exercise.sets.map((set) => 
                <TableRow key={set.number}>
                  <TableCell align="center" sx={{ fontSize: '1rem' }}>{set.number}</TableCell>
                  <TableCell align="center" sx={{ fontSize: '1rem' }}>{set.quantity}</TableCell>
                  <TableCell align="center" sx={{ fontSize: '1rem' }}>{set.measurement}</TableCell>
                  <TableCell align="center" sx={{ fontSize: '1rem' }}>       
                    <Checkbox
                      sx={{ p: 0 }}
                      checked={set.isComplete}
                      onChange={() => completeSet(exercise.definition.id, set.number)}
                      inputProps={{ 'aria-label': 'controlled', 'data-testid':`recovery-view-session-set-checkbox-${exercise.id}-${set.number}` }}
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<CheckCircleIcon />}
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {exercise.notes && 
            <Card variant='outlined' sx={{ border: 'none' }}>
              <Typography variant='caption' color='text.secondary'>Exercise Notes</Typography>
              <Typography component='div'
                sx={{ pre: { 
                  fontSize: 'inherit',
                  color: 'inherit',
                  border: 'initial',
                  padding: 'initial',
                  fontFamily: 'inherit',
                  margin: 0
                } }  }>
                <pre>
                  <LoadableText isLoading={isLoadingSession}
                    text={exercise?.notes}
                    sx={{
                      overflowX: 'auto',
                      whiteSpace: 'pre-wrap',
                      wordWrap: 'break-word'
                    }} />
                </pre>
              </Typography>
            </Card>
        }
        {}
      </Card>
    );
  };

  return (
    <Page showMenu={true}>
      <SubPage 
        title={`${session?.plan.planName} - ${toShortDateString(session?.plannedStartDate)} `}
        isLoading={isLoadingSession}
        isBackButtonEnabled={true}
        endButton={
          <IconButton  data-testid='recovery-view-session-delete-button'
            sx={{ px: 1 }}
            disabled={isLoadingSession}
            onClick={() => {setShouldShowDeletedSessionDialog(true);}}>
            <DeleteIcon/>
          </IconButton>
        }
      >
        <Stack spacing={1} p={1} sx={{ paddingTop: 2, marginBottom: '120px' }}>
          {session?.plan.exercises.map(exercise => 
            <Exercise exercise={exercise} key={exercise.definition.id}/>
          )}
        </Stack>
      </SubPage>
      {(session && !session?.plan.isComplete) &&
        <Box sx={{ 
          position: 'fixed', 
          bottom: 'calc(60px + env(safe-area-inset-bottom))',
          paddingBottom: 1,
          width: '100%',
          display: 'flex',
          justifyContent: 'center'
        }}>
          <Button 
            data-testid='recovery-view-session-complete-all-button'
            size="large"
            onClick={completeAll}
            variant='contained'
            color='primary'>
              Complete All
          </Button>
        </Box>
      }
      <ConfirmationModal 
        isOpen={shouldShowDeletedSessionDialog} 
        title='Delete session?' 
        message={'If you delete this session you will no longer be able to complete it and it will be removed from your metrics'}
        cancelText={'Cancel'}
        handleClose={() => {
          setShouldShowDeletedSessionDialog(false);
        }}
        handleClickOutsideModal={() => {
          setShouldShowDeletedSessionDialog(false);
        }}
        confirmText={'DELETE'}
        isDeleteAction={true}
        confirmationSuccessMessage={'Session successfully deleted."'}
        handleConfirmation={() => deleteSession()} />
    </Page>
  );
}

