import React, { useContext, useRef, useState } from 'react';

import { 
  Box, 
  Typography
} from '@mui/material';
import { RecoveryContext } from '@context';
import { useTheme } from '@emotion/react';
import { LoadableText } from '@components/atoms';
import moment from 'moment';

export default function DayScroller(){
  const ANIMATION_TIME_IN_SECONDS = 0.5;
  const BOX_WIDTH_VW = 13;
  const theme = useTheme();
  const { activeDay, moveActiveDay, isLoadingActiveDaysSessions } = useContext(RecoveryContext);
  const [ animationDirection, setAnimationDirection ] = useState(null);
  const animationMagnitude = useRef(1);

  const getDayOfWeek = (dayDiff) => {
    return activeDay.clone().add(dayDiff, 'd').format('ddd').toUpperCase();
  };

  const getDateNumber = (dayDiff) => {
    return activeDay.clone().add(dayDiff, 'd').date();
  };

  const handleClick = (dayDiff) => {
    animationMagnitude.current = Math.abs(dayDiff);
    if(dayDiff != 0 && !isLoadingActiveDaysSessions){
      moveActiveDay(activeDay?.add(dayDiff, 'DAYS'));
    
      setAnimationDirection(dayDiff > 0 ? 'right' : 'left');
      setTimeout(() => {
        setAnimationDirection(null);
      }, ANIMATION_TIME_IN_SECONDS * 1000);
    }
  };

  return (
    <Box sx={{ width: '100%', overflowX: 'hidden'   }}>
      <Box sx={{ 
        display: 'flex', 
        textAlign: 'center', 
        justifyContent: 'space-around',
        position: 'relative',
        width: `${BOX_WIDTH_VW * 15}vw`,
        paddingBottom: 0.5,
        marginLeft: `-${(BOX_WIDTH_VW * 15 - 100) / 2}vw`,
        animation: animationDirection ? 
          animationDirection == 'right' ? `moveRight${animationMagnitude.current} ${ANIMATION_TIME_IN_SECONDS * 0.9}s` : `moveLeft${animationMagnitude.current} ${ANIMATION_TIME_IN_SECONDS * 0.9}s`
          : 'none',
        '@keyframes moveLeft1': {
          from : { left: '0' },
          to : { left: `${1 * BOX_WIDTH_VW}vw` }
        },
        '@keyframes moveLeft2': {
          from : { left: '0' },
          to : { left: `${2 * BOX_WIDTH_VW}vw` }
        },
        '@keyframes moveLeft3': {
          from : { left: '0' },
          to : { left: `${3 * BOX_WIDTH_VW}vw` }
        },
        '@keyframes moveLeft4': {
          from : { left: '0' },
          to : { left: `${4 * BOX_WIDTH_VW}vw` }
        },
        '@keyframes moveRight1': {
          from : { left: `${1 * BOX_WIDTH_VW}vw` },
          to : { left: '0' }
        },
        '@keyframes moveRight2': {
          from : { left: `${2 * BOX_WIDTH_VW}vw` },
          to : { left: '0' }
        },
        '@keyframes moveRight3': {
          from : { left: `${3 * BOX_WIDTH_VW}vw` },
          to : { left: '0' }
        } ,
        '@keyframes moveRight4': {
          from : { left: `${4 * BOX_WIDTH_VW}vw` },
          to : { left: '0' }
        } 
      }}>
        { [-7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7].map((value) => {
          var showToday = !isLoadingActiveDaysSessions &&
          !animationDirection &&
          activeDay.clone().add(value, 'd').isSame(moment(), 'day');

          return (
            <Box    
              sx={{
                width: `${BOX_WIDTH_VW}vw`,
                height: `${BOX_WIDTH_VW}vw`,
                p: '2px'
              }}
              key={value}>
              <Box 
                sx={{
                  height: '100%',
                  width: '100%',
                  textAlign: 'center',
                  p: 1, 
                  borderRadius: '100%', 
                  border: `${showToday ? 2 : 1}px solid ${showToday ? theme.palette.primary.main : theme.palette.text.primary}`, 
                  display: 'flex', 
                  flexDirection: 'column', 
                  justifyContent: 'center',
                  opacity: 1 - Math.abs(value) * 0.15
                }}
              >
                <Typography component='div' variant='body2' sx={{ fontWeight: 'medium', lineHeight: 1 }}>
                  <LoadableText isLoading={isLoadingActiveDaysSessions || animationDirection} text={getDateNumber(value)}></LoadableText>
                  {/* {getDateNumber(value)} */}
                </Typography>
                <Typography 
                  component='div' 
                  variant='caption' 
                  sx={{ lineHeight: 1 }}
                  onClick={() => handleClick(value)} >
                  <LoadableText isLoading={isLoadingActiveDaysSessions || animationDirection} text={getDayOfWeek(value)}></LoadableText>
                  {/* {getDayOfWeek(value)} */}
              
                </Typography>
              </Box>
            </Box>
          );})}
      </Box>
    </Box>
  );
}

