import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { 
  Box,
  Stack,
  Divider,
  Slide,
  useScrollTrigger,
  IconButton,
  Typography
} from '@mui/material';
import { useTheme } from '@emotion/react';

import MenuIcon from '@mui/icons-material/Menu';

import MenuDrawerContainer from './menu-drawer';
import { UserContext } from '@context';

import { ProfileImage } from '@components/molecules';
import { Icon } from '@components/v2/atoms';
import { useHistory } from 'react-router-dom';
import { BillingContext } from '@context/billing';
import { AccountOnboardingContext } from '@context/account-onboarding';
import { DialogContext } from '@context/dialogs';

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

export default function TopBar(props) {
  const { shouldShowFeedbackRequest } = props;
  const { user } = useContext(UserContext);
  const { accountOnboarding } = useContext(AccountOnboardingContext);
  const { subscription, isAvailable } = useContext(BillingContext);
  const { openFeedbackDialog } = useContext(DialogContext);
  const theme = useTheme();
  const reduxUser = useSelector(state => state.user);
  const notificationCount = useSelector(state => state.notifications.notifications.filter(n => !n.hasBeenRead)).length;
  const history = useHistory();
  
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  return (
    <>
      <HideOnScroll>
        <Box sx={{
          position: 'fixed',
          top: 'env(safe-area-inset-top)',
          width: '100%',
          zIndex: 1100
        }}>
          <Stack 
            direction="row"
            sx={{
              position: 'relative',
              justifyContent: 'space-between',
              width:'100%',
              padding: 1,
              backgroundColor: '#fff',
              alignItems: 'center' }}>
            <ProfileImage 
              isPro={accountOnboarding?.isComplete}
              badgeCount={notificationCount}
              user={user}
              isThisUser={true}
              lastMod={reduxUser.userImageModified }
              onClick={() => {setIsMenuOpen(!isMenuOpen);}}
              dataTestId='menu-drawer-button-open'
              icon={<MenuIcon style={{ fontSize: 28 }} color='action' />}
            />
            {shouldShowFeedbackRequest &&
              <Box sx={{ display: 'flex', alignItems: 'center', color: theme.palette.braceGreenDark.main }}
                onClick={() => openFeedbackDialog()}>
                <Icon type='svg/chat'
                  size={22}
                  primaryColor={theme?.palette.braceGreenDark.main}
                  sx={{ marginRight: 0.25 }}/>
                <Typography variant='body2' paddingTop={'2px'} fontWeight='medium'>Have Feedback? Let Us Know Here</Typography>
              </Box>
            }
            <Box sx={{ width: 36 }}>
              {(subscription?.isTrial && isAvailable) &&
                <Box>
                  <IconButton onClick={() => history.push('paywall')}>
                    <Icon type='svg/crown' size={24} primaryColor={theme?.palette.braceGreenDark.main}/>
                  </IconButton>
                </Box>
              }
            </Box>
          </Stack>
          <Divider />
        </Box>
      </HideOnScroll>
      <MenuDrawerContainer isMenuOpen={isMenuOpen} closeMenu={() => setIsMenuOpen(false)}/>
    </>
  );
}

