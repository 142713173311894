import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Dialog,
  Box,
  Stack,
  Slide,
  Divider,
  InputBase,
  Link,
  FormGroup,
  FormControlLabel,
  Switch

} from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';

import { useSnackbar } from 'notistack';

import { closeNewPostModal } from './../../actions/modal';

import CloseIcon from '@mui/icons-material/Close';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

import Avatar from './../avatar/avatar';
import ImageUploader from './../images/image-uploader';
import { UserContext } from '@context/user';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function NewPostModal(props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { activeOrganisation, user } = useContext(UserContext);
  const modalState = useSelector(state => state.modal.newPostModal);
  const [title, setTitle] = React.useState('');
  const [value, setValue] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [image, setImage] = React.useState(null);
  const [organisation, setOrganisation] = React.useState(null);
  const [group, setGroups] = React.useState(null);

  React.useEffect(() => {
    setValue('');
    setTitle('');

    if(modalState?.metadata?.initialImage){
      setImage(modalState?.metadata?.initialImage);
    }

  }, [modalState.isOpen]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const close = () => {
    dispatch(closeNewPostModal());
  };

  const toggleOrganisation = () => {
    setOrganisation(organisation ? null : activeOrganisation);
  };

  const submit = () => {
    setIsLoading(true);
    modalState.action({ message: value, title, metadata: modalState.metadata, image, organisation, group })
      .then(() => {
        enqueueSnackbar(modalState.metadata.successMessage, { 
          variant: 'success'
        });
        setIsLoading(false);
        setValue('');
        setTitle('');
        setImage(null);
        close();
      })
      .catch(err => {
        setIsLoading(false);
      });
  };

  const saveImage = (image) => {
    setImage(image);
  };

  return (
    <div>
      <Dialog
        open={modalState.isOpen}
        fullScreen
        TransitionComponent={Transition}
        onClose={close}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Box sx={{
          p: 1, 
          display: 'flex',
          justifyContent: 'space-between', 
          alignItems: 'center',
          backgroundColor: '#FFFFFF',
          marginTop: 'env(safe-area-inset-top)'
        }} >
          <CloseIcon onClick={close} style={{ fontSize: 32 }} color="action" />
          <Box>
            <LoadingButton 
              disabled={!(value !== '' || image != null) || (modalState.metadata?.showTitle && title === '') }
              loading={isLoading}
              onClick={submit}
              size='small'
              variant='contained'
              color='secondary'>
              {modalState.metadata?.actionName ?? 'Create'}
            </LoadingButton>
          </Box>
        </Box>
        <Divider light/>
        <Stack sx={{ p: 2, paddingBottom: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar userId={user?.id} />
              <Box sx={{ paddingLeft: 1 }}>
                <Box sx={{ typography: 'h5', fontWeight: 'medium', lineHeight: 1.1 }}>
                  {user?.name}
                </Box>
                {modalState.metadata?.replyingTo &&
                  <Box sx={{ typography: 'body2', color: 'text.secondary', lineHeight: 1.1 }}>
                    Reply to <Link sx={{ textDecoration: 'none', color: '#2196F3' }} >{`@${modalState.metadata.replyingTo?.name}`}</Link>
                  </Box>
                }
              </Box>
            </Box>
            <Box>
              {modalState.metadata?.isImageEnabled &&
                <ImageUploader isEnabled={true} saveImage={saveImage}>
                  <CameraAltIcon color={'primary'} fontSize="large" />
                </ImageUploader>
              }
            </Box>
          </Box>
        </Stack>
        {modalState.metadata?.isPrivateEnabled &&
         <Box px={2} width='100%'>
            Privacy Options
         </Box>
        }
        {/* {(modalState.metadata?.isPrivateEnabled && activeOrganisation != null) &&
          <Box px={2}>
            <FormGroup size='small'>
              <FormControlLabel control={<Switch checked={organisation != null} onClick={toggleOrganisation} />} label={`Private To ${activeOrganisation.name}`} />
            </FormGroup>
          </Box>
        } */}
        {modalState.metadata?.showTitle &&
          <Box px={2} py={1} width='100%'>
            <InputBase
              fullWidth
              multiline
              autoFocus={modalState.metadata?.showTitle}
              sx={{ color: 'text.primary', flexGrow: 1 }}
              placeholder={modalState.metadata?.titlePlaceholder}
              value={title}
              onChange={handleTitleChange} />
            <Divider light />
          </Box>
        }
        <Box px={2} width='100%'>
          <InputBase
            fullWidth
            multiline
            autoFocus={!modalState.metadata?.showTitle}
            sx={{ color: 'text.primary', flexGrow: 1 }}
            placeholder={modalState.metadata?.placeholder}
            value={value}
            onChange={handleChange} />
        </Box>
        {image &&
          <Box px={2} width='100%'>
            <img style= {{ width: '100%' }} src={URL.createObjectURL(image)} />
          </Box>
        }
      </Dialog>
    </div>
  );
}