import React, { useContext, useEffect } from 'react';

import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

import { 
} from '@mui/material';

import { loadUser } from '../../actions/users';

import { UserContext, BillingContext } from '@context';
import { Account } from '@components/templates';

export default function IndexContainer() {
  const { user, isLoading: isLoadingUser } = useContext(UserContext);
  const { checkBillingStatus, isLoading: isLoadingSubscription  } = useContext(BillingContext);
  var history = useHistory();
  var dispatch = useDispatch();

  useEffect(() => {
    if(user?.id){
      dispatch(loadUser());
    }
  }, [user]);

  useEffect(() => {
    if(!isLoadingSubscription){
      checkBillingStatus(history);
    }
  }, [isLoadingSubscription]);

  if(user && user.status != 'PENDING' && !isLoadingUser){
    history.push('/home');
  }

  return(
    <Account 
      doAfterClientSignUp = {() => {
        history.push('/onboarding/info');
      }}/>
  );
}