import React from 'react';

import {
  Box,
  Typography,
  Stack
}from '@mui/material';
import { 
  LocalizationProvider,
  DatePicker
} from '@mui/lab';
import AdapterMoment from '@mui/lab/AdapterMoment';

import { DaysOfWeekPicker } from '@components/molecules';

import { TextField } from '@components/atoms';

export default function Step2(props) {
  const { settings, handleChange } = props;

  const handleStartDateChange = (value) => {
    handleChange({
      ...settings,
      startDate: value
    });
  };

  const handleEndDateChange = (value) => {
    handleChange({
      ...settings,
      endDate: value
    });
  };

  return (
    <Box p={2} >
      <Typography variant='h6' fontWeight='normal' gutterBottom>
        Set Duration and Frequency
      </Typography>
      <Typography variant='body2'>
        Please select enter duration of program and frequency of this program.
      </Typography>
      <Box sx={{ py: 2 }}>
        <Stack direction='row' spacing={1} alignItems='center'>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label='Start Date'
              value={settings.startDate}
              onChange={handleStartDateChange}
              renderInput={(params) => <TextField {...params} size='small'/>}
            />
            <Typography variant='body2' color='text.secondary'>
              to
            </Typography>
            <DatePicker
              minDate={settings.startDate.clone().add(1, 'days')}
              label='End Date'
              value={settings.endDate}
              onChange={handleEndDateChange}
              renderInput={(params) => <TextField {...params} size='small'/>}
            />
          </LocalizationProvider>
        </Stack>
      </Box>
      <Typography variant='body2'>
        Session Days
      </Typography>
      <Box sx={{ py: 1 }}>
        <DaysOfWeekPicker 
          values={settings.daysOfWeek}
          onChange={(daysOfWeek) => {
            handleChange({
              ...settings,
              daysOfWeek
            });
          }}/>
      </Box>
      <Box sx={{ py: 1 }}>
        <TextField 
          inputProps={{ 'data-testid': 'recovery-create-program-sessions-per-day-input' }}
          type='number'     
          pattern="\d*"
          min="1"
          label='Number of Sessions Per Day'
          fullWidth
          value={settings.timesPerDay}
          onChange={(event) => handleChange({
            ...settings,
            timesPerDay: event.target.value
          })}/>
      </Box>
      
    </Box>
  );
}