import React from 'react';

import { 
  Box,
  Fab
} from '@mui/material';
  
import AddIcon from '@mui/icons-material/Add';

export default function FabButton(props) {
  return (
    <Box sx={{ 
      position: 'fixed',
      right: 4,
      bottom: 'calc(60px + env(safe-area-inset-bottom))'
    }}>
      <Fab size="large" 
        data-testid={props.dataTestId}
        color="secondary" 
        id="basic-button"
        onClick={props.onClick}>
        <AddIcon />
      </Fab>
    </Box>
  );
}