import ApiClient from './services/clients/api-client';
import CommunityApiClient from './services/clients/community-api-client';

import BlobClient from './services/clients/blob-client';
import { Logger } from './services/logging/logger';
import Storage from './services/storage';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import config from './config';

export default function createServices(){
  var services = {};

  const appInsights = new ApplicationInsights({ config: {
    connectionString: config.appInsightsKey,
    disableFetchTracking: false,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    correlationHeaderExcludedDomains: ['api-eu1.hubspot.com', 'api.mixpanel.com', 'cdn.contentful.com', 'bracesocial.eu.auth0.com', 'bracesocial-dev.eu.auth0.com', 'auth.bracesocial.com', 'events.statsigapi.net']
  } });
  appInsights.loadAppInsights();
  appInsights.trackPageView();

  var logger = new Logger(appInsights);
  logger.info(`Creating Services: v${config.version}`);
  
  services.blobClient = new BlobClient();
  services.storage = new Storage();
  services.logger = logger;

  services.apiClient = new ApiClient();
  services.communityApiClient = new CommunityApiClient();

  return services;
}