import React, { useContext, useEffect, useState } from 'react';

import { 
  Box,
  Card,
  IconButton,
  Typography,
  Checkbox,
  Divider
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import { ServicesContext, UserContext, AccountOnboardingContext } from '@context';
import { Icon, LoadableList, LoadableText, UserGoalText } from '@components/atoms';
import { CreateGoalDialog, NewPostContext } from '@components/organisms';
import { useDispatch } from 'react-redux';
import { closeModal, openSuccessModal } from './../../../actions/modal';
import moment from 'moment';
import { useLocation, useHistory } from 'react-router-dom';

export default function Goals(){
  var { user, getUserToken } = useContext(UserContext);
  var { accountOnboarding, loadAccountOnboarding } = useContext(AccountOnboardingContext);
  var { api } = useContext(ServicesContext);
  const [isLoadingGoals, setIsLoadingGoals] = useState(true);
  const [goals, setGoals] = useState([]);
  const [areGoalsExpanded, setAreGoalsExpanded] = useState(false);
  const [isCreateGoalDialogOpen, setIsCreateGoalDialogOpen] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(
    location.search
  );

  const loadGoals = async () => {
    setIsLoadingGoals(true);
    var result = await api.get({ url: 'goals', getUserToken });
    setGoals(result.results);
    setIsLoadingGoals(false);
  };

  function EmptySection(props){
    const { iconType, iconSize='100px', title, message, opacity, dataTestId } = props;
  
    return (
      <Card 
        data-testid={dataTestId}
        variant='outlined'
        sx={{ display: 'flex', alignItems: 'center', height: '100%', px: 2, py: 1, opacity }}
        onClick={openCreateGoalDialog}
      >
        <Icon type={iconType} size={iconSize} delay={1000}/>
        <Box sx={{ flexGrow: 1, paddingLeft: 2  }}>
          <Typography variant='body1'
            component='div'
            textAlign='center'
            fontWeight='medium'
            data-testid='recovery-plan-the-first-step'>
            <LoadableText isLoading={isLoadingGoals} text={title}/>
          </Typography>
          <Typography variant='body2' textAlign='center' component='div'>
            <LoadableText isLoading={isLoadingGoals} text={message}/>
          </Typography>
        </Box>
      </Card>
    );
  }

  const UserGoal = (props) => {
    const { user } = useContext(UserContext);
    const { api } = useContext(ServicesContext);
    const { openNewPostDialog } = useContext(NewPostContext);
    var dispatch = useDispatch();
    var { goal } = props;
  
    var [isCompleted, setIsCompleted] = useState(false);
  
    useEffect(() => {
      setIsCompleted(goal.isCompleted ?? false);
    }, [goal]);
  
    const savePost = async (data) => {
      const formData = new FormData();
      formData.append('message', data.message);
  
      if(data.image){
        formData.append('image', data.image);
      }
  
      if(data.organisation){
        formData.append('organisationId', data.organisation.id);
      }
  
      if(data.team){
        var teamIds = [ data.team.id ];
        formData.append('teamIds', teamIds);
      }
  
      await api.post({ data: formData, url: 'community/posts', user });
      dispatch(closeModal());
    };
  
    const openCreatePost = () => {
      openNewPostDialog({ 
        title: 'New Check-In', 
        placeholder: 'Your Post..', 
        buttonAction: savePost, 
        successToastMessage : 'Check-in Created!', 
        isAccessControlEnabled: true,
        isPrivateEnabled: true
      });
    };
  
    const handleOnClick = () => {
      if(!isCompleted){
        setIsCompleted(true);
        api.put({ user, url : `goals/${goal.id}/toggle` })
          .then(() => {
            dispatch(openSuccessModal('Goal Complete', (<><p>Well done on completing your recovery goal!</p><p>Celebrate your progress with the Brace Community!</p></>), 
              'target', { text: 'Post New Check-In', onClick: openCreatePost }));
          });
      }else{
        setIsCompleted(false);
        api.put({ user, url : `goals/${goal.id}/toggle` });
      }
    };
  
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox
          disabled={isLoadingGoals}
          onClick={handleOnClick}
          sx={{ p: 0 }}
          checked={isCompleted}
          icon={<RadioButtonUncheckedIcon fontSize='small' />}
          checkedIcon={<CheckCircleIcon fontSize='small' />} />

        <Box sx={{ flexGrow: 1 }}>
          <UserGoalText goal={goal} isLoading={isLoadingGoals}/>
        </Box>
       
      </Box>
    );
  };

  useEffect(() => {
    if(user?.id){
      loadGoals();
    }
    if(query.get('defaultNewGoalOpen') === 'true'){
      setIsCreateGoalDialogOpen(true);
      query.delete('defaultNewGoalOpen');
      history.replace({
        search: query.toString()
      });
    }
  }, [user]);

  useEffect(() => {
    if(query.get('defaultNewGoalOpen') === 'true'){
      setIsCreateGoalDialogOpen(true);
      query.delete('defaultNewGoalOpen');
      history.replace({
        search: query.toString()
      });
    }
  }, [query.get('defaultNewGoalOpen')]);

  const openCreateGoalDialog = () => {
    setIsCreateGoalDialogOpen(true);
  };

  const sortedGoals = goals.sort(function(a, b) {
    if(a?.isCompleted && !b?.isCompleted){
      return 1;
    }
    if(!a?.isCompleted && b?.isCompleted){
      return -1;
    }

    if(a.targetDate && !b.targetDate){
      return -1;
    }

    if(!a.targetDate && b.targetDate){
      return 1;
    }

    if(a.targetDate && b.targetDate){
      return moment(a.targetDate).valueOf() - moment(b.targetDate).valueOf();
    }

    return moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf();
  });

  return (
    <Box p={1} paddingTop={0}>
      <Box sx={{ display : 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 1 }}>
        <Typography onClick={openCreateGoalDialog} variant='h6' sx={{ lineHeight: 1, flexGrow: 1, fontWeight: 'medium' }}>My Goals</Typography>
        <IconButton 
          data-testid={'home-open-add-goal-dialog'} 
          onClick={openCreateGoalDialog}
          disabled={isLoadingGoals}
          sx={{ p: 0 }}>
          <AddCircleIcon color='primary'/>
        </IconButton>
      </Box>
      <Divider />
      {(!isLoadingGoals && goals.length == 0) && 
      <Box sx={{ p: 1 }}>
        <EmptySection 
          iconType='target'
          iconSize='50px'
          title='Set a Goal for Your Recovery Journey.'
        />
      </Box>
      
      }
      <Box sx={{ p: 1 }}>
        <LoadableList 
          numberOfRows={2}
          isLoading={isLoadingGoals}
          rows={sortedGoals.slice(0, areGoalsExpanded ? sortedGoals.length : 3)}
          getRow={(goal) => <UserGoal key={goal.id} goal={goal} /> }
        />
      </Box>
      {((!isLoadingGoals && sortedGoals.length > 3) && !areGoalsExpanded) &&
         <Box 
           sx={{ display : 'flex', flexDirection: 'column', alignItems: 'center', color: 'text.secondary' }}
           onClick={() => setAreGoalsExpanded(!areGoalsExpanded)}>
           
           <Typography sx={{ p: 0, lineHeight: 1 }} variant='caption'>Show More</Typography>
           <ExpandMoreIcon sx={{ p: 0, fontSize: '1rem' }} />
         </Box>
      }
      {(!isLoadingGoals && areGoalsExpanded) &&
            <Box 
              sx={{ display : 'flex', flexDirection: 'column', alignItems: 'center', color: 'text.secondary' }}
              onClick={() => setAreGoalsExpanded(!areGoalsExpanded)}>
              <ExpandLessIcon sx={{ p: 0, fontSize: '1rem' }} />
              <Typography sx={{ p: 0, lineHeight: 1 }} variant='caption'>Show Less</Typography>
           
            </Box>
      }
      <CreateGoalDialog 
        isOpen={isCreateGoalDialogOpen}
        close={(shouldRefresh) => {
          if(shouldRefresh){
            loadGoals();
            if(!accountOnboarding?.hasAddedRecoveryGoal){
              loadAccountOnboarding();
            }
          }

          setIsCreateGoalDialogOpen(false);
        }}
      />
    </Box>
  );
}

