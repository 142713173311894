import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { Link } from 'react-router-dom';

import moment from 'moment';

import { 
  Box,
  Grid,
  Stack,
  Avatar,
  Divider,
  Slide,
  Grow,
  TextField,
  MenuItem,
  FormGroup,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Select,
  InputLabel,
  Button,
  CircularProgress,
  Checkbox,
  Typography
} from '@mui/material';

import { 
  LocalizationProvider,
  DatePicker
} from '@mui/lab';

import AdapterMoment from '@mui/lab/AdapterMoment';

import CloseIcon from '@mui/icons-material/Close';

import { useFormik } from 'formik';
import * as yup from 'yup';

import { loadPlans, createProgram, createSession } from '../../../../../../actions/recovery';
import { openSuccessModal } from '../../../../../../actions/modal';
import { loadUser } from '../../../../../../actions/users';

import Page from '../../../../../../components/page/page';
import Card from '../../../../../../components/molecules/card';
import RadioCards from '../../../../../../components/molecules/radio-cards';

import { EventsContext } from '@context';

const DayCheckBox = (props) => {
  return (
    <Avatar
      sx={{ 
        bgcolor: props.checked ? 'secondary.main' : '#fafafa',
        color: props.checked ? '#fff' : 'secondary.light',
        border: props.checked ? 'none' : '1px solid',
        borderColor: 'secondary.light',
        fontSize: 12,
        margin: 0,
        height: 35,
        width: 35
      }}>
      {props.day}
    </Avatar>
  );
};

const buildDaysOfWeek = (values) => {
  var daysOfWeek = [];

  if(values.monday) daysOfWeek.push('MONDAY');
  if(values.tuesday) daysOfWeek.push('TUESDAY');
  if(values.wednesday) daysOfWeek.push('WEDNESDAY');
  if(values.thursday) daysOfWeek.push('THURSDAY');
  if(values.friday) daysOfWeek.push('FRIDAY');
  if(values.saturday) daysOfWeek.push('SATURDAY');
  if(values.sunday) daysOfWeek.push('SUNDAY');

  return daysOfWeek;
};

const IndexContainer = (props) => {
  var dispatch = useDispatch();
  let history = useHistory();
  var plans = useSelector(state => state.recovery.plans);
  var recovery = useSelector(state => state.recovery);
  const [success, setSuccess] = React.useState(false);
  const [scheduleType, setScheduleType] = React.useState('single-day');
  const [isToday, setIsToday] = React.useState(true);
  const [selectedDay, setSelectedDay] = React.useState(moment());
  const { trackEvent } = useContext(EventsContext);

  const handleScheduleTypeChange = (value) => {
    setScheduleType(value);
  };

  useEffect(() => {
    dispatch(loadUser())
      .then(() => {
        trackEvent('Recovery:ProgramCreator:Open:New');
        dispatch(loadPlans());
      });
  }, []);

  useEffect(() => {
    if(recovery?.currentDate){
      setSelectedDay(recovery?.currentDate);
      setIsToday(recovery?.currentDate.isSame(moment(), 'day'));
    }
  }, [recovery?.currentDate]);

  const getOptions = () => {
    return [
      { title: 'Single Day', subTitle: 'Add this exercise plan for one day only.', value: 'single-day', isSelected: scheduleType === 'single-day' },
      { title: 'Program', subTitle: 'Create a program to complete this exercise plan over multiple days.', value: 'program', isSelected: scheduleType === 'program' }
    ];
  };

  // call this function, passing-in your date
  function dateToFromNowDaily( myDate ) {
    // ensure the date is displayed with today and yesterday
    return moment( myDate ).calendar( null, {
      // when the date is closer, specify custom values
      lastWeek: '[Last] dddd',
      lastDay:  '[Yesterday]',
      sameDay:  '[Today]',
      nextDay:  '[Tomorrow]',
      nextWeek: 'dddd',
      // when the date is further away, use from-now functionality             
      sameElse: function () {
        return 'Do MMM';
      }
    });
  }

  const validationSchema = scheduleType === 'single-day' ?
    yup.object({
      selectedPlan: yup
        .string('Enter Select Your Plan')
        .required('Please Select Your Plan')
    }) : 
    yup.object({
      selectedPlan: yup
        .string('Enter Select Your Plan')
        .required('Please Select Your Plan'),
      programName: yup
        .string('Enter Select Your Program Name')
        .required('Please enter you program name'),
      timesPerDay: yup
        .number('Number of times per day')
        .min(1, 'Must be greater than zero')
    });

  const formik = useFormik({
    initialValues: {
      programName: '',
      selectedPlan: '',
      date: moment(),
      startDate: moment(),
      endDate: moment().add(7, 'days'),
      timesPerDay: 1,
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      formik.setSubmitting(true);

      if(scheduleType === 'single-day'){
        dispatch(createSession(values.selectedPlan, selectedDay))
          .then(program => {
            setSuccess(true);
            formik.setSubmitting(false);
            dispatch(openSuccessModal(`Plan Added for ${dateToFromNowDaily(selectedDay)}`, `You've added your plan for ${dateToFromNowDaily(selectedDay)}! Now find time to get it done and move one step closer to your recovery goal!`));
            history.goBack();
          });
      }else{
        dispatch(createProgram(values.programName, values.selectedPlan, values.startDate, values.endDate, buildDaysOfWeek(values), values.timesPerDay))
          .then(program => {
            setSuccess(true);
            formik.setSubmitting(false);
            dispatch(openSuccessModal('New Program Created', 'Best of luck with your new program! You can track your progress towards completing this on the home screen!'));
            history.goBack();
          });
      }
    }
  });

  return (
    <Page>
      <form onSubmit={formik.handleSubmit}>
        <Grid container
          p={1}
          display='flex'
          alignItems='center'>
          <Grid item xs={9}>
            <Stack direction='row'
              sx={{ 
                alignItems: 'center'
              }}>
              <CloseIcon onClick={() => history.goBack()} style={{ fontSize: 28 }} color="primary" />
            </Stack>
          </Grid>
          <Grid item
            xs={3}
            sx={{
              position: 'relative',
              width:'100%'
            }}>
            <Button id='button-sign-up'
              type="submit"
              size="small"
              color="secondary"
              variant='contained' 
              disabled={formik.isSubmitting} 
              sx={success ? {
                width:'100%',
                backgroundColor: 'success.main'
              }: {
                width:'100%'
              }}>
              Save
            </Button>
            {formik.isSubmitting && 
              <CircularProgress size={24}
                sx={{
                  color: 'success.main',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px'
                }} />
            }
          </Grid>
        </Grid>
        <Divider />
        <Stack direction='column'
          alignItems='center'
          width='100%'
          paddingTop={3}
          px={2}
          spacing={2}>
          <Card title='Exercise Plan' subTitle='Step 1: Select what exercise plan you would like to add.' sx={{ width: '100%' }}>
            <Stack direction='column'
              width='100%'
              spacing={1}
              paddingTop={1}>
              <FormControl fullWidth
                error={formik.touched.selectedPlan && Boolean(formik.errors.selectedPlan)}>
                <InputLabel size='small' id="demo-simple-select-label">Exercise Plan</InputLabel>
                <Select
                  value={formik.values.selectedPlan}
                  size='small'
                  name="selectedPlan"
                  label="Exercise Plan"
                  onChange={formik.handleChange}
                >
                  {plans.map((plan => 
                    <MenuItem key={plan.id} value={plan.id}>{plan.name}</MenuItem>
                  ))}
                </Select>
                {formik.touched.selectedPlan && formik.errors.selectedPlan &&
                  <FormHelperText>{formik.errors.selectedPlan}</FormHelperText>
                }
              </FormControl>
              <Box component={Link} to='/recovery/plans'>
                Manage Your Exercise Plans
              </Box>
            </Stack>
          </Card>
          <Slide direction="left"
            in={formik.values.selectedPlan !== ''}
            mountOnEnter
            unmountOnExit
            sx={{ paddingBottom: '10px' }}>
            <Box>
              <RadioCards 
                title='Details'
                subTitle='Step 2: Select how often you would like to complete this plan'
                sx={{ width: '100%' }}
                options={getOptions()}
                handleClick={handleScheduleTypeChange}>
                <Grow in={scheduleType === 'single-day'} mountOnEnter unmountOnExit>
                  <Box>
                    <FormGroup>
                      <FormControlLabel control={<Checkbox checked={isToday}
                        onClick={() => {
                          setIsToday(!isToday);
                          if(!isToday){
                            setSelectedDay(moment());
                          }
                        }} 
                      />}
                      label="Add To Today" />
                      {!isToday &&
                        <Box>
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                              label="Select Day"
                              name="endDate"
                              inputFormat="DD/MM/yyyy"
                              value={selectedDay}
                              onChange={val => {
                                setSelectedDay(val);
                              }}
                              renderInput={(params) => <TextField size='small' fullWidth {...params} />}
                            />
                          </LocalizationProvider>
                        </Box>
                      }
                    </FormGroup>
                  </Box>
                </Grow>
                <Grow in={scheduleType === 'program'} mountOnEnter unmountOnExit>
                  <Box>
                    <Box sx={{ 
                      paddingBottom: 1,
                      paddingTop: 2
                    }}>
                      <Typography sx={{ fontSize: 'h6.fontSize', lineHeight: 1, textAlign: 'center' }} color="text.primary">
                          Program Details
                      </Typography>
                    </Box>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <Stack direction='column' spacing={2}>
                        <Box>
                          <TextField
                            fullWidth
                            name="programName"
                            label="Name"
                            size='small'
                            value={formik.values.programName}
                            onChange={formik.handleChange}
                            error={formik.touched.programName && Boolean(formik.errors.programName)}
                            helperText={formik.touched.programName && formik.errors.programName}/>
                        </Box>
                        <Stack direction='row' spacing={2}>
                          <DatePicker
                            label="Start Date"
                            name="startDate"
                            inputFormat="DD/MM/yyyy"
                            value={formik.values.startDate}
                            onChange={val => {
                              formik.setFieldValue('startDate', moment(val));
                            }}
                            error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                            renderInput={(params) => <TextField size='small' fullWidth {...params} />}
                          />
                          <DatePicker
                            label="End Date"
                            name="endDate"
                            inputFormat="DD/MM/yyyy"
                            minDate={formik.values.startDate.clone().add(1, 'days')}
                            value={formik.values.endDate}
                            onChange={val => {
                              formik.setFieldValue('endDate', moment(val));
                            }}
                            error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                            renderInput={(params) => <TextField size='small' fullWidth {...params} />}
                          />
                        </Stack>
                          
                        <Stack direction='column'>
                          <Box paddingBottom={1}>
                            <Typography sx={{ fontSize: 'body2.fontSize', lineHeight: 1 }} color="text.secondary">
                                Days Of The Week
                            </Typography>
                          </Box>
                          <Stack direction='row' spacing={1} justifyContent="space-around">
                            <Checkbox 
                              name="monday"
                              checked={formik.values.monday}
                              onChange={formik.handleChange}
                              sx={{ padding: 0 }}
                              inputProps={{ 'aria-label': 'controlled' }}
                              icon={<DayCheckBox checked={false} day='Mon'/>}
                              checkedIcon={<DayCheckBox checked day='Mon'/>}
                            />
                            <Checkbox 
                              name="tuesday"
                              checked={formik.values.tuesday}
                              onChange={formik.handleChange}
                              sx={{ padding: 0 }}
                              inputProps={{ 'aria-label': 'controlled' }}
                              icon={<DayCheckBox checked={false} day='Tue'/>}
                              checkedIcon={<DayCheckBox checked day='Tue'/>}
                            />
                            <Checkbox 
                              name="wednesday"
                              checked={formik.values.wednesday}
                              onChange={formik.handleChange}
                              sx={{ padding: 0 }}
                              inputProps={{ 'aria-label': 'controlled' }}
                              icon={<DayCheckBox checked={false} day='Wed'/>}
                              checkedIcon={<DayCheckBox checked day='Wed'/>}
                            />
                            <Checkbox 
                              name="thursday"
                              checked={formik.values.thursday}
                              onChange={formik.handleChange}
                              sx={{ padding: 0 }}
                              inputProps={{ 'aria-label': 'controlled' }}
                              icon={<DayCheckBox checked={false} day='Thu'/>}
                              checkedIcon={<DayCheckBox checked day='Thu'/>}
                            />
                            <Checkbox 
                              name="friday"
                              checked={formik.values.friday}
                              onChange={formik.handleChange}
                              sx={{ padding: 0 }}
                              inputProps={{ 'aria-label': 'controlled' }}
                              icon={<DayCheckBox checked={false} day='Fri'/>}
                              checkedIcon={<DayCheckBox checked day='Fri'/>}
                            />
                            <Checkbox 
                              name="saturday"
                              checked={formik.values.saturday}
                              onChange={formik.handleChange}
                              sx={{ padding: 0 }}
                              inputProps={{ 'aria-label': 'controlled' }}
                              icon={<DayCheckBox checked={false} day='Sat'/>}
                              checkedIcon={<DayCheckBox checked day='Sat'/>}
                            />
                            <Checkbox 
                              name="sunday"
                              checked={formik.values.sunday}
                              onChange={formik.handleChange}
                              sx={{ padding: 0 }}
                              inputProps={{ 'aria-label': 'controlled' }}
                              icon={<DayCheckBox checked={false} day='Sun'/>}
                              checkedIcon={<DayCheckBox checked day='Sun'/>}
                            />
                          </Stack>
                        </Stack>
                        <>
                          <TextField
                            fullWidth
                            name="timesPerDay"
                            label="Number Of Times Per Day"
                            size='small'
                            value={formik.values.timesPerDay}
                            onChange={formik.handleChange}
                            error={formik.touched.timesPerDay && Boolean(formik.errors.timesPerDay)}
                            helperText={formik.touched.timesPerDay && formik.errors.timesPerDay}/>
                        </>
                      </Stack>
                    </LocalizationProvider>
                  </Box>
                </Grow>
              </RadioCards>
            </Box>
          </Slide>
        </Stack>
      </form>
    </Page>
  );
};

export default IndexContainer;