import React, { useContext, useEffect, useState } from 'react';

import {
  Box,
  Stack,
  MobileStepper,
  Grid,
  Button
} from '@mui/material';

import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { useTheme } from '@emotion/react';
import Image from 'mui-image';
import { UserContext, ServicesContext, EventsContext } from '@context';
import { LoadingButton } from '@mui/lab';
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const steps =  [
  {
    title: 'Mental Health',
    image: 'https://bracesocialprod.blob.core.windows.net/static/onboarding-carousel-image-step-1.png',
    text1: 'Brace helps you prioritise wellbeing throughout your recovery.',
    text2: 'Gather insights on your mental health through "Diary Entries" and build your understanding of the journey in "Learn"'
  },
  {
    title: 'Physical Recovery',
    image: 'https://bracesocialprod.blob.core.windows.net/static/onboarding-carousel-image-step-2.png',
    text1: 'Brace helps you stay on track to reach your recovery goals.',
    text2: 'Build and manage your exercise programmes in "Recovery" and celebrate your progress with "Trophies".'
  },
  {
    title: 'Community of Peers',
    image: 'https://bracesocialprod.blob.core.windows.net/static/onboarding-carousel-image-step-3.png',
    text1: 'Brace\'s Community ensures you\'re never alone in your journey.',
    text2: 'Ask questions and access peer insights in "Discussion". Share your journey and celebrate milestones with "Check-Ins"'
  }
];

export default function Step2(props){
  const { handleNext, handleBack } = props;
  const { api } = useContext(ServicesContext);
  const [ isLoading, setIsLoading ] = useState(false);
  const { getUserToken } = useContext(UserContext);
  const { trackEvent } = useContext(EventsContext);
  const theme = useTheme();

  const [activeStep, setActiveStep] = useState(0);

  const handleStepChange = (step) => {
    if(activeStep >= steps.length - 1){
      trackEvent('Onboarding:Carousel:Complete');
      completeOnboarding();
    }else{
      setActiveStep(step);
    }
  };

  const completeOnboarding = async () => {
    setIsLoading(true);
    await api.put({ url: 'accounts/onboarding/complete', getUserToken  });
    setIsLoading(false);
    handleNext();
  };

  const goBack = () => {
    if(activeStep == 0){
      handleBack();
    }
    else{
      setActiveStep(activeStep - 1);
    }
  };
  
  useEffect(() => {
    trackEvent(`Onboarding:Carousel:Step:${activeStep + 1}`);
  }, [activeStep]);

  const maxSteps = steps.length;
  const wasFinalStepReached = activeStep + 1 == steps.length;

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', color: 'braceGreenDark.main' }}>
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <AutoPlaySwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeStep}
          autoplay={false}
          interval={10000}
          onChangeIndex={handleStepChange}
          enableMouseEvents
          style={{ width: '100vw', flexGrow: 1, marginTop: 4 }}
          containerStyle={{  width: '100%', height: '100%', p: 2 } }
        >
          {steps.map((step, index) => (
            <Box key={index} sx={{  width: '100%', height: '100%' }}>
              <Stack sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                color: 'braceGreenDark.main',
                fontWeight: 'medium',
                height: '100%',
                alignItems: 'center' }}>
                <Box>
                  <Image 
                    duration={500} 
                    width='60vw'
                    src={step.image}/>
                </Box>
                <Box sx={{ 
                  fontSize: 'h5.fontSize', 
                  textAlign: 'center',
                  fontWeight: 'bold',
                  paddingTop: 1,
                  paddingBottom: 2
                }}>
                  {step.title}
                </Box>
                <Box 
                  sx={{ 
                    fontSize: 'body1.fontSize', 
                    textAlign: 'center',
                    fontWeight: 'regular',
                    px: 2,
                    paddingBottom: 1
                  }}>
                  {step.text1}
                </Box>
                <Box sx={{ 
                  fontSize: 'body1.fontSize', 
                  textAlign: 'center',
                  fontWeight: 'regular',
                  px: 2
                }}>
                  {step.text2}
                </Box>
              </Stack>
                
            </Box>
          ))}
        </AutoPlaySwipeableViews>
      </Box>
      <Grid container sx={{ p: 1, px: 4 }}>
        <Grid item xs={5} size='small'>
          <Button color='braceGreenDark'
            sx={{ fontWeight: 'bold' }}
            onClick={goBack}
            size='small'
            data-testid={'onboarding-button-back'}>
                Back
          </Button>
        </Grid>
        <Grid item xs={2}>
          <MobileStepper
            sx={{ backgroundColor: '#fff', justifyContent: 'center', 
              '.MuiMobileStepper-dotActive' : {
                backgroundColor: theme.palette.braceGreenLight.main
              } }}
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
          />
        </Grid>
        <Grid item xs={5} sx={{ display: 'flex' }}>
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row-reverse' }}>
            <Box>
              <LoadingButton color='braceGreenLight'
                loading={isLoading}
                sx={{ fontWeight: 'bold' }}
                onClick={() => handleStepChange(activeStep + 1)}
                variant='contained'
                data-testid={'onboarding-button-next'}
                size='small'>
                {!wasFinalStepReached ? 'Next' : 'CONTINUE'}
              </LoadingButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

