import {
  CHANGE_TAB,
  ADD_POST_SEARCH_RESULTS,
  CLEAR_POST_SEARCH_RESULTS,
  INIT_POST_SEARCH_RESULTS,
  RESET_CHECK_INS,
  SET_PROFILE_IMAGE,
  LOADING_POST,
  SET_POST,
  LOADING_POST_COMMENTS,
  SET_POST_COMMENTS,
  CLEAR_CHECK_IN,
  SET_CHECK_IN_COMMENTS,
  CLEAR_CHECK_IN_COMMENTS,
  ADD_PROFILE_SEARCH_RESULTS,
  LOADING_GROUPS,
  SET_GROUPS,
  LOADING_TOPICS,
  SET_TOPICS,
  LOADING_TOPIC,
  SET_TOPIC,
  LOADING_TOPIC_COMMENTS,
  SET_TOPIC_COMMENTS } from './../actions/community';

const initialState = {
  activeTab: 0,
  postsSearch: {
    posts: []
  }, 
  post: { isLoading: true, comments:{ isLoading: true, results: [] } },
  profileResults:[], 
  checkInComments:[], 
  profileImages:[], 
  groups:{ results: [], isLoading: true }, 
  topics:{ results: [], isLoading: true }, 
  topic: { isLoading: true, comments:{ isLoading: true, results: [] } } };

const getChildComments = (comments, parentCommentId) => {
  var childComments = comments.filter(c => c.parentCommentId === parentCommentId);

  childComments.forEach(cc => {
    cc.childComments = getChildComments(comments, cc.id);
  });

  return childComments;
};

const groupComments = (comments) => {
  var topLevelComments = comments.filter(c => !c.parentCommentId);

  topLevelComments.forEach(comment => comment.childComments = getChildComments(comments, comment.id));

  return topLevelComments;
};

export default function exploreReducer(state = initialState, action) {
  switch (action.type) {
  case CHANGE_TAB:
    state = { 
      ...state,
      activeTab: action.activeTab
    };
  case RESET_CHECK_INS:
    state = {
      ...state, 
      checkInSearch: {
        checkIns: [],
        page: 0
      },
      profileImages:[]
    };
    return state;
  case ADD_POST_SEARCH_RESULTS:
    state = {
      ...state,
      postsSearch:{
        ...state.postsSearch,
        posts: state.postsSearch.posts.concat(action.posts),
        page: action.page
      }
    };
    return state;
  case CLEAR_POST_SEARCH_RESULTS:
    state = {
      ...state,
      postsSearch: {
        posts: []
      } 
    };
    return state;
  case INIT_POST_SEARCH_RESULTS:
    state = {
      ...state,
      postsSearch: {
        posts: action.posts,
        page: action.page,
        createdAtLimit: action.createdAtLimit
      }
    };
    return state;
  case ADD_PROFILE_SEARCH_RESULTS:
    state = {
      ...state, 
      profileResults: action.profiles
    };
    return state;
  case LOADING_POST:
    state = {
      ...state, 
      post: { isLoading: true, comments:{ isLoading: false, results: [] } }
    };
    return state;
  case SET_POST:
    state = {
      ...state, 
      post: { ...action.post, isLoading: false, comments: { isLoading: false, results:[] } }
    };
    return state;
  case LOADING_POST_COMMENTS:
    return {
      ...state,
      post: { ...state.post, comments: { isLoading: true, results:[] } }
    };
  case SET_POST_COMMENTS:
    return {
      ...state,
      post: {
        ...state.post,
        comments: { results: groupComments(action.comments), isLoading: false }
      }
    };
  case CLEAR_CHECK_IN:
    state = {
      ...state, 
      checkIn: null
    };
    return state;
  case SET_CHECK_IN_COMMENTS:
    state = {
      ...state, 
      areCommentsLoading: false,
      checkInComments: groupComments(action.comments)
    };
    return state;
  case CLEAR_CHECK_IN_COMMENTS:
    state = {
      ...state, 
      areCommentsLoading: true,
      checkInComments: []
    };
    return state;
  case SET_PROFILE_IMAGE:
    const index = state.profileImages.findIndex(profileImage => profileImage.userId === action.userId);
    const newProfileImagesArray = [...state.profileImages];
    if(index>0)
      newProfileImagesArray[index].profileImage = action.profileImage;
    else
      newProfileImagesArray.push({ userId: action.userId, profileImage: action.profileImage });
      
    return {
      ...state,
      profileImages: newProfileImagesArray
    };
  case LOADING_GROUPS:
    return {
      ...state,
      groups: { isLoading: true, results: [] }
    };
  case SET_GROUPS:
    return {
      ...state,
      groups: { isLoading: false, results: action.groups }
    };
  case LOADING_TOPICS:
    return {
      ...state,
      topics: { results: [], isLoading: true } 
    };
  case SET_TOPICS:
    return {
      ...state,
      topics: { results: action.topics, isLoading: false } 
    };
  case LOADING_TOPIC:
    return {
      ...state,
      topic: { isLoading: true, comments:{ isLoading: false, results: [] } }
    };
  case SET_TOPIC:
    return {
      ...state,
      topic: { ...action.topic, isLoading: false, comments: { isLoading: false, results:[] } }
    };
  case LOADING_TOPIC_COMMENTS:
    return {
      ...state,
      topic: { ...state.topic, comments: { isLoading: true, results:[] } }
    };
  case SET_TOPIC_COMMENTS:
    return {
      ...state,
      topic: {
        ...state.topic,
        comments: { results: groupComments(action.comments), isLoading: false }
      }
    };
  default:
    return state;
  }
}