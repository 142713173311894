import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import {
  Stack,
  Box,
  Grow
} from '@mui/material';

import { saveOnboardingOption } from './../../actions/users';

import ButtonCard from './components/button-card';

export default function Options(){
  const history = useHistory();
  const dispatch = useDispatch();

  const goTo = (selection, location) => {
    dispatch(saveOnboardingOption(selection));
    history.push(location);
  };

  return (
    <Grow timeout={200}
      in={true}
      mountOnEnter
      unmountOnExit>
      <Stack direction='column'
        alignItems='center'
        sx={{ height: 'calc(100vh - 56px)', padding: 3, paddingTop: 4 }}>
        <Box sx={{ 
          color: 'text.primary', 
          fontSize: 'h4.fontSize', 
          padding: 1, 
          fontWeight: 'medium',
          textAlign: 'center' }}>
          What brings you to Brace?
        </Box>
        <Box sx={{ 
          color: 'text.secondary', 
          fontSize: 'body1.fontSize', 
          textAlign: 'center',
          width: '80%' }}>
          Choose the area you are most excited to get started with.
        </Box>
        <Stack direction="column" justifyContent="space-evenly" sx={{ flexGrow: 1 }}>
          <ButtonCard 
            dataTestId='onboarding-options-button-tracking'
            onClick={() => goTo('Tracking', '/recovery')}
            title='Tracking'
            icon='to-do-list'
            text='Track and manage your exercise plan to achieve your recovery goals.'
          />
          <ButtonCard 
            dataTestId='onboarding-options-button-progress'
            onClick={() => goTo('Progress', '/home')}
            title='Progress'
            icon='analytics'
            text='Document your recovery journey and get progress metrics as you go.'
          />
          <ButtonCard 
            dataTestId='onboarding-options-button-community'
            onClick={() => goTo('Community', '/community')}
            title='Community'
            icon='consultation'
            text='Connect with others for recovery insights and community motivation.'
          />
        </Stack>
      </Stack>
    </Grow>
  );
}

