import React from 'react';

import moment from 'moment';

import { 
  Card,
  Box,
  Divider,
  Grid
} from '@mui/material';

import { grey, red, amber, green } from '@mui/material/colors';

import MoodEmoji from './../../../../../../components/atoms/mood-emoji';
import LoadableText from './../../../../../../components/atoms/loadable-text';
import Image from './../../../../../../components/atoms/image';

const getColor = (value, inverse) => {
  var level = !inverse ? value : 6 - value;

  switch(level){
  case 1:
    return red[50];
  case 2:
    return amber[50];
  case 4:
    return green[50];
  case 5:
    return green[100];
  default: 
    return grey[50];
  }
};

export default function DiaryEntry(props){
  var { initiateEditEntry } = props;
  return (
    <Card sx={{ display: 'flex' }} onClick={() => {initiateEditEntry(props.diaryEntry);}}>
      <Box sx={{ textAlign: 'center', p: 1 }}>
        <Box sx={{ typography: 'h4' }}>
          <LoadableText text={moment(props.diaryEntry.createdAt).format('DD')} isLoading={props.isLoading} minWidth={30} />
        </Box>
        <Box sx={{ typography: 'body1', color: 'text.secondary' }}>
          <LoadableText text={moment(props.diaryEntry.createdAt).format('MMM')} isLoading={props.isLoading} minWidth={30} />
        </Box>
      </Box>
      <Divider orientation="vertical" flexItem/>
      <Grid container>
        <Grid item xs={props.diaryEntry.sleep > 0 ? 3 : 4}  sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Box sx={{ typography: 'body2', color: 'text.secondary' }}>Mood</Box>
          <Box sx={{ backgroundColor: getColor(props.diaryEntry.mood), borderRadius: 1, px: 1, display: 'flex', alignItems: 'center', minHeight: '40px' }}>
            <MoodEmoji level={props.diaryEntry.mood}/>
          </Box>
        </Grid>
        <Grid item xs={props.diaryEntry.sleep > 0 ? 3 : 4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Box sx={{ typography: 'body2', color: 'text.secondary' }}>Pain</Box>
          {!props.isLoading &&
            <Box sx={{ backgroundColor: getColor(props.diaryEntry.pain, true), borderRadius: 1, px: 1, display: 'flex', minHeight: '40px'  }}>
              <Box sx={{ typography: 'h2', fontWeight: 'medium', lineHeight: 1 }}>
                {props.diaryEntry.pain}
              </Box>
              <Box sx={{ typography: 'body2', display: 'flex', alignItems: 'end', lineHeight: 1.5 }}>
                <Box>
                /5
                </Box>
              </Box>
            </Box>
          }
          {props.isLoading &&
            <LoadableText text='' isLoading={props.isLoading} minWidth={30} />
          }
        </Grid>
        <Grid item xs={props.diaryEntry.sleep > 0 ? 3 : 4}  sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Box sx={{ typography: 'body2', color: 'text.secondary' }}>Energy</Box>
          {!props.isLoading &&
            <Box sx={{ backgroundColor: getColor(props.diaryEntry.energy), borderRadius: 1, px: 1, display: 'flex', minHeight: '40px'  }}>
              <Box sx={{ typography: 'h2', fontWeight: 'medium', lineHeight: 1 }}>
                {props.diaryEntry.energy}
              </Box>
              <Box sx={{ typography: 'body2', display: 'flex', alignItems: 'end', lineHeight: 1.5 }}>
                <Box>
                /5
                </Box>
              </Box>
            </Box>
          }
          {props.isLoading &&
            <LoadableText text='' isLoading={props.isLoading} minWidth={30} />
          }
        </Grid>
        {props.diaryEntry.sleep > 0 &&
          <Grid item xs={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box sx={{ typography: 'body2', color: 'text.secondary' }}>Sleep</Box>
            {!props.isLoading &&
              <Box sx={{ backgroundColor: getColor(props.diaryEntry.sleep), borderRadius: 1, px: 1, display: 'flex', minHeight: '40px'  }}>
                <Box sx={{ typography: 'h2', fontWeight: 'medium', lineHeight: 1 }}>
                  {props.diaryEntry.sleep}
                </Box>
                <Box sx={{ typography: 'body2', display: 'flex', alignItems: 'end', lineHeight: 1.5 }}>
                  <Box>
                  /5
                  </Box>
                </Box>
              </Box>
            }
            {props.isLoading &&
              <LoadableText text='' isLoading={props.isLoading} minWidth={30} />
            }
          </Grid>
        }
        <Grid item xs={12}>
          <Box sx={{ typography: 'body1', p: 1 }}>
            {props.diaryEntry.message}
          </Box>
        </Grid>
        {props.diaryEntry.imageUrl &&
        <Grid item xs={12}>
          <Box p={1} width='100%'>
            <Image src={props.diaryEntry.imageUrl} />
          </Box>
        </Grid>
        }
      </Grid> 
    </Card>
  );
}