import config from './../../config';
import Storage from './../storage';
import axios from 'axios';

class Api {
  static constitute () { }
  constructor() {
    this.baseUrl = config.apiBaseUrl;
    this.storage = new Storage();
  }
  post({ url, data, isAuthenticated = true }){
    return this.storage.get('user')
      .then((user) => {
        return axios({
          method: 'post',
          url: this.baseUrl +  url,
          headers: isAuthenticated ? { 'Authorization': user.token } : {},
          data
        })
          .then(response => {
            return response.data;
          })
          .catch(error => { 
            if(error?.response?.status === 400)   {
              var expectedError = new Error(error.message);
              expectedError.status = error.response?.status;
              expectedError.data = error.response?.data;
              throw expectedError;
            }        
            throw new Error(error.message);
          });
      });
  }
  put({ url, data }){
    return this.storage.get('user')
      .then((user) => {
        return axios({
          method: 'put',
          url: this.baseUrl +  url,
          headers: { 'Authorization': user.token },
          data
        })
          .then(response => {
            return response.data;
          })
          .catch(error => {
            throw error;
          });
      });
  }
  get({ url, params = {} }){
    return this.storage.get('user')
      .then((user) => {
        return axios({
          method: 'get',
          url: this.baseUrl +  url,
          headers: { 'Authorization': user.token },
          params
        })
          .then(response => {
            return response.data;
          })
          .catch(error => {
            throw error;
          });
      });
  }
  delete({ url, params = {} }){
    return this.storage.get('user')
      .then((user) => {
        return axios({
          method: 'delete',
          url: this.baseUrl +  url,
          headers: { 'Authorization': user.token },
          params
        })
          .then(response => {
            return response.data;
          })
          .catch(error => {
            throw error;
          });
      });
  }
}

export default new Api();