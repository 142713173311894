import React, { useState } from 'react';

import {
  Box,
  Typography,
  Stack
}from '@mui/material';
import { TextField } from '@components/atoms';

export default function Step3(props) {
  const { settings, handleChange } = props;
  return (
    <Box p={2} >
      <Typography variant='h6' fontWeight='normal' gutterBottom>
        Program Details
      </Typography>
      <Typography variant='body2'>
      </Typography>
      <Box sx={{ py: 2 }}>
        <Stack spacing={2}>
          <TextField 
            inputProps={{ 'data-testid': 'recovery-create-program-name-input' }}
            fullWidth
            value={settings.name ?? ''}
            onChange={(e) => {
              handleChange({
                ...settings,
                name: e.target.value
              });
            }}
            label='Name'
          />
          <TextField 
            fullWidth
            multiline
            minRows={3}
            value={settings.notes ?? ''}
            onChange={(e) => {
              handleChange({
                ...settings,
                notes: e.target.value
              });
            }}
            label='Notes'
          />
        </Stack>
      </Box>
    </Box>
  );
}