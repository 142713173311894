import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Box,
  Dialog,
  Divider,
  Typography,
  Checkbox
}from '@mui/material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import config from '@config';

import { DialogHeader, DialogTransition, LoadableText } from '@components/atoms';
import { UserContext, ServicesContext, AccountOnboardingContext, EventsContext } from '@context';
import { useTheme } from '@emotion/react';
import Banner from './banner';

export default function AccountOnboardingDialog(props) {
  const { isOpen, close } = props;
  var { user, getUserToken } = useContext(UserContext);
  var { accountOnboarding, isLoadingAccountOnboarding, loadAccountOnboarding } = useContext(AccountOnboardingContext);
  const { api } = useContext(ServicesContext);
  const { trackEvent } = useContext(EventsContext);
  var history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  useEffect(() => {
    if(isOpen){
      loadAccountOnboarding();
      trackEvent('AccountOnboarding:Dialog:Open');
    }
  }, [isOpen]);

  const resendVerificationEmail = async () => {
    if(!accountOnboarding.isEmailVerified){
      await api.put({ url: 'accounts/resendVerificationEmail', getUserToken });
      enqueueSnackbar('Verification email successfully resent', { 
        variant: 'success'
      });
    }
  };

  const ListItem = (props) => {
    const { value, onClick, title, message, dataTestIdName } = props;
    return (
      <Box sx={{ padding: 1, display: 'flex' }}          onClick={onClick}>
        <Checkbox
          inputProps={{ 'data-testid':`account-onboarding-step-${dataTestIdName}-check-box` }}
          size='large'
          readOnly
          sx={{ p: 0 }}
          checked={value}
          icon={<RadioButtonUncheckedIcon fontSize='large'  sx={{ color: '#fff' }}/>}
          checkedIcon={<CheckCircleIcon fontSize='large' color='braceGreenLight' />} />
        <Box sx={{ paddingLeft: 2, flexGrow: 1 }}>
          <LoadableText />
          <Typography variant='body1' component='div' fontWeight='bold'>
            <LoadableText isLoading={isLoadingAccountOnboarding} text={title}/> 
          </Typography>
          <Typography variant='body2' component='div'>
            <LoadableText isLoading={isLoadingAccountOnboarding} text={message}/>
          </Typography>
        </Box>
      </Box>
    );
  };

  const goToPage = (page) => {
    history.push(page);
    close();
  };

  const onClickWithEvent = (eventName, onClick) => {
    trackEvent(eventName);
    onClick();
  };

  const closeDialog = () => {
    trackEvent('AccountOnboarding:Dialog:Close');
    close();
  };

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={closeDialog}
      TransitionComponent={DialogTransition}
      TransitionProps={{ direction: 'up' }}

    >
      <Box
        sx={{ 
          height: '100vh',
          color: '#fff',
          //backgroundColor: theme.palette.braceGreenDark.main 
          backgroundImage: `linear-gradient(${theme.palette.braceGreenDark.dark} 0%, ${theme.palette.braceGreenDark.main} 60%, ${theme.palette.braceGreenDark.dark} 100%)`
     
        }}
      >
        <Box 
          sx={{ 
            paddingTop: 'env(safe-area-inset-top)',   
            paddingBottom: 'calc(env(safe-area-inset-bottom) + 8px)',  
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
          }}>
          <DialogHeader title='Get Started With Brace'
            close={closeDialog}
            iconColor='#fff'
            sx={{
              '.MuiDialogTitle-root' : {
                fontWeight: 'bold'
              }
            }}/>
          <Divider variant='middle' sx={{ borderColor: '#fff' }}/>
          <Box sx={{ 
            flexGrow: 1,           
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around'
          }}>
            <ListItem 
              dataTestIdName='SIGN_UP'
              title='Sign Up To Brace' 
              message='You nailed this one!'
              value={accountOnboarding?.hasSignedUpToBrace}/>
            <ListItem 
              dataTestIdName='VERIFY_EMAIL'
              title='Verify Your Email Address' 
              message={<>It should be in your inbox, if not <u>resend by clicking here</u></>}
              value={accountOnboarding?.isEmailVerified}
              onClick={() => onClickWithEvent('AccountOnboarding:Dialog:Selection:ResendVerificationEmail', resendVerificationEmail)}
            />
            <ListItem 
              dataTestIdName='PROFILE_PHOTO'
              title='Add A Profile Photo' 
              message='It can be you, an injury, your activity, the choice is yours!'
              value={accountOnboarding?.hasAddedProfilePhoto}
              onClick={() => onClickWithEvent('AccountOnboarding:Dialog:Selection:ProfilePhoto', 
                () => goToPage(`home?profileId=${user.id}`))
              }
            />
            <ListItem 
              dataTestIdName='RECOVERY_COURSE'
              title='Take Our Recovery Course' 
              message='A quick walkthrough of the Recovery tab, takes 3 mins!'
              value={accountOnboarding?.hasTakenRecoveryCourse}
              onClick={() => onClickWithEvent('AccountOnboarding:Dialog:Selection:RecoveryCourse', 
                () => goToPage(`learn?&courseId=${config.accountOnboardingCourseId}`))
              }
            />
            <ListItem 
              dataTestIdName='DIARY_ENTRY'
              title='Add Your First Diary Entry' 
              message='Start tracking your wellbeing with your first entry.'
              value={accountOnboarding?.hasAddedDiary}
              onClick={() => onClickWithEvent('AccountOnboarding:Dialog:Selection:DiaryEntry', 
                () => goToPage('diary?defaultOpen=true'))
              }
            />
            <ListItem 
              dataTestIdName='COMMUNITY_CONTRIBUTION'
              title='Check Out The Community' 
              message='Like or comment on a post or post your own Check-in!'
              value={accountOnboarding?.hasContributedToCommunity}
              onClick={() => onClickWithEvent('AccountOnboarding:Dialog:Selection:Community', 
                () => goToPage('community'))
              }
            />
            <ListItem 
              dataTestIdName='GOAL_SET'
              title='Set Your First Recovery Goal' 
              message='Goals help to keep your focused through the journey.'
              value={accountOnboarding?.hasAddedRecoveryGoal}
              onClick={() => onClickWithEvent('AccountOnboarding:Dialog:Selection:NewGoal', 
                () => goToPage('home?defaultNewGoalOpen=true'))
              }
            />
          </Box>
          <Banner />
        </Box>
      </Box>
      
    </Dialog>
  );
}