import { SET_RECOVERY_DAILY_DATA, 
  SET_RECOVERY_DAILY_DATE,
  SET_PLANS,
  SET_PROGRAMS_LOADING,
  SET_PROGRAMS,
  COMPLETE_SET,
  DELETE_SESSION,
  DELETE_PROGRAM,
  PROGRAM_UPDATED,
  SET_EXERCISE_SENTIMENT } from './../actions/recovery';

import moment from 'moment';

const completeSet = (exercise, setNumber) => {
  return { ...exercise,
    sets: exercise.sets.map(
      (set) => set.number == setNumber ? 
        { ...set, 
          isComplete: !set.isComplete
        }:
        set
    ) };
};

const isPlanComplete = (plan) => {
  var isComplete = true;

  plan.exercises.forEach(exercise => {
    exercise.sets.forEach(set => {
      if(!set.isComplete){
        isComplete = false;
      }
    });
  });

  return isComplete;
};

const orderSessions = (sessions) => {
  var orderedSessions = sessions.slice().sort(function(sessionA, sessionB) {
    var dateA = new Date(sessionA.createdAt);
    var dateB = new Date(sessionB.createdAt);
    return dateA - dateB;
  });

  orderedSessions.forEach(s => {
    if(!s.plan.planName){
      s.plan.planName = 'Session';
    }
  });

  return orderedSessions;
};

export default function recoveryReducer(state = {
  currentDate: moment(),
  sessions:[],
  plans: [],
  programs: [],
  isLoading:true
}, 
action) {

  switch (action.type) {
  case SET_RECOVERY_DAILY_DATE:
    state = {
      ...state, 
      isLoading:true,
      currentDate: action.date.clone()
    };
    return state;
  case SET_RECOVERY_DAILY_DATA:
    state = {
      ...state, 
      sessions: orderSessions(action.sessions),
      isLoading:false
    };
    return state;
  case DELETE_SESSION:
    var indexSession = state.sessions.map((x) => x.id).indexOf(action.sessionId);
    if(indexSession >= 0){
      state = { ...state,
        sessions: [ 
          ...state.sessions.slice(0, indexSession),
          ...state.sessions.slice(indexSession + 1)
        ]
      };
    }
    return state;
  case DELETE_PROGRAM:
    var indexProgram = state.programs.map((x) => x.id).indexOf(action.programId);
    if(indexProgram >= 0){
      state = { ...state,
        programs: [ 
          ...state.programs.slice(0, indexProgram),
          ...state.programs.slice(indexProgram + 1)
        ],
        sessions: state.sessions.filter(s => s.program?.id !== action.programId)
      };
    }
    return state;
  case PROGRAM_UPDATED:
    state = { ...state,
      programs:  state.programs.map(
        (program) => program.id === action.program.id ? { ...program, ...action.program }
          : program
      )
    };
    return state;
  case SET_PLANS:
    state = {
      ...state, 
      plans: action.plans.sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf())
    };
    return state;
  case SET_PROGRAMS_LOADING:
    state = {
      ...state, 
      areProgramsLoading:  true
    };
    return state;
  case SET_PROGRAMS:
    state = {
      ...state, 
      programs: action.programs,
      areProgramsLoading: false
    };
    return state;
  case COMPLETE_SET:
    state = { ...state,
      sessions: state.sessions.map(
        (session) => session.id === action.sessionId ? 
          { ...session, 
            plan: { ...session.plan,
              exercises: session.plan.exercises.map(
                (exercise) => exercise.definition.id === action.exerciseDefinitionId ? 
                  completeSet(exercise, action.setNumber) : exercise)
            }
          } : session
      )
    };
    state = { ...state,
      sessions: state.sessions.map(
        (session) => session.id === action.sessionId ? 
          { ...session, 
            plan: { ...session.plan, isComplete: isPlanComplete(session.plan) }
          } : session
      )
    };
    state = {
      ...state,
      sessions: orderSessions(state.sessions)
    };
    return state;
  case SET_EXERCISE_SENTIMENT:
    state = { ...state,
      sessions: state.sessions.map(
        (session) => session.id === action.sessionId ? 
          { ...session, 
            plan: { ...session.plan,
              exercises: session.plan.exercises.map(
                (exercise) => exercise.definition.id === action.exerciseDefinitionId ? 
                  { ...exercise, sentiment: action.sentiment } : exercise)
            }
          } : session
      )
    };
    return state;
  default:
    return state;
  }
}