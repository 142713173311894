import React from 'react';
import { Link } from 'react-router-dom';
import { 
  Box,
  Divider,
  Skeleton,
  Avatar
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { LoadableText, ProgressLinear, TruncatedText } from '@components/atoms';
import { Image } from 'mui-image';

import { useTheme } from '@emotion/react';
import { getProgress } from './helpers';

import { toShortDateString } from '@helpers/dates';

export default function Course(props) {
  const{ course } = props;
  const IMAGE_SIZE = 100;
  const theme = useTheme();

  var getImageUrl = () => {
    return `https:${course.imageUrl}`;
  };

  return (
    <Box sx={{ backgroundColor: theme.palette.background.paper, marginBottom: '1px' }}>
      <Divider light/>
      <Box 
        sx={{ display: 'flex', alignItems: 'center', px: 1.5, py: 1, textDecoration: 'none', maxWidth: '100%' }}
        component={Link} 
        to={`?courseId=${course?.id}`}
      >
        <Box>
          {props.isLoading ? (
            <Skeleton variant="rectangular" sx={{ borderRadius: 2 }}>
              <Avatar sx={{ width: IMAGE_SIZE, height: IMAGE_SIZE, borderRadius: 1 }}/>
            </Skeleton>
          ) : (
            <Image duration={1000} src={getImageUrl()} style={{ width: `${IMAGE_SIZE}px` }} />
          )}
        </Box>
        <Box sx={{ px: 1, flexGrow: 1 }}>
          <Box sx={{ typography: 'h6', lineHeight: 1.25, color: 'text.primary' }}>
            <LoadableText text={course.title} isLoading={props.isLoading}/>
          </Box>
          <Box sx={{ typography: 'body2', color: 'text.secondary' }}>
            <TruncatedText text={course.description}  isLoading={props.isLoading} maxLines={3} />
          </Box>
          
        </Box>
      </Box>
      <Box sx={{ px: 1.5, py: 1 }}>
        {!course?.account?.startedAt &&
          <Box sx={{ typography: 'body1', color: 'text.disabled' }}>
            <LoadableText text={'Not Started Yet'} isLoading={props.isLoading}/>
          </Box>
        }
        {course?.account?.completedAt &&
        <Box sx={{ display: 'flex' }}>
          <CheckCircleIcon color='primary' />
          <Box sx={{ typography: 'body1', color: 'text.disabled', marginLeft: 1 }}>
            <LoadableText text={`Completed At - ${toShortDateString(course?.account?.completedAt)}`} isLoading={props.isLoading}/>
          </Box>
        </Box>
        }
        {(course?.account?.startedAt && !course?.account?.completedAt) &&
          <Box>
            <Box sx={{ typography: 'body1', color: 'text.disabled' }}>
              <LoadableText text={`Started At - ${toShortDateString(course?.account?.startedAt)}`} isLoading={props.isLoading}/>
            </Box>
           
            <ProgressLinear value={getProgress(course)}/>
          </Box>
        }
       
      </Box>
      <Divider light/>
    </Box>
  );
}