import React from 'react';

import { 
  Box,
  IconButton,
  Typography,
  Button
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export default function Section(props){
  const { id, title, children, buttonAction, buttonText, buttonDisabled, buttonDataTestId, buttonIcon= <ChevronRightIcon /> } = props;

  return (
    <Box id={id} sx={{ p: 1, display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box sx={{ display : 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 1 }}>
        <Typography onClick={buttonAction} variant='h6' sx={{ lineHeight: 1, flexGrow: 1 }}>{title}</Typography>
        {(buttonText && buttonAction) &&
          <Button sx={{ p: 0 }}
            data-testid={buttonDataTestId}
            onClick={buttonAction} 
            disabled={buttonDisabled}
            color="button"
            size='small'
            endIcon={buttonIcon}>
            {buttonText}
          </Button>
        }
        {(!buttonText && buttonAction) &&
          <IconButton 
            data-testid={buttonDataTestId} 
            onClick={buttonAction}
            disabled={buttonDisabled}
            sx={{ p: 0 }}>
            {buttonIcon}
          </IconButton>
        }
      </Box>
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        {children}
      </Box>
    </Box>
  );
}

