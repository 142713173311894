import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { 
  Box, 
  Card,
  Typography,
  CardContent,
  Divider,
  Switch
} from '@mui/material';

import { loadUser } from '../../../actions/users';

import api from '../../../services/clients/api';
import LoadableText from '../../../components/atoms/loadable-text';

export default function Notifications(props){
  const { user } = props;
  const [notifications, setNotifications] = useState([{ id: 1 }, { id: 2 }]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if(user){
      setIsLoading(true);
      api.get({ url: 'scheduled-notifications' })
        .then((result) => {
          setNotifications(result);
          setIsLoading(false);
        });
    }
  }, [user]);

  const toggleNotification = (notification) => {
    api.put({ url: `scheduled-notifications/${notification.id}/toggle` })
      .then((result) => {
        var updatedNotifications = [...notifications].map(n => n.id !== notification.id ? n : result);
        setNotifications(updatedNotifications);
      });
  };

  const mapNotificationToTitle = (type) => {
    switch(type){
    case 'DAILY_MOTIVATION':
      return 'Daily Motivation';
    case 'DIARY_REMINDER':
      return 'Diary Reminder';
    default:
      return 'Unknown';
    }
  };

  return (
    <Card>
      <CardContent>
        <Typography color="text.secondary" variant='h5' gutterBottom>
            Notifications
        </Typography>
        {notifications.map((notification) => (
          <Box key={notification.id}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography component='div' variant='body1'>
                  <LoadableText isLoading={isLoading} text={mapNotificationToTitle(notification.type)} />
                </Typography>
              </Box>
              <Box>
                <Switch
                  checked={notification.isActive}
                  disabled={isLoading}
                  onChange={() => {toggleNotification(notification);}}
                />
              </Box>
              <Divider light/>
            </Box>

          </Box>
        ))}
      </CardContent>
    </Card>
  );
}

