import React from 'react';

import { 
  Box
} from '@mui/material';

const getEmoji = (level) => {
  switch(level){
  case 0:
    return 'https://bracesocial-prod-cdn.azureedge.net/static/neutral.png';
  case 1:
    return 'https://bracesocial-prod-cdn.azureedge.net/static/angry.png';
  case 2:
    return 'https://bracesocial-prod-cdn.azureedge.net/static/tired.png';
  case 3:
    return 'https://bracesocial-prod-cdn.azureedge.net/static/neutral.png';
  case 4:
    return 'https://bracesocial-prod-cdn.azureedge.net/static/happy.png';
  case 5:
    return 'https://bracesocial-prod-cdn.azureedge.net/static/grinning.png';
  }
};

const getText = (level) => {
  switch(level){
  case 0:
    return 'OK';
  case 1:
    return 'Frustrated';
  case 2:
    return 'Tired';
  case 3:
    return 'OK';
  case 4:
    return 'Content';
  case 5:
    return 'Delighted';
  }
};

export default function MoodEmoji(props) {
  const { level, displayText, size = 32 } = props;
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      <Box sx={{ height: size, width: size }}>
        <img src={getEmoji(level)} style={{ opacity: level === 0 ? 0.2 : 1, height: size, width: size }} />
      </Box>
      {displayText &&
        <Box sx={{ typography: 'caption', fontSize: '0.7rem' }}>{getText(level)}</Box>
      }
    </Box>
  );
}