import React, { useState } from 'react';

import {
  Box,
  Typography
}from '@mui/material';
import { SelectPlan } from '@components/organisms';
import { NewPlanDialog } from '@components/templates';

export default function Step1(props) {
  const { settings, handleChange } = props;
  const [isNewPlanDialogOpen, setIsNewPlanDialogOpen] = useState(false);

  return (
    <Box p={2} >
      <Typography variant='h6' fontWeight='normal' gutterBottom>
        Select Exercise Plan
      </Typography>
      <Typography variant='body2'>
        Please select the exercise plan that you would like to attach to this program.
      </Typography>
      <Box sx={{ py: 2 }}>
        {!isNewPlanDialogOpen &&
        <SelectPlan 
          shouldAddCreateNewPlanOption={true}
          selectDataTestId={'recovery-create-program-select-plan-button'}
          selectedPlan={settings.selectedPlan}
          handleChange={(value) => value.id == 'CREATE_NEW_PLAN_OPTION' ? 
            setIsNewPlanDialogOpen(true)
            : handleChange({
              ...settings,
              selectedPlan: value
            })}
          handleLoadingStateChange={() => {}}/>
        }
      </Box>
      <NewPlanDialog 
        isOpen={isNewPlanDialogOpen}
        close={() => {
          setIsNewPlanDialogOpen(false);
        }}/>
    </Box>
  );
}