import React, { useContext, useEffect, useState } from 'react';

import { 
  Box,
  Typography,
  Card
} from '@mui/material';

import { ServicesContext, UserContext  } from '@context';
import moment from 'moment';
import { Icon, LoadableText, MoodEmoji } from '@components/atoms';
import CreateDiaryDialog from '../../components/home-profile/diary/create-dialog';
import { moodToText } from '@helpers/text';

export default function DiaryToDo(){
  const { user, getUserToken } = useContext(UserContext);
  const { api } = useContext(ServicesContext);
  const [todaysDiaryEntry, setTodaysDiaryEntry] = useState(null);
  const [diaryStreak, setDiaryStreak] = useState(0);
  const [isLoadingDiaryEntries, setIsLoadingDiaryEntries] = useState(true);
  const [isCreateDiaryOpen, setIsCreateDiaryOpen] = useState(false);
  const today = moment();

  const loadDiaryEntries = async () => {
    setIsLoadingDiaryEntries(true);
    var result = await api.get({ url: 'community/diary/search', getUserToken });
    setTodaysDiaryEntry(result.results.filter(d => moment(d.createdAt).isAfter(today.startOf('day')))[0]);

    var count = 0;
    var hasDiaryOnDay = true;

    do {
      hasDiaryOnDay = result.results.filter(d => 
        moment().add(-(count), 'days').startOf('day').isBefore(moment(d.createdAt)) && 
        moment().add(-(count - 1), 'days').startOf('day').isAfter(moment(d.createdAt))
      ).length > 0;
      if(hasDiaryOnDay)
        count++;
    } while (hasDiaryOnDay);

    setDiaryStreak(count);
    setIsLoadingDiaryEntries(false);
  };

  useEffect(() => {
    if(user?.id){
      loadDiaryEntries();
    }
  }, [user]);

  return (
    <Box data-testid='home-diary-to-do'>
      {!isLoadingDiaryEntries &&
        <>
          {!todaysDiaryEntry && 
      <Card 
        variant='outlined'
        sx={{ display: 'flex', alignItems: 'center', height: '100%', px: 2, py: 1 }}
        onClick={() => setIsCreateDiaryOpen(true)}
      >
        <MoodEmoji level={3} size={60} opacity={0.25}/>
        <Box sx={{ flexGrow: 1, paddingLeft: 2  }}>
          <Typography variant='body1'
            data-testid='home-diary-to-do-header'
            textAlign='center'
            fontWeight='medium'>
            {'Complete you Diary for Today'}
          </Typography>
          <Typography variant='body2' textAlign='center' component='div'>
            {diaryStreak > 0 ? 
              `You’re are on a ${diaryStreak} day streak!` : 
              'Start your diary streak!'
            }
          </Typography>
        </Box>
      </Card>
          }
          {todaysDiaryEntry && 
      <Card 
        variant='outlined'
        sx={{ display: 'flex', alignItems: 'center', height: '100%', px: 2, py: 1 }}
        onClick={() => setIsCreateDiaryOpen(true)}
      >
        <MoodEmoji level={todaysDiaryEntry.mood} size={60}/>
        <Box sx={{ flexGrow: 1, paddingLeft: 2  }}>
          <Typography variant='body1'
            textAlign='center'
            data-testid='home-diary-to-do-header'
            fontWeight='medium'>
            {`You are ${moodToText(todaysDiaryEntry.mood)} Today${todaysDiaryEntry.mood >= 4 ? '!' : ''}`}
          </Typography>
          <Typography variant='body2' textAlign='center' component='div'>
            {diaryStreak > 0 ? 
              `You are on a ${diaryStreak} day streak!` : 
              ''
            }
          </Typography>
        </Box>
      </Card>
          }
        </>
      }
      {isLoadingDiaryEntries && 
         <Card 
           variant='outlined'
           sx={{ display: 'flex', alignItems: 'center', height: '100%', px: 2, py: 1 }}
           onClick={() => setIsCreateDiaryOpen(true)}
         >
           <MoodEmoji level={3} size={60} opacity={0.1}/>
           <Box sx={{ flexGrow: 1, paddingLeft: 2 }}>
             <Typography variant='body1'
               textAlign='center'
               component='div'
               fontWeight='medium'>
               <LoadableText isLoading={true} />
             </Typography>
             <Typography variant='body2' textAlign='center' component='div'>
               <LoadableText isLoading={true} />
             </Typography>
           </Box>
         </Card>
      }
      <CreateDiaryDialog userId={user?.id}
        isOpen={isCreateDiaryOpen}
        diaryEntryBeingEdited={todaysDiaryEntry}
        close={(shouldRefresh) => {
          setIsCreateDiaryOpen(false);
          if(shouldRefresh){
            loadDiaryEntries();
          }
        }} />
    </Box>
  );
}

