import mixpanel from 'mixpanel-browser';

export const OPEN_DIALOG = 'OPEN_DIALOG'
export const CLOSE_DIALOG = 'CLOSE_DIALOG'

export const saveCheckIn = (message, sentiment) => {
  return(dispatch, getState, services) => {
    mixpanel.track('Interaction:CheckIn:Created');
    return services.apiClient.saveCheckIn(getState().user, message, sentiment);
  };
};

export const likeCheckIn = (checkInId) => {
  return(dispatch, getState, services) => {
    mixpanel.track('Interaction:CheckIn:Liked');
    var user = getState().user;
    return services.apiClient.likeCheckIn(user, checkInId)
  };
};

export const likeComment = (commentId) => {
  return(dispatch, getState, services) => {
    mixpanel.track('Interaction:Comment:Liked');
    var user = getState().user;
    return services.apiClient.likeComment(user, commentId)
  };
};

export const openDialog = () => {
  return{
    type:OPEN_DIALOG
  }
}

export const closeDialog = () => {
  return{ 
    type:CLOSE_DIALOG
  }
}
