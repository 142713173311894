import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import InfiniteScroll from 'react-infinite-scroll-component';

import { 
  Box,
  Alert,
  CircularProgress,
  Divider
} from '@mui/material';

import { loadUser } from './../../actions/users';
import { searchNotifications, refreshNotifications } from './../../actions/notifications';

import Page from '../../components/page/page';
import Notification from './components/notification';

const EmptyLoadingState = () => {
  var rows = [];
  for (var i = 0; i < 10; i++) {
    rows.push(<Notification key={i} isLoading={true}/>);
  }
  return rows;
};

export default function Index(props){
  var dispatch = useDispatch();
  const notifications = useSelector(state => state.notifications.notifications);
  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [moreResultsLeft, setMoreResultsLeft] = useState(true);
  const [createdAtLimit, setCreatedAtLimit] = useState(null);
  const [page, setPage] = useState(0);

  useEffect(() => {
    var now = moment();
    setCreatedAtLimit(now);
    dispatch(loadUser())
      .then(() => {
        dispatch(refreshNotifications(now.toISOString(), page))
          .then(() => {
            setIsLoading(false);
            setMoreResultsLeft(true);
          });
      });
  }, []);

  const refresh = () => {
    var now = moment();
    setCreatedAtLimit(now);
    setIsRefreshing(true);
    setPage(0);
    dispatch(refreshNotifications(now.toISOString(), 0))
      .then((results) => {
        setIsRefreshing(false);
        setMoreResultsLeft(true);
      });
  };

  return (
    <Page showMenu header='Notifications'>
      <Box>
        {isLoading && notifications.length === 0 &&
          <EmptyLoadingState />
        }
        {!isLoading && notifications.length === 0 &&
          <Box p={2}>
            <Alert severity="info">
              You do not have any notifications
            </Alert>
          </Box>
        }
        {isRefreshing && 
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 1 }}>
            <CircularProgress size={'1.5rem'}/>
          </Box>
        }
        {!isLoading && notifications.length > 0 &&
          <InfiniteScroll
            dataLength={notifications.length}
            next={() => {
              setPage(page + 1);
              dispatch(searchNotifications(createdAtLimit.toISOString(), page + 1))
                .then((results) => {
                  if(results.length === 0){
                    setMoreResultsLeft(false);
                  }
                });
            }}
            hasMore={moreResultsLeft}
            loader={
              <Box sx={{ display: 'flex', justifyContent: 'center', p: 1 }}>
                <CircularProgress size={'1.5rem'}/>
              </Box>
            }
            refreshFunction={refresh}
            pullDownToRefresh
            pullDownToRefreshThreshold={50}
          >
            {notifications.map((notification) =>
              <Box key={notification.id} >
                <Notification notification={notification}/>
                <Divider />  
              </Box>  
            )}
          </InfiniteScroll>
        }
      </Box>
    </Page>
  );
}

