import React, { useContext } from 'react';
import {
  Box
}from '@mui/material';

import { AccountOnboardingContext } from '@context';
import { useTheme } from '@emotion/react';

export default function AccountOnboardingLoadingBar() {
  var { accountOnboarding } = useContext(AccountOnboardingContext);
  var theme = useTheme();

  const Section = (props) => {
    const { isComplete } = props;
    return (
      <Box sx={{ 
        width: `${100/7}%`,
        px: 0.5
      }}>
        <Box 
          sx={{ 
            border: '1px solid #fff',
            background: isComplete ? theme.palette.braceGreenLight.main : 'transparent',
            width: '100%',
            height: '1.25vh'
          }}>
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={{ display: 'flex', py:1 }}>
      <Section isComplete={accountOnboarding?.hasSignedUpToBrace}/>
      <Section isComplete={accountOnboarding?.isEmailVerified} />
      <Section isComplete={accountOnboarding?.hasAddedProfilePhoto} />
      <Section isComplete={accountOnboarding?.hasTakenRecoveryCourse} />
      <Section isComplete={accountOnboarding?.hasAddedDiary} />
      <Section isComplete={accountOnboarding?.hasContributedToCommunity} />
      <Section isComplete={accountOnboarding?.hasAddedRecoveryGoal} />
    </Box>
  );
}