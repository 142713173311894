import {SET_NOTIFICATIONS, RESET_NOTIFICATIONS} from '../actions/notifications'

export default function notificationsReducer(state = {notifications:[]}, action) {
  switch (action.type) {
    case SET_NOTIFICATIONS:
      return { 
        ...state,
        notifications: [...state.notifications, ...action.notifications],
     }
    case RESET_NOTIFICATIONS:
      return {notifications: action.notifications}
    default:
      return state
  }
}