import React from 'react';

import { 
  Card,
  Box,
  Typography
} from '@mui/material';
import { Icon } from '@components/atoms';

export default function EmptySection(props){
  const { iconType, iconSize='80px', action, title, message, opacity, dataTestId } = props;

  return (
    <Card 
      data-testid={dataTestId}
      variant='outlined'
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', height: '100%', p: 1, opacity }}
      onClick={action}
    >
      <Icon type={iconType} size={iconSize} delay={1000}/>
      <Box>
        <Typography variant='body1'
          textAlign='center'
          fontWeight='bold'
          data-testid='recovery-plan-the-first-step'>
          {title}
        </Typography>
        <Typography variant='body1'
          textAlign='center'
          fontSize='0.95rem'
          sx={{ paddingLeft: 2 }}>
          {message}
        </Typography>
      </Box>
    </Card>
  );
}

