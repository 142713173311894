import React, { useEffect, useContext, useState } from 'react';

import {
} from '@mui/material';

import { ConfirmationModal } from '@components/molecules';

import { UserContext } from '@context/user';
import { ServicesContext } from '@context/services';

export default function Invites(props) {
  var { user, getUserToken, refresh } = useContext(UserContext);
  var { api } = useContext(ServicesContext);
  const [invite, setInvite] = useState(null);

  const loadInvites = async () => {
    setInvite(null);
    var invites = await api.post({ url: 'invites/search', getUserToken, data: { inviteeEmail: user.email } });
    if(invites.results.length > 0){
      setInvite(invites.results[0]);
    }
  };

  useEffect(() => {
    loadInvites();
  }, []);

  const acceptInvite = async () => {
    await api.put({ url: `invites/${invite.id}/accept`, getUserToken });
    loadInvites();
    refresh();
  };

  const rejectInvite = async (wasAccepted) => {
    if(!wasAccepted){
      await api.put({ url: `invites/${invite.id}/reject`, getUserToken });
    }
    loadInvites();
  };

  return (
    <>
      {invite != null &&
        <ConfirmationModal isOpen={true} 
          title='Pending Invite'
          handleClose={rejectInvite}
          handleConfirmation={acceptInvite}
          confirmText='Accept'
          cancelText='Reject'
          message={`You have been invited to join ${invite.organisationName}. Would you like to accept?`}/>
      }
    </>
  );
}
