import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';

import {
  Dialog,
  Box,
  Divider
}from '@mui/material';

import { EventsContext } from '@context';
import { DialogHeader, DialogTransition } from '@components/atoms';
import Step1 from './step-1';
import Step2 from './step-2';
import Step3 from './step-3';
import Buttons from './buttons';
import { UserContext } from '@context/user';
import { ServicesContext } from '@context/services';
import { useSnackbar } from 'notistack';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <DialogTransition direction="up" ref={ref} {...props} />;
});

const initialSettings = {
  startDate: moment(),
  endDate: moment().add(7, 'days'),
  name: '',
  timesPerDay: 1,
  daysOfWeek: ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'],
  selectedPlan: ''
};

export default function CreateProgramDialog(props) {
  const { isOpen, close, programBeingEdited } = props;
  const { trackEvent } = useContext(EventsContext);
  const { getUserToken } = useContext(UserContext);
  const { api } = useContext(ServicesContext);
  const [activeStep, setActiveStep] = useState(0);
  const [settings, setSettings] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if(isOpen){
      if(programBeingEdited?.id){
        trackEvent(`Recovery:Programs:Edit:${activeStep > 0 ? `Step:${activeStep + 1}` : 'Open'}`, { programId: programBeingEdited.id });
      }else{
        trackEvent(`Recovery:Programs:New:${activeStep > 0 ? `Step:${activeStep + 1}` : 'Open'}`);
      }
     
    }else{
      if(settings?.startDate){
        trackEvent('Recovery:Programs:Closed');
      }
      setSettings(initialSettings);
      setActiveStep(0);
    }
  }, [activeStep, isOpen]);

  useEffect(() => {
    if(programBeingEdited){
      setSettings({
        startDate: moment(programBeingEdited.startDate),
        endDate: moment(programBeingEdited.endDate),
        name: programBeingEdited.name,
        timesPerDay: 1,
        daysOfWeek: programBeingEdited.daysOfWeek,
        selectedPlan: programBeingEdited.plan,
        notes: programBeingEdited.notes
      });
    }
  }, [programBeingEdited]);

  const saveProgram = async () => {
    setIsSaving(true);

    var data = {
      daysOfWeek: settings.daysOfWeek,
      notes: settings.notes,
      planId: settings.selectedPlan.id,
      name: settings.name,
      startDate: settings.startDate.startOf('day').toISOString() ,
      endDate: settings.endDate.startOf('day').toISOString(),
      numberOfTimesPerDay: settings.timesPerDay
    };
    
    if(programBeingEdited){
      await api.put({ url: `recovery/programs/${programBeingEdited.id}`, data, getUserToken });
      trackEvent('Recovery:Programs:Update', { programId: programBeingEdited.id });
      enqueueSnackbar('Program successfully updated', { 
        variant: 'success'
      });
    }else{
      await api.post({ url: 'recovery/programs', data, getUserToken });
      trackEvent('Recovery:Programs:Save');
      enqueueSnackbar('Program successfully created', { 
        variant: 'success'
      });
    }
    
    setIsSaving(false);
    close(true);
  };

  const handleNext = () => {
    if(activeStep == 2){
      saveProgram();
    }else{
      setActiveStep(activeStep + 1);
    }
    
  };

  const isNextDisabled = () => {
    if(activeStep == 0){
      return settings.selectedPlan?.id == null;
    }
    if(activeStep == 1){
      return settings.startDate.isAfter(settings.endDate) ||
        settings.daysOfWeek.length == 0 ||
        settings.timesPerDay < 1;
    }
    if(activeStep == 2){
      return settings.name == '';
    }
    return true;
  };

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={close}
      TransitionComponent={Transition}
    >
      <Box sx={{ 
        height: 'calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))', 
        marginTop: 'calc(env(safe-area-inset-top))', 
        marginBottom: 'calc(env(safe-area-inset-bottom))', 
        display: 'flex', 
        flexDirection: 'column' }}>
        <DialogHeader title='New Program' close={close}/>
        <Divider />
        {activeStep == 0 &&
          <Step1 settings={settings} handleChange={setSettings} />
        }
        {activeStep == 1 &&
          <Step2 settings={settings} handleChange={setSettings}/>
        }
        {activeStep == 2 &&
          <Step3 settings={settings} handleChange={setSettings} />
        }
        <Buttons 
          next={handleNext}
          nextText={activeStep == 2 ? 
            programBeingEdited ?
              'Update' : 
              'Create' 
            : 'Next'}
          isNextDisabled={isNextDisabled()}
          isNextLoading={isSaving}
          back={activeStep > 0 ? 
            () => setActiveStep(activeStep - 1) :
            null
          }
        />
      </Box>

    </Dialog>
  );
}