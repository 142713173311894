import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import { 
  Box,
  Stack,
  Typography
} from '@mui/material';

import HistoryEduIcon from '@mui/icons-material/HistoryEdu';

import Page from './../../../../../../components/page/page';
import { Icon } from '@components/atoms';
import Card from './../../../../../../components/molecules/card';
import DiaryEntry from './diary-entry';

import CreateDiaryDialog from './../../diary/create-dialog';
import DiaryGraph from './graph';
import { loadUser } from './../../../../../../actions/users';
import { loadDiary } from './../../../../../../actions/diary';
import { EventsContext } from '@context';
import { FabButton } from './../../../../../../components/atoms';

const IndexContainer = () => {
  const dispatch = useDispatch();
  const [diaryEntries, setDiaryEntries] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isCreateDiaryOpen, setIsCreateDiaryOpen] = React.useState(false);
  const [diaryEntryBeingEdited, setDiaryEntryBeingEdited] = React.useState(null);
  const { trackEvent } = useContext(EventsContext);
  const location = useLocation();
  const history = useHistory();

  const query = new URLSearchParams(
    location.search
  );

  const loadDiaryEntries = () => {
    setIsLoading(true);
    dispatch(loadDiary())
      .then(diaryEntries => {
        setDiaryEntries(diaryEntries);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    dispatch(loadUser())
      .then(() => {
        trackEvent('Diaries:Open');
        loadDiaryEntries();
      });
    if(query.get('defaultOpen') === 'true'){
      setIsCreateDiaryOpen(true);
      query.delete('defaultOpen');
      history.replace({
        search: query.toString()
      });
    }
  }, []);

  useEffect(() => {
    if(query.get('defaultOpen') === true){
      setIsCreateDiaryOpen(true);
      query.delete('defaultOpen');
      history.replace({
        search: query.toString()
      });
    }
  }, [query.get('defaultOpen')]);

  return (
    <Page showMenu header='Diary' shouldShowFeedbackRequest={true}>
      <Box sx={{ height: 'calc(100vh - 56px - 56px - env(safe-area-inset-top) - env(safe-area-inset-bottom))', display: 'flex', flexDirection: 'column' }}>
        {!isLoading && diaryEntries.length > 0 &&
      <>
        <DiaryGraph data={diaryEntries}/>
        <Box>
          <Stack spacing={1} sx={{ px: 1 }}>
            {diaryEntries.map((diaryEntry) => {
              return(
                <Box key={diaryEntry.id}>
                  <DiaryEntry 
                    diaryEntry={diaryEntry}
                    initiateEditEntry={(entry) => {
                      setDiaryEntryBeingEdited(entry);
                      setIsCreateDiaryOpen(true);
                    }} />
                </Box>
              );
            })}
          </Stack>
        </Box>
      </>
        }
        {!isLoading && diaryEntries.length === 0 &&
        <Box py={5}>
          <Box display='flex'
            flexDirection='column'
            alignItems='center'
            px={5}>
            <Icon type='inkwell'/>
            <Card title='Document Your Journey'>
              <Stack spacing={1}>
                <Typography component="div">
                  <Box sx={{ fontWeight: 'regular', typography: 'body1', color: 'text.secondary', textAlign: 'center' }}>
                    Keeping a recovery diary is proven to increase growth and resilience, and lead to a more positive recovery experience.
                  </Box>
                </Typography>
              </Stack>
            </Card>
          </Box>
        </Box>
        }
        {isLoading &&
        <Stack spacing={1} sx={{ px: 1 }}>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((index) => 
            <Box key={index}>
              <DiaryEntry diaryEntry={{}} isLoading={true} />
            </Box>
          )}        
        </Stack>   
        }
      </Box>
      <FabButton 
        dataTestId='diary-create-new-button'
        onClick={() => setIsCreateDiaryOpen(true)}/>
      <CreateDiaryDialog isOpen={isCreateDiaryOpen} 
        diaryEntryBeingEdited={diaryEntryBeingEdited}
        close={() => {
          setIsCreateDiaryOpen(false); 
          setDiaryEntryBeingEdited(null);
          loadDiaryEntries();}}/>
    </Page>
  );
};

export default IndexContainer;