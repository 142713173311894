import React from 'react';

import { 
  Box,
  Skeleton
} from '@mui/material';

import config from './../../config';

import { Image as MuiImage } from 'mui-image';

export default function Image(props) {
  return (
    <Box sx={{ borderRadius: 1 }}>
      <MuiImage
        src={`${config.profileImagesBaseUrl}images/${props.src}`}
        sx={{ borderRadius: 1 }}
        duration={500}
        shift="top"
        width={'100%'} />
    </Box>
  );
}