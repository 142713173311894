import React, { useCallback, useContext, useState } from 'react';

import { 
  Box,
  Card,
  Typography,
  Stack,
  IconButton
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { RecoveryContext } from '@context';

import Section from './section';
import { LoadableList, LoadableText } from '@components/atoms';
import { useHistory } from 'react-router-dom';
import { getProgramCompletionPercentage, getDaysLeft } from '@helpers/programs';
import { useTheme } from '@emotion/react';
import EmptySection from './empty-section';
import CreateProgramDialog from '../programs/new/create-program-dialog';

export default function Programs(){
  const PROGRAMS_SECTION_HEIGHT_IN_PX = 67;
  const { plans, programs, isLoadingPrograms, loadPrograms, loadActiveDaySessions } = useContext(RecoveryContext);
  const history = useHistory();
  const theme = useTheme();
  const [isCreateProgramDialogOpen, setIsCreateProgramDialogOpen] = useState(false);
  const [numberOfRows, setNumberOfRows] = useState(2);

  const programsStackRef = useCallback(node => {
    if (!node) return;
    const resizeObserver = new ResizeObserver((entries) => { 
      var height = entries[0].contentRect.height;
      if(height){
        setNumberOfRows(Math.max(1,
          Math.floor(height / PROGRAMS_SECTION_HEIGHT_IN_PX))
        );
      }
    });
    resizeObserver.observe(node);
  }, []);

  const Program = (props) => {
    const { program } = props;

    return (
      <Box sx={{ position: 'relative' }} 
        data-testid={`recovery-program-card-${program.name}`}
        onClick={() => history.push(`/recovery/programs/${program.id}`)}>
        <Card 
          variant='outlined' 
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: -1,
            width: `${getProgramCompletionPercentage(program)}%`, 
            height: '100%',
            backgroundColor: theme.palette.primary.main,
            opacity: 0.5
          }}>
        </Card>
        <Card variant='outlined' sx={{ p: 1, py: 0.5, background: 'transparent', display: 'flex', alignItems: 'center'  }}>
          <Box sx={{ flexGrow: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 0.5 }}>
              <Typography component='div' variant='body1'>
                <LoadableText isLoading={isLoadingPrograms} text={program?.name} minWidth={200}/>
              </Typography>
              <Typography component='div' variant='body2'>
                <LoadableText isLoading={isLoadingPrograms} text={`${getProgramCompletionPercentage(program)}% Complete`} minWidth={100}/>
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography component='div' variant='body2'>
                <LoadableText isLoading={isLoadingPrograms} text={`Plan Adherence : ${program.adherence}%`} minWidth={100}/>
              </Typography>
              <Typography component='div' variant='body2'>
                <LoadableText isLoading={isLoadingPrograms} text={`Days Remaining : ${getDaysLeft(program)}`} minWidth={100}/>
              </Typography>
            </Box>
          </Box>
          <IconButton onClick={() => history.push(`/recovery/programs/${program.id}`)} sx={{ paddingRight: 0 }}><ChevronRightIcon /></IconButton>
        </Card>
      </Box>
    );
  };

  return (
    <Section title='Programs'
      buttonDataTestId='recovery-program-manage-button'
      buttonText={programs.length > 0 ? 'VIEW' : null}
      buttonAction={
        plans.length == 0 ?
          null
          : () => { programs.length > 0  ? history.push('/recovery/programs') : setIsCreateProgramDialogOpen(true);}}
      buttonIcon= {programs.length > 0 ? <ChevronRightIcon /> : <AddCircleIcon color='primary'/>}
    >
      {(!isLoadingPrograms && programs.length == 0) &&
        <>
          <EmptySection 
            opacity= {plans.length === 0 ? 0.4 : 1}
            iconType='appointment'
            iconSize='80px'
            message={plans.length > 0 ? 
              'Create a recovery program from your plans.' 
              : 'Create an exercise plan to assign to your recovery program.'}
            action={plans.length > 0 ?
              () => programs.length > 0  ? history.push('/recovery/programs') : setIsCreateProgramDialogOpen(true)
              : null}/>
          <CreateProgramDialog 
            isOpen={isCreateProgramDialogOpen}
            close={(shouldRefresh) => {
              setIsCreateProgramDialogOpen(false);
              if(shouldRefresh){
                loadPrograms(true);
                loadActiveDaySessions(true);
              }
            }}
          />
      
        </>
      }

      <Stack spacing={1} sx={{ flexGrow: 1 }} ref={programsStackRef}>
        <LoadableList 
          numberOfRows={numberOfRows}
          isLoading={isLoadingPrograms}
          rows={programs.slice(0, numberOfRows)}
          getRow={(program) => <Program key={program.id} program={program}/> }
        />
      </Stack>
    </Section>
  );
}

