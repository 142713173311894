import React from 'react';

import { 
  Stack,
  Checkbox,
  Avatar
} from '@mui/material';

const DayCheckBox = (props) => {
  return (
    <Avatar
      sx={{ 
        bgcolor: props.checked ? 'secondary.main' : '#fafafa',
        color: props.checked ? '#fff' : 'secondary.light',
        border: props.checked ? 'none' : '1px solid',
        borderColor: 'secondary.light',
        fontSize: 12,
        margin: 0,
        height: 35,
        width: 35
      }}>
      {props.day}
    </Avatar>
  );
};

export default function DaysOfTheWeek(props) {
  const { values, onChange } = props;
  return (
    <Stack direction='row' spacing={1} justifyContent="space-around">
      <Checkbox 
        name="monday"
        checked={values.monday}
        onChange={onChange}
        disabled={!onChange}
        sx={{ padding: 0 }}
        inputProps={{ 'aria-label': 'controlled' }}
        icon={<DayCheckBox checked={false} day='Mon'/>}
        checkedIcon={<DayCheckBox checked day='Mon'/>}
      />
      <Checkbox 
        name="tuesday"
        checked={values.tuesday}
        onChange={onChange}
        disabled={!onChange}
        sx={{ padding: 0 }}
        inputProps={{ 'aria-label': 'controlled' }}
        icon={<DayCheckBox checked={false} day='Tue'/>}
        checkedIcon={<DayCheckBox checked day='Tue'/>}
      />
      <Checkbox 
        name="wednesday"
        checked={values.wednesday}
        onChange={onChange}
        disabled={!onChange}
        sx={{ padding: 0 }}
        inputProps={{ 'aria-label': 'controlled' }}
        icon={<DayCheckBox checked={false} day='Wed'/>}
        checkedIcon={<DayCheckBox checked day='Wed'/>}
      />
      <Checkbox 
        name="thursday"
        checked={values.thursday}
        onChange={onChange}
        disabled={!onChange}
        sx={{ padding: 0 }}
        inputProps={{ 'aria-label': 'controlled' }}
        icon={<DayCheckBox checked={false} day='Thu'/>}
        checkedIcon={<DayCheckBox checked day='Thu'/>}
      />
      <Checkbox 
        name="friday"
        checked={values.friday}
        onChange={onChange}
        disabled={!onChange}
        sx={{ padding: 0 }}
        inputProps={{ 'aria-label': 'controlled' }}
        icon={<DayCheckBox checked={false} day='Fri'/>}
        checkedIcon={<DayCheckBox checked day='Fri'/>}
      />
      <Checkbox 
        name="saturday"
        checked={values.saturday}
        onChange={onChange}
        disabled={!onChange}
        sx={{ padding: 0 }}
        inputProps={{ 'aria-label': 'controlled' }}
        icon={<DayCheckBox checked={false} day='Sat'/>}
        checkedIcon={<DayCheckBox checked day='Sat'/>}
      />
      <Checkbox 
        name="sunday"
        checked={values.sunday}
        onChange={onChange}
        disabled={!onChange}
        sx={{ padding: 0 }}
        inputProps={{ 'aria-label': 'controlled' }}
        icon={<DayCheckBox checked={false} day='Sun'/>}
        checkedIcon={<DayCheckBox checked day='Sun'/>}
      />
    </Stack>
  );
}