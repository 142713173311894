export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
export const RESET_NOTIFICATIONS = 'RESET_NOTIFICATIONS'

export const searchNotifications = (createdAtLimit, page) => {
  return(dispatch, getState, services) => {
    var user = getState().user;

    return services.apiClient.searchNotifications({user, createdAtLimit, page})
      .then(response => {
        if(response.results.length > 0){
          dispatch(setNotifications(response.results))
        }
        return response.results
      })
  };
}

export const refreshNotifications = (createdAtLimit, page) => {
  return(dispatch, getState, services) => {
    var user = getState().user;

    return services.apiClient.searchNotifications({user, createdAtLimit, page})
      .then(response => {
        if(response.results.length > 0){
          dispatch(resetNotifications(response.results))
        }
        return response.results
      })
  };
}

export const markAsRead = (notificationId) => {
  return(dispatch, getState, services) => {
    var user = getState().user;
    return services.apiClient.markNotificationAsRead({user, notificationId});
  };
}

export const resetNotifications = (notifications) => {
  return{
    type: RESET_NOTIFICATIONS,
    notifications
  }
}

export const setNotifications = (notifications) => {
  return{
    type: SET_NOTIFICATIONS,
    notifications
  }
}
