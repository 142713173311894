import React from 'react';

import { 
  Box, 
  Typography,
  Card,
  Stack
} from '@mui/material';

import { LoadableText, ProgressLinear } from '@components/atoms';
import { getProgramCompletionPercentage } from '@helpers/programs';

export default function ProgramMetrics(props){
  var { program, isLoading, sessions } = props;

  var completeSetCount = 0;
  var completeRepCount = 0;

  sessions.forEach(s => {
    s.plan.exercises.forEach(e => {
      e.sets.forEach(set => {
        if(set.isComplete){
          completeSetCount++;
          completeRepCount += set.quantity;
        }
      });
    });
  });

  return (
    <Box>
      <Box sx={{ p: 1 }}>
        <ProgressLinear value={getProgramCompletionPercentage(program)} text={`${getProgramCompletionPercentage(program)}% Complete`} height={40}/>
      </Box>
      <Stack sx={{ p: 1 }} spacing={1}>
        <Card sx={{ width: '100%', px: 4, py: 2 }} variant='outlined'>
          <Box sx={{ display : 'flex', justifyContent: 'center' }}>
            <LoadableText sx={{ paddingRight : 1 }}
              isLoading={isLoading}
              text={`${program?.adherence}% Adherence`}
              minWidth={200}/>
          </Box>
        </Card>
        <Card sx={{ width: '100%', px: 4, py: 2 }} variant='outlined'>
          <Box sx={{ display : 'flex', justifyContent: 'center' }}>
            <LoadableText sx={{ paddingRight : 1 }}
              isLoading={isLoading}
              text={`${program?.sessions.completeCount} of ${program?.sessions.totalCount} Sessions Completed`}
              minWidth={200}/>
          </Box>
        </Card>
        <Card sx={{ width: '100%', px: 4, py: 2 }} variant='outlined'>
          <Box sx={{ display : 'flex', justifyContent: 'center' }}>
            <LoadableText sx={{ paddingRight : 1 }}
              isLoading={isLoading}
              text={`${completeSetCount} Sets Completed`}
              minWidth={200}/>
          </Box>
        </Card>
        <Card sx={{ width: '100%', px: 4, py: 2 }} variant='outlined'>
          <Box sx={{ display : 'flex', justifyContent: 'center' }}>
            <LoadableText sx={{ paddingRight : 1 }}
              isLoading={isLoading}
              text={`${completeRepCount} Reps Completed`}
              minWidth={200}/>
          </Box>
        </Card>
      </Stack>
    </Box>
  );
}

