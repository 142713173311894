import React from 'react';

import { 
  Box,
  Typography,
  Divider,
  Stack
} from '@mui/material';

import moment from 'moment';
import DiaryToDo from './diary-to-do';
import SessionsToDo from './sessions-to-do';

export default function ToDo(){
  const today = moment();

  return (
    <Box p={1}  paddingTop={0}>
      <Box sx={{ display : 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 1 }}>
        <Typography variant='h6' sx={{ lineHeight: 1, flexGrow: 1, fontWeight:'medium' }}>
          {`${today.format('dddd Do MMMM')}`}
        </Typography>
      </Box>
      <Divider />
      <Stack p={1} spacing={1}>
        <SessionsToDo />
        <DiaryToDo />
      </Stack>
    </Box>
  );
}

