import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import queryString from 'query-string';
import { 
  Dialog,
  Divider,
  Typography,
  Box
} from '@mui/material';

import { DialogTransition, DialogHeader, ProgressLinear, LoadableText } from '@components/atoms';
import { SuccessDialog } from '@components/molecules';
import { UserContext, ServicesContext, EventsContext, AccountOnboardingContext } from '@context'; 
import CourseContent from './content';

import { getCompletedSteps, getTotalSteps, getProgress } from '../helpers';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { NewPostContext } from '@components/organisms';

import { svgToImage } from './../../../utils/images';

export default function ViewCourse(props) {
  const { refresh } = props;
  var history = useHistory();
  const { search } = useLocation();
  const { api } = useContext(ServicesContext);
  const { user } = useContext(UserContext);
  var { accountOnboarding, loadAccountOnboarding } = useContext(AccountOnboardingContext);
  const { trackEvent } = useContext(EventsContext);
  const [courseId, setCourseId] = useState(null);
  const [course, setCourse] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const { openNewPostDialog } = useContext(NewPostContext);

  const values = queryString.parse(search);

  const loadCourse =async() => {
    setIsLoading(true);
    var result = await api.get({ url: `courses/${courseId}`, user });
    setIsLoading(false);
    setCourse(result);
  };

  const savePost = async (data) => {
    const formData = new FormData();
    formData.append('message', data.message);

    if(data.image){
      formData.append('image', data.image);
    }

    if(data.organisation){
      formData.append('organisationId', data.organisation.id);
    }

    if(data.team){
      var teamIds = [ data.team.id ];
      formData.append('teamIds', teamIds);
    }

    await api.post({ data: formData, url: 'community/posts', user });
  };

  const openCreatePostDialog = (c) => {
    setIsSuccessDialogOpen(false);
    axios({
      method: 'get',
      url: 'https://bracesocialprod.blob.core.windows.net/static/course_complete.svg'
    })
      .then(async(response) => {
        var svg = response.data.replace('$$COURSE_NAME_GOES_HERE$$', `"${course.title}"`);
        var i = await svgToImage({ svg, mimetype: 'image/png', quality: 1, outputFormat: 'blob' });

        openNewPostDialog({ 
          title: 'New Check-In', 
          placeholder: 'Your Post..', 
          buttonAction: savePost, 
          successToastMessage : 'Check-in Created!', 
          isAccessControlEnabled: true,
          isPrivateEnabled: true,
          initialImage: i
        });
      });
  };

  const completeStep =async(stepNumber) => {
    var result = await api.put({ url: `courses/${courseId}/step/${stepNumber}/toggle`, user });
    setCourse(result);

    if(result?.account?.completedAt){
      setIsSuccessDialogOpen(true);
    }
  };

  const closeCourse = () => {
    refresh();
    history.push('learn');
  };

  useEffect(() => {
    if(values?.courseId){
      setCourseId(values?.courseId);
    }else if(courseId){
      setCourseId(null);
    }
  }, [values]);

  useEffect(() => {
    if(courseId && user?.id){
      loadCourse();
      trackEvent('Community:Course:Open', { courseId });
    }
  }, [courseId, user]);

  return (
    <Dialog
      open={courseId != null}
      fullScreen
      TransitionComponent={DialogTransition}
      sx={{ ...props.sx }} >
      <Box 
        sx={{ 
          paddingTop: 'env(safe-area-inset-top)',   
          paddingBottom: 'env(safe-area-inset-bottom)'
        }}>
        <DialogHeader doHistoryBack={true}
          title={course?.title}
          isLoading={isLoading}
          close={closeCourse}/>
        <Divider />
        <Box sx={{ p: 2, px: 4 }}>
          <Typography variant='h5' fontWeight='medium'>
          Course Progress
          </Typography>
          <Box sx={{ display: 'flex' }}>
            <Typography component='div'
              variant='body1'
              color='text.secondary'
              sx={{ flexGrow: 1 }}>
              <LoadableText isLoading={isLoading} text={`Completed ${getCompletedSteps(course)} of ${getTotalSteps(course)} lessons`}/>
            </Typography>
            <Typography component='div' variant='body1' color='text.secondary'>
              <LoadableText isLoading={isLoading} text={`${getProgress(course)}%`}/>
            </Typography>
          </Box>
          <Box paddingTop={0.5}>
            <ProgressLinear value={getProgress(course)}/>
          </Box>
        </Box>
        <CourseContent course={course} isLoading={isLoading} completeStep={completeStep}/>
        <SuccessDialog isOpen={isSuccessDialogOpen}
          iconType='graduation'
          close={() => {
            setIsSuccessDialogOpen(false);
        
            if(!accountOnboarding?.hasTakenRecoveryCourse){
              loadAccountOnboarding();
            }
        
          }}
          title='Course Complete'
          message={<><p>{`Well done - you have completed the "${course?.title}" course!`}</p><p>Post a check-in celebrate your progress!</p></>}
          action={openCreatePostDialog}
          actionText='Post to Community'/>
      </Box>
    </Dialog>
  );
}