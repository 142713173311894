import React, { useContext, useEffect } from 'react';

import { 
  Box,
  Typography,
  Card
} from '@mui/material';

import { RecoveryContext } from '@context';
import moment from 'moment';
import { Icon, LoadableText } from '@components/atoms';
import { useHistory } from 'react-router-dom';
import { UserContext } from '@context/user';

export default function SessionsToDo(){
  const { user } = useContext(UserContext);
  var history = useHistory();
  const { moveActiveDay, activeDaysSessions, isLoadingActiveDaysSessions } = useContext(RecoveryContext);
  const today = moment();

  useEffect(() => {
    if(user?.id){
      moveActiveDay(today);
    }
  }, [user]);

  var toDoSessions = activeDaysSessions.filter(s => !s.plan.isComplete);

  function ToDoItem(props){
    const { iconType, title, message, onClick } = props;
  
    return (
      <Card 
        variant='outlined'
        sx={{ display: 'flex', alignItems: 'center', height: '100%', px: 2, py: 1 }}
        onClick={onClick}
      >
        <Icon type={iconType}
          size={'60px'}
          delay={1000}
          isLoading={isLoadingActiveDaysSessions}/>
        <Box sx={{ flexGrow: 1, px: 1 }}>
          <Typography variant='body1'
            component='div'
            textAlign='center'
            fontWeight='medium'>
            <LoadableText isLoading={isLoadingActiveDaysSessions} text={title} />
          </Typography>
          <Typography variant='body2' textAlign='center' component='div'>
            <LoadableText isLoading={isLoadingActiveDaysSessions} text={message} />
          </Typography>
        </Box>
      </Card>
    );
  }

  var sessionPlanList = toDoSessions.length > 2 ? 
    toDoSessions.slice(0, 2).map(s => (<Box key={s.id}>{`${s.plan.planName}${s.program?.name ? ' - ' + s.program?.name : ''}`}</Box>))
    : toDoSessions.map(s => (<Box key={s.id}>{`${s.plan.planName}${s.program?.name ? ' - ' + s.program?.name : ''}`}</Box>));

  if(toDoSessions.length > 2){
    sessionPlanList.push(<Box key={'XMore'}>{`+ ${toDoSessions.length - 2} More`}</Box>);
  }

  return (
    <Box>
      <Box>
        {!isLoadingActiveDaysSessions &&
          <Box>
            {toDoSessions.length > 0 && 
              <ToDoItem 
                onClick={() => history.push('/recovery')}
                iconType='deadlift'
                title={`${toDoSessions.length} Session${toDoSessions.length > 1 ? 's' : ''} to Complete Today.`}
                message={sessionPlanList}
              />
            }
            {toDoSessions.length === 0 && 
              <ToDoItem 
                onClick={() => history.push('/recovery')}
                iconType='deadlift'
                title={'You Have No Sessions Today'}
                message={'Want to add a session, plan or program?'}
              />
            }
        
          </Box>
        }
        {isLoadingActiveDaysSessions &&
          <ToDoItem />
        }

      </Box>
    </Box>
  );
}

