import React from 'react';

import { 
} from '@material-ui/core';

import Page from '../../../components/page/page';

import { ProfileUpdate as ProfileUpdateForm } from '@components/templates';

export default function ProfileUpdate(){
  return (
    <>
      <Page>
        <ProfileUpdateForm />
      </Page>
    </>
  );
}

