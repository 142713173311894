import mixpanel from 'mixpanel-browser';
import moment from 'moment';

export const CHANGE_TAB = 'CHANGE_TAB';
export const RESET_CHECK_INS = 'RESET_CHECK_INS';
export const CLEAR_POST_SEARCH_RESULTS = 'CLEAR_POST_SEARCH_RESULTS';
export const ADD_POST_SEARCH_RESULTS = 'ADD_POST_SEARCH_RESULTS';
export const INIT_POST_SEARCH_RESULTS = 'INIT_POST_SEARCH_RESULTS';
export const SET_PROFILE_IMAGE = 'SET_PROFILE_IMAGE';
export const SET_POST = 'SET_POST';
export const LOADING_POST = 'LOADING_POST';
export const LOADING_POST_COMMENTS = 'LOADING_POST_COMMENTS';
export const SET_POST_COMMENTS = 'SET_POST_COMMENTS';
export const CLEAR_CHECK_IN = 'CLEAR_CHECK_IN';
export const SET_CHECK_IN_COMMENTS = 'SET_CHECK_IN_COMMENTS';
export const CLEAR_CHECK_IN_COMMENTS = 'CLEAR_CHECK_IN_COMMENTS';
export const ADD_PROFILE_SEARCH_RESULTS = 'ADD_PROFILE_SEARCH_RESULTS';
export const INIT_PROFILE_SEARCH_RESULTS = 'INIT_PROFILE_SEARCH_RESULTS';
export const LOADING_GROUPS = 'LOADING_GROUPS';
export const SET_GROUPS = 'SET_GROUPS';
export const LOADING_TOPICS = 'LOADING_TOPICS';
export const SET_TOPICS = 'SET_TOPICS';
export const LOADING_TOPIC = 'LOADING_TOPIC';
export const SET_TOPIC = 'SET_TOPIC';
export const LOADING_TOPIC_COMMENTS = 'LOADING_TOPIC_COMMENTS';
export const SET_TOPIC_COMMENTS = 'SET_TOPIC_COMMENTS';

export const changeTab = (activeTab) => {
  return(dispatch, getState, services) => {
    dispatch({ type: CHANGE_TAB, activeTab });
  };
};

export const searchProfiles = (searchTerm, createdAtLimit, page) => {
  return(dispatch, getState, services) => {
    var user = getState().user;

    return services.apiClient.searchProfiles(user, searchTerm, createdAtLimit, page)
      .then(response => {
        dispatch(addProfileSearchResults(response));
        return response;
      });
  };
};

export const getComment = (id) => {
  return(dispatch, getState, services) => {
    var user = getState().user;

    return services.apiClient.getComment(id, user);
  };
};

export const getCheckInComments = (id) => {
  return(dispatch, getState, services) => {
    
    dispatch(clearCheckInComments());

    var user = getState().user;

    return services.apiClient.getCheckInComments(id, user)
      .then(response => {
        dispatch(setCheckInComments(response.results));
      });
  };
};

export const getLikes = (parentId) => {
  return(dispatch, getState, services) => {
    var user = getState().user;
    return services.communityApiClient.getLikes({ user, parentId })
      .then(response => {
        return response.results;
      });
  };
};

export const saveComment = (checkInId, message, parentId) => {
  return(dispatch, getState, services) => {
    var user = getState().user;
    mixpanel.track('Interaction:CheckIn:Comment:Created');
    return services.apiClient.saveComment(checkInId, message, parentId, user);
  };
};

export const getGroups = (forceRefresh) => {
  return(dispatch, getState, services) => {
    var user = getState().user;

    if(getState().community.groups.results.length === 0 || forceRefresh){
      dispatch({ type: LOADING_GROUPS });
      return services.communityApiClient.searchGroups({ user })
        .then(response => {
          dispatch(setGroups(response.results));
        });
    }

    return Promise.resolve();
  };
};

export const createTopic = (groupId, title, description, image) => {
  return(dispatch, getState, services) => {
    var user = getState().user;

    return services.communityApiClient.createTopic({ user, groupId, name: title, description, image })
      .then(response => {
        return response;
      });
  };
};

export const getTopics = (groupId, sortBy = 'LATEST') => {
  return(dispatch, getState, services) => {
    var user = getState().user;

    dispatch({ type: LOADING_TOPICS });

    return services.communityApiClient.searchTopics({ user, groupId, sortBy })
      .then(response => {
        dispatch(setTopics(response.results));
      });
  };
};

export const getTopic = (topicId) => {
  return(dispatch, getState, services) => {
    var user = getState().user;
    dispatch({ type: LOADING_TOPIC });
    return services.communityApiClient.getTopic({ user, topicId })
      .then((topic) => {
        dispatch(setTopic(topic));
        return topic;
      });
  };
};

export const getTopicComments = (topicId) => {
  return(dispatch, getState, services) => {
    var user = getState().user;
    dispatch({ type: LOADING_TOPIC_COMMENTS });
    return services.communityApiClient.getTopicComments({ user, topicId })
      .then((response) => {
        return dispatch(setTopicComments(response.results));
      });
  };
};

export const saveTopicComment = (message, topicId, parentCommentId) => {
  return(dispatch, getState, services) => {
    var user = getState().user;
    return services.communityApiClient.saveTopicComment({ user, topicId, message, parentCommentId });
  };
};

export const savePostComment = (message, postId, parentCommentId) => {
  return(dispatch, getState, services) => {
    var user = getState().user;
    return services.communityApiClient.savePostComment({ user, postId, message, parentCommentId });
  };
};

export const likeTopic = (topicId) => {
  return(dispatch, getState, services) => {
    var user = getState().user;
    return services.communityApiClient.likeTopic({ user, topicId });
  };
};

export const likeComment = (commentId) => {
  return(dispatch, getState, services) => {
    var user = getState().user;
    return services.communityApiClient.likeComment({ user, commentId });
  };
};

export const savePost = (message, image, organisation) => {
  return(dispatch, getState, services) => {
    return services.communityApiClient.savePost({ user: getState().user, message, image, organisation });
  };
};

export const getUserPosts = (userId) => {
  return(dispatch, getState, services) => {
    var user = getState().user;

    return services.communityApiClient.searchPosts({ user, createdAtLimit: moment().toISOString(), userId, page: 0, searchTerm: '' })
      .then(response => {
        return response.results;
      })
      .catch(err => {
        services.logger.error('Failed to load posts', err);
        throw err;
      });
  };
};

export const searchPosts = ({ searchTerm, page, shouldAppend, isRefresh, userId, organisation, team }) => {
  return(dispatch, getState, services) => {
    var user = getState().user;

    var createdAtLimit = getState().community.postsSearch.createdAtLimit ?? moment();

    if(isRefresh){
      createdAtLimit = moment();
    }

    if(searchTerm && searchTerm != ''){
      services.logger.info(`Posts Search Term : ${searchTerm}`);
    }

    return services.communityApiClient.searchPosts({ user, searchTerm, createdAtLimit: createdAtLimit.toISOString(), page, userId, organisation, team })
      .then(response => {
        if(shouldAppend){
          dispatch(addPostSearchResults(response.results, page));
        }else{
          dispatch(initPostSearchResults(response.results, page, createdAtLimit));
        }
       
        return response.results;
      })
      .catch(err => {
        services.logger.error('Failed to load posts', err);
        throw err;
      });
  };
};

export const likePost = (postId) => {
  return(dispatch, getState, services) => {
    var user = getState().user;
    return services.communityApiClient.likePost({ user, postId });
  };
};

export const getPost = (id) => {
  return(dispatch, getState, services) => {
    var user = getState().user;

    dispatch({ type: LOADING_POST });

    return services.communityApiClient.getPost({ id, user })
      .then(response => {
        dispatch({
          type: SET_POST,
          post: response
        });
        return response;
      });
  };
};

export const getPostComments = (postId) => {
  return(dispatch, getState, services) => {
    var user = getState().user;
    dispatch({ type: LOADING_POST_COMMENTS });
    return services.communityApiClient.getPostComments({ user, postId })
      .then((response) => {
        dispatch({
          type: SET_POST_COMMENTS,
          comments: response.results
        });
      });
  };
};

export const addPostSearchResults = (posts, page) => {
  return{
    type: ADD_POST_SEARCH_RESULTS,
    posts,
    page
  };
};

export const clearPostSearchResults = () => {
  return{
    type: CLEAR_POST_SEARCH_RESULTS
  };
};

export const initPostSearchResults = (posts, page, createdAtLimit) => {
  return{
    type: INIT_POST_SEARCH_RESULTS,
    posts,
    page,
    createdAtLimit
  };
};

export const addProfileSearchResults = (profiles) => {
  return{
    type: ADD_PROFILE_SEARCH_RESULTS,
    profiles
  };
};

export const setGroups = (groups) => {
  return{
    type: SET_GROUPS,
    groups
  };
};

export const setTopics = (topics) => {
  return{
    type: SET_TOPICS,
    topics
  };
};

export const setTopic = (topic) => {
  return{
    type: SET_TOPIC,
    topic
  };
};

export const setTopicComments = (comments) => {
  return{
    type: SET_TOPIC_COMMENTS,
    comments
  };
};

export const clearCheckIn = () => {
  return{
    type: CLEAR_CHECK_IN
  };
};

export const setCheckInComments = (comments) => {
  return{
    type: SET_CHECK_IN_COMMENTS,
    comments
  };
};

export const clearCheckInComments = () => {
  return{
    type: CLEAR_CHECK_IN_COMMENTS
  };
};