import {OPEN_DIALOG, CLOSE_DIALOG} from './../actions/check-ins'

export default function checkInsReducer(state = {results:[]}, action) {
  switch (action.type) {
    case OPEN_DIALOG:
      state = {
        ...state, 
        isDialogOpen: true
      }
      return state;
    case CLOSE_DIALOG:
      state = {
        ...state, 
        isDialogOpen: false
      }
      return state;
    default:
      return state
  }
}