import React from 'react';
import {
  Box,
  Grow } from '@material-ui/core';

import BottomNavigation from './../navigation/bottom-navigation';
import TopBar from './../navigation/top-bar';

export default function Page(props) {
  return (
    <Box marginBottom='56px'>
      {props.showMenu &&
        <TopBar shouldShowFeedbackRequest={props.shouldShowFeedbackRequest} header={props.header} />
      }
      <Box marginTop={props.showMenu ? '56px' : '0px'}>
        <Grow timeout={200}
          in={true}
          mountOnEnter
          unmountOnExit>
          <Box>
            <Box>
              {props.children}
            </Box>
          </Box>
        </Grow>
      </Box>
      {!props.hideBottomNav &&
        <BottomNavigation showOnboardingBanner={props.showOnboardingBanner}/>
      }
    </Box>
  );
}