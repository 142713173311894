import React, { useEffect, useState } from 'react';

import { 
  Grid,
  Box,
  Divider,
  Paper,
  Typography,
  Grow
} from '@mui/material';

import ProgressSpinner from '../../../components/progress/progress-spinner';

import LoadableText from '../../../components/atoms/loadable-text';
import { Icon } from '@components/atoms';

const getIconFromType = (type) => {
  switch (type) {
  case 'MINDFUL_MASTER':
    return 'inkwell'; 
  case 'PRIZED_PHOTOGRAPHER':
    return 'camera'; 
  case 'IMPRESSIVE_INTRODUCER':
    return 'edit'; 
  case 'SESSION_SUCCESS':
    return 'stretching'; 
  case 'POWERFUL_PLANNER':
    return 'to-do-list'; 
  case 'SUPER_SUPPORTER':
    return 'thumbs-up'; 
  case 'COMMUNITY_CHAMP':
    return 'consultation'; 
  case 'TEAM_TALKER':
    return 'chat'; 
  case 'GOAL_GENIUS':
    return 'target';   

  default: 
    return 'strength';
  }

};

export default function Trophy(props){
  const { type, title, description, level, progress, hasNextLevel, levelCompleteAt, isLoading, isComplete } = props;
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    setIsCollapsed(isComplete);
  }, [isComplete]);

  const iconProps = {
    size: 45,
    trigger: level > 0 ? 'loop' : 'none',
    sx:{
      opacity: level > 0 ? 1 : 0.5
    }
  };

  return !isCollapsed ?
    <Grid item xs={ 12 }>
      <Grow in={!isCollapsed}>
        <Paper sx={{ width: '100%', m: 0.5, p: 0.5, display: 'flex', textAlign: 'center' }} 
          onClick={() => {
            if(isComplete){
              setIsCollapsed(true);
            }
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly' }}>
            <ProgressSpinner value={(progress/levelCompleteAt) * 100}
              isLoading={isLoading}
              size={60}
              icon={getIconFromType(type)}
              iconProps={iconProps}/>
            {(progress != levelCompleteAt && levelCompleteAt > 1) &&
                <Typography variant='caption' color='text.secondary'>
                  <LoadableText text={`${progress}/${levelCompleteAt}`} isLoading={isLoading} minWidth={100}/>
                </Typography>
            }
            {(level > 0 && !(level == 1 && !hasNextLevel)) &&
                <Typography variant='body1' sx={{ fontWeight: 'medium' }}>
                  {`Level ${level}`}
                </Typography>
            }
          </Box>
          <Divider flexItem orientation='vertical' sx={{ m: 1 }}/>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', width: '100%' }}>
            <Typography variant='body1' sx={{ fontWeight: 'medium' }} component='div'>
              <LoadableText text={title} isLoading={isLoading} minWidth={100} />
            </Typography>
            <Box>
              <Typography variant='body2' color='text.secondary' component='div'>
                <LoadableText text={description} isLoading={isLoading} minWidth={100}/>
              </Typography>
            </Box>
          </Box>
        </Paper> 
      </Grow>
    </Grid> :
    <Grid item>
      <Grow in={isCollapsed}>
        <Paper sx={{ display: 'flex', p: 0.5, m: 0.5, textAlign: 'center' }} onClick={() => setIsCollapsed(false)}>
          <Icon type={getIconFromType(type)}
            {...iconProps}
            size={60}
          />
        </Paper>
      </Grow>
    </Grid>;
}