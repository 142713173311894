import React from 'react';

import { 
  Box,
  Slider
} from '@mui/material';

import { styled } from '@mui/material/styles';

import { grey, red, amber } from '@mui/material/colors';

const CustomSlider = styled(Slider)(({ theme }) => ({
  height: 3,
  padding: '10px 0',
  '& .MuiSlider-thumb': {

  },
  '& .MuiSlider-valueLabel': {
 
  },
  '& .MuiSlider-track': {
    border: 'none'
  },
  '& .MuiSlider-rail': {
    opacity: 1
  },
  '& .MuiSlider-markLabel': {
    top: '30px',
    color: theme.palette.text.secondary
  }
}));

export default function DiarySlider(props) {
  const { value, dataTestId } = props;
  
  const getColor = () => {
    var level = !props.inverse ? value : 6 - value;

    switch(level){
    case 1:
      return red[500];
    case 2:
      return amber[600];
    case 4:
      return 'primary.light';
    case 5:
      return 'primary';
    default: 
      return grey[400];
    }
  };

  const onSelection = (selectionValue) => {
    props.onSelect(selectionValue);
  };

  return (
    <Box>
      <CustomSlider 
        componentsProps={{ thumb: { 'data-testid': dataTestId } }}
        value={value}
        sx={{ color: getColor() }}
        aria-label="Mood"
        defaultValue={3}
        onChange={(event) => onSelection(event.target.value)}
        marks={props.marks}
        min={1}
        max={5}
      />
    </Box>
  );
}