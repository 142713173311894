import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box
} from '@mui/material';

import Page from './../../../components/page/page';

import { loadUser } from './../../../actions/users';
import { loadPlans, loadPrograms, loadRecoveryDailyProgress } from './../../../actions/recovery';

import { EmptyState } from '@components/molecules';
import { EventsContext } from '@context/events';

import config from '@config';
import { UserContext } from '@context';

import NewRecoveryHomePage from './../home';

export default function IndexV3(){
  var dispatch = useDispatch();
  var recovery = useSelector(state => state.recovery);
  const { user } = useContext(UserContext);
  const { trackEvent } = useContext(EventsContext);
  
  useEffect(() => {
    dispatch(loadUser())
      .then(u => {
        dispatch(loadPlans());
        dispatch(loadPrograms(u.id));
        dispatch(loadRecoveryDailyProgress(recovery.currentDate));
      });
  }, []);

  if(!user?.role || user?.role === 'CLIENT'){
    return (<NewRecoveryHomePage />);
  }

  return (
    <Page showMenu header='Recovery'>
      <Box sx={{ height: 'calc(100vh - 56px - 56px - env(safe-area-inset-top) - env(safe-area-inset-bottom))' }}>
        <>
          {(!user?.role || user?.role === 'CLIENT') &&
            <NewRecoveryHomePage />
          }
          {!user?.role || user?.role !== 'CLIENT' && 
          <EmptyState 
            iconType='computer'
            title='Brace Web App' 
            message={
              <>
                <>Practitioners should use our Web App to:</>
                <li>Invite & Manage Clients</li>
                <li>Create Private Groups</li>
                <li>Allocate Plans & Goals</li>
                <li>Track Progress Metrics</li>
              </>
            }
            buttonText='Go TO Web App'
            buttonAction={() => window.open(config.webAppUrl)}
          />

          }
        </>
      </Box>
    </Page>
  );
}

