import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import mixpanel from 'mixpanel-browser';

import { 
  Box,
  Typography,
  Button,
  CircularProgress,
  Slider,
  Grid
} from '@material-ui/core';

import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import ThumbDownOutlinedIcon from '@material-ui/icons/ThumbDownOutlined';

import Alert from '@material-ui/lab/Alert';

import { Link } from 'react-router-dom';

import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';

import { makeStyles } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';

import Page from './../../components/page/page';

import { loadUser, saveFeedback } from './../../actions/users';

const useStyles = makeStyles((theme) => ({
  field:{
    marginTop:theme.spacing(1),
    marginBottom:theme.spacing(1),
    width:'100%'
  },
  button:{
    marginTop:theme.spacing(1),
    marginBottom:theme.spacing(1),
    width:'100%'
  },
  buttonSuccess:{
    marginTop:theme.spacing(1),
    marginBottom:theme.spacing(1),
    width:'100%',
    backgroundColor: theme.palette.success.main
  },
  buttonWrapper: {
    position: 'relative',
    width:'100%'
  },
  buttonProgress: {
    color: theme.palette.success.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

export default function Index(props) {
  var dispatch = useDispatch();
  const [success, setSuccess] = React.useState(false);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    mixpanel.track('Feedback:Open');
    dispatch(loadUser());
  }, []);

  const classes = useStyles();
  return (
    <Box>
      <Page showMenu>
        <Box display='flex' flexDirection='column' px={2}>
          <Box paddingBottom={1} textAlign='center' width='100%'>
            <Typography variant="h4" component="h4">
              Feedback
            </Typography>
          </Box>
          {success && 
          <>
            <Alert severity="success">Thanks so much for your feedback! We will use it to help improve your recovery journey </Alert>
            <Box p={2} width="100%">
              <Button fullWidth
                color="primary"
                variant="contained"
                component={Link}
                to='home'>Back To Home</Button>
            </Box>
          </>
          }
          {!success &&
          <Formik
            initialValues={{ like: '' , changes: '', nps: 5 }}
            validate={values => {
              const errors = {};
              return errors;
            }}
            onSubmit={(values, { setSubmitting, setErrors }) => {
              dispatch(saveFeedback(values.like, values.changes, values.nps))
                .then(() => {
                  mixpanel.track('Feedback:Created');
                  setSuccess(true);
                  setSubmitting(false);
                });

            }}
          >
            {({
              values,
              isSubmitting
            }) => (
              <Form>
                <Box display='flex' flexDirection='column' alignItems='center'>
                  <Field component={TextField}
                    value={values.like}
                    label="What do you like about Brace?" 
                    className={classes.field}
                    multiline
                    rows={3}
                    type="text"
                    name="like"
                    variant="outlined"/>
                  <Field component={TextField}
                    value={values.changes}
                    label="Any changes or additions you'd like?" 
                    className={classes.field}
                    multiline
                    rows={3}
                    type="text"
                    name="changes"
                    variant="outlined"/>
                  <Box>
                      How likely would you be to recommend to a friend?
                  </Box>
                  <Box px={2}
                    paddingTop={4}
                    paddingBottom={0}
                    width="100%"
                    display='flex'
                    flexDirection='row'>
                    <Grid container spacing={2}>
                      <Grid item>
                        <ThumbDownOutlinedIcon style={{ fontSize: 25, color: red[500] }}/>
                      </Grid>
                      <Grid item xs>
                        <Slider aria-label="Temperature"
                          defaultValue={5}
                          valueLabelDisplay="auto"
                          onChange={(event, value) => values.nps = value}
                          step={1}
                          marks
                          min={1}
                          max={10}
                          valueLabelDisplay="on"
                        />
                      </Grid>
                      <Grid item>
                        <ThumbUpOutlinedIcon style={{ fontSize: 25, color: green[500] }}/>
                      </Grid>
                    </Grid>
                  </Box>
                  <div className={classes.buttonWrapper}>
                    <>
                      <Button type="submit"
                        color="primary"
                        variant='contained'
                        disabled={isSubmitting} 
                        className={success ? classes.buttonSuccess : classes.button}>
                          Submit Feedback
                      </Button>
                      {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </>
                  </div>
                </Box>
              </Form>
            )}
          </Formik>
          }
        </Box>
      </Page>
    </Box>
  );
}