import React from 'react';

import {
  Box,
  Card,
  ButtonBase
} from '@mui/material';

import { Icon } from '@components/atoms';

export default function ButtonCard(props){
  const { onClick, title, text, icon, dataTestId } = props;

  return (
    <ButtonBase sx={{ textAlign: 'left' }} onClick={onClick} data-testid={dataTestId} >
      <Card sx={{ display: 'flex' }} variant='outlined'>
        <Box sx={{ flexGrow: 1, padding: 1, paddingLeft: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Box sx={{ 
            color: 'text.primary', 
            fontSize: 'h5.fontSize', 
            paddingBottom: 0.5,
            fontWeight: 'medium' }}>
            {title}
          </Box>
          <Box sx={{ 
            color: 'text.secondary', 
            fontSize: 'body2.fontSize' }}>
            {text}
          </Box>
        </Box>
        <Box sx={{ padding: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Icon type={icon} size='12vh' delay="1000"/>
        </Box>
      </Card>
    </ButtonBase>
  );
  
}

