export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_PROFILE_IMAGE = 'SET_PROFILE_IMAGE';
export const SET_SESSION_STATS = 'SET_SESSION_STATS';
export const SET_DAILY_STATS = 'SET_DAILY_STATS';
export const SET_CHECK_INS_LOADING = 'SET_CHECK_INS_LOADING';
export const SET_CHECK_INS = 'SET_CHECK_INS';
export const SET_PROFILE_IMAGE_MODIFIED = 'SET_PROFILE_IMAGE_MODIFIED';
export const SET_GOALS = 'SET_GOALS';

import api from '../services/clients/api';

export const getProfile = (id) => {
  return(dispatch, getState, services) => {
    var user = getState().user;

    if(!id){
      id = user.id;
    }

    dispatch(clearProfile());

    return services.apiClient.getProfile(user, id)
      .then(profile => {
        dispatch(setProfile(profile));
      });

  };
};

export const saveProfileImage = (image, cb) => {
  return(dispatch, getState, services) => {
    var user = getState().user;
    return services.apiClient.saveProfileImage(user, image)
      .then(() => {
        dispatch(setProfileImageModified());
        if(cb){
          cb();
        }
      });
  };
};

export const getCheckIns = () => {
  return(dispatch, getState, services) => {
    dispatch(setCheckInsLoading());
    var user = getState().user;
    var profile = getState().profile;
    return services.apiClient.getCheckIns(profile.id, user)
      .then((checkIns) => {
        dispatch(setCheckIns(checkIns.results));
      });
  };
};

export const getStats = (id) => {
  return(dispatch, getState, services) => {
    var user = getState().user;

    if(!id){
      id = user.id;
    }

    services.apiClient.getSessionStats(user, id)
      .then(stats => {
        dispatch(setSessionStats(stats));
      });

    services.apiClient.getDailyStats(user, id)
      .then(stats => {
        dispatch(setDailyStats(stats));
      });

  };
};

export const loadGoals = (userId) => {
  return(dispatch, getState, services) => {
    api.get({ url : 'goals', params: { userId } })
      .then(result => {
        dispatch({ type: SET_GOALS, goals: result.results });
      });
  };
};

export const clearProfile = (profile) => {
  return{
    type: CLEAR_PROFILE
  };
};

export const setProfile = (profile) => {
  return{
    type: SET_PROFILE,
    profile
  };
};

export const setSessionStats = (stats) => {
  return{
    type: SET_SESSION_STATS,
    stats
  };
};

export const setDailyStats = (stats) => {
  return{
    type: SET_DAILY_STATS,
    stats
  };
};

export const setCheckIns = (checkIns) => {
  return{ 
    type:SET_CHECK_INS,
    checkIns
  };
};

export const setCheckInsLoading = () => {
  return{ 
    type:SET_CHECK_INS_LOADING
  };
};

export const setProfileImageModified = () => {
  return{ 
    type:SET_PROFILE_IMAGE_MODIFIED
  };
};