import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { 
  Grid,
  Divider,
  Stack, 
  Typography
} from '@mui/material';

import Page from '../../components/page/page';
import Trophy from './components/trophy';
import { EventsContext } from '@context';

import api from '../../services/clients/api';

import { loadUser } from '../../actions/users';

export default function Index(props){
  const dispatch = useDispatch();
  const [trophies, setTrophies] = useState([]);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { trackEvent } = useContext(EventsContext);
  
  useEffect(() => {
    setIsLoading(true);
    dispatch(loadUser())
      .then((user) => {
        setUser(user);
        trackEvent('Trophies:Open');
        api.get({ url: 'trophies' })
          .then(response => {
            setTrophies(response.sort((a, b) => { return a.priority - b.priority;} ));
            setIsLoading(false);
          });
      });
  }, []);

  const isComplete = (trophy) => {
    return (trophy.level > 0 && trophy.progress >= trophy.levelCompleteAt && !trophy.hasNextLevel);
  };

  return (
    <Page showMenu header='Trophies'>
      <Stack px={2} spacing={1} sx={{ paddingBottom: 1 }}>
        {isLoading && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((index) => 
          <Trophy key={index} isLoading={true} />
        )}
        {!isLoading && 
        <>
          <Typography>
            Completed Trophies
          </Typography>
          <Grid container>
            {
              trophies.filter(t => isComplete(t)).map(t => (
                <Trophy 
                  key = {t.id}
                  type={t.type}
                  title={t.title}
                  description={t.description}
                  level={t.level}
                  progress={t.progress}
                  levelCompleteAt={t.levelCompleteAt}
                  hasNextLevel={t.hasNextLevel}
                  isComplete={isComplete(t)}
                />
              ))
            }
          </Grid>
          <Divider light/>
          <Typography>
            Remaining Trophies
          </Typography>
          <Grid container>
            {
              trophies.filter(t => !isComplete(t)).map(t => (
                <Trophy 
                  key = {t.id}
                  type={t.type}
                  title={t.title}
                  description={t.description}
                  level={t.level}
                  progress={t.progress}
                  levelCompleteAt={t.levelCompleteAt}
                  hasNextLevel={t.hasNextLevel}
                  isComplete={isComplete(t)}
                />
              ))
            }
          </Grid>
        </>
        }
      </Stack>
    </Page>
  );
}

