import React from 'react';

import {
  Box,
  Typography,
  Card as MuiCard
} from '@mui/material';

export default function Card(props) {
  return (
    <Box sx={{
      ...props.sx,
    }}>
      <MuiCard sx={{width: '100%'}}>
        {props.title &&
          <Box sx={{ 
                padding:1, 
                paddingBottom: 0
              }}>
              <Typography sx={{ fontSize: 'h5.fontSize', fontWeight: 'medium', lineHeight: 1, textAlign: 'center' }} color="text.primary">
                {props.title}
              </Typography>
              <Typography sx={{ fontSize: 'body2.fontSize', lineHeight: 1.25, textAlign: 'center'}} color="text.secondary">
                {props.subTitle}
              </Typography>
          </Box>
        }
        <Box sx={{ 
          padding:1 
        }}>
          {props.children}
        </Box>
      </MuiCard>
    </Box>
  );
}