import {
  OPEN_MODAL,
  CLEAR_MODAL,
  OPEN_COUNTDOWN_TIMER_MODAL,
  CLEAR_COUNTDOWN_TIMER_MODAL,
  OPEN_NEW_POST_MODAL,
  CLOSE_NEW_POST_MODAL,
  OPEN_NEW_GOAL_MODAL,
  CLOSE_NEW_GOAL_MODAL } from './../actions/modal';

const initialState = {
  isOpen: false,
  isCountdownModalOpen: false,
  newPostModal:{
    isOpen: false
  },
  newGoalModal:{
    isOpen: false
  }
};

export default function modalReducer(state = initialState, action) {
  switch (action.type) {
  case OPEN_MODAL:
    state = {
      ...state, 
      isOpen:true,
      title: action.title,
      message: action.message,
      type: action.type,
      icon: action.icon,
      action: action.action
    };
    return state;
  case CLEAR_MODAL:
    return initialState;
  case OPEN_COUNTDOWN_TIMER_MODAL:
    state = {
      ...state, 
      isCountdownModalOpen:true,
      initialTime: action.initialTime
    };
    return state;
  case CLEAR_COUNTDOWN_TIMER_MODAL:
    state = {
      ...state, 
      isCountdownModalOpen:false
    };
    return state;
  case OPEN_NEW_POST_MODAL:
    state = {
      ...state, 
      newPostModal:{
        isOpen: true,
        action: action.action,
        metadata: action.metadata
      }
    };
    return state;
  case CLOSE_NEW_POST_MODAL:
    state = {
      ...state, 
      newPostModal:{
        isOpen: false
      }
    };
    return state;
  case OPEN_NEW_GOAL_MODAL:
    state = {
      ...state, 
      newGoalModal:{
        isOpen: true,
        userId: action.userId
      }
    };
    return state;
  case CLOSE_NEW_GOAL_MODAL:
    state = {
      ...state, 
      newGoalModal:{
        isOpen: false

      }
    };
    return state;
  default:
    return state;
  }
}