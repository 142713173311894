import React from 'react';
import { useLocation, useHistory } from "react-router";
import { useDispatch } from 'react-redux'

import { 
  Box,
  Stack,
  Button,
  Divider,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Snackbar,
  Alert,
  TextField
} from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Page from '../../components/page/page'

import {report} from './../../actions/users'

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Index(props){
  var dispatch = useDispatch();
  let query = useQuery();
  let history  = useHistory();
  const [showSnackBar, setShowSnackBar] = React.useState(false);
  const [value, setValue] = React.useState('');
  const [reason, setReason] = React.useState('');
  const [goBackTimeout, setGoBackTimeout] = React.useState(null);

  const submit = () => {
    setShowSnackBar(true)
    dispatch(report(query.get("type"), query.get("id"), reason === '' ? value : reason))
    setGoBackTimeout(setTimeout(()=>{
      goBack()
    }, 2000))
  }

  const handleChange = (event) => {
    setValue(event.target.value);
    if(event.target.value !== 'other'){
      setReason('')
    }
  }

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  }

  const goBack = () => {
    if(goBackTimeout != null){
      clearTimeout(goBackTimeout);
    }
    setGoBackTimeout(null)
    history.goBack()
  }

  return (
    <Page>
      <Box>
        <Stack direction="row" p={1}>
          <Box display='flex' alignItems='center' flexGrow={1}>
            <ArrowBackIcon onClick={goBack} style={{fontSize: 28}} color="primary" />
          </Box>
          <Box>
            <Button disabled={value === ''} onClick={submit} size='small' variant='contained' color='primary'>Report</Button>
          </Box>
        </Stack>
        <Divider />
        <Box p={1}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Why are you reporting this?</FormLabel>
            <RadioGroup
              aria-label="gender"
              name="controlled-radio-buttons-group"
              value={value}
              onChange={handleChange}
            >
              <FormControlLabel value="harassment" control={<Radio />} label="Harassment or hateful speech" />
              <FormControlLabel value="adult" control={<Radio />} label="Adult Content" />
              <FormControlLabel value="other" control={<Radio />} label="Other" />
            </RadioGroup>
          </FormControl>
        </Box>
        {value === 'other' &&
          <Box p={1} > 
            <TextField
              id="outlined-multiline-flexible"
              label="Reason"
              multiline
              rows={4}
              fullWidth
              value={reason}
              onChange={handleReasonChange}
            />
          </Box>
        }
        <Snackbar
          open={showSnackBar}
          autoHideDuration={2000}
          message="Reported"
          onClose={() => {setShowSnackBar(false)}}>
          <Alert onClose={() => {setShowSnackBar(false)}} severity="info" sx={{ width: '100%' }}>
            Successfully Reported. We will review within 24 hours.
          </Alert>
        </Snackbar>
      </Box>
    </Page>
  )
}

