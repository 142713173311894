import config from "../../config";

const doRequest = (httpMethod, entity, url, resolve, reject, token) => {
  let xhr = new XMLHttpRequest();
  xhr.open(httpMethod, url);

  xhr.onload = () => {
    if (xhr.status >= 200 && xhr.status < 300) {
      if(xhr.response)
        resolve(xhr.response);
      else
        resolve()
    } else if(xhr.status === 404){
      resolve()
    } else {
      var response = JSON.parse(xhr.response);
      reject({
        errors:response.errors,
        message:response.message,
        statusCode:xhr.status
      });
    }
  };
  xhr.onerror = () => {
    reject(xhr.statusText);
  }

  xhr.ontimeout = function (e) {
    reject('timeout');
  };

  xhr.send();
}

export default class BlobClient {
  constructor() {
    this.baseUrl = config.profileImagesBaseUrl
  }
  getUserImage(userId){
    return new Promise((resolve, reject) => {
      doRequest("GET", null, `${this.baseUrl}images/user-profile-image-${userId}`, resolve, reject, null);
    });
  }
}