export const HIDE_APP_BAR = 'HIDE_APP_BAR';
export const SHOW_APP_BAR = 'SHOW_APP_BAR';

export const hideAppBar = () => {
  return{
    type:HIDE_APP_BAR
  };
};

export const showAppBar = () => {
  return{
    type:SHOW_APP_BAR
  };
};
    