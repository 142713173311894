import React, { useState, useCallback, useRef } from 'react';
import {
  Dialog, 
  Box,
  Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import getCroppedImg from './crop-image';

const useStyles = makeStyles((theme) => ({
  dialog: {
    height: '90%'
  },
  cropper:{
    maxHeight:'80vh'
  },
  controls:{
    width:'100%',
    height:'20%'
  },
  control:{
    width:'50%',
    padding:theme.spacing(1)
  }
}));

export default function ImageCropper(props) {
  const classes = useStyles();
  const imgRef = useRef(null);
  const [crop, setCrop] = useState({
    unit: '%',
    height: 100,
    aspect: 1 / 1
  });

  const saveImage = () => {
    getCroppedImg(
      imgRef.current,
      crop
    ).then(image => {
      props.saveImage(image);
      setCrop({
        unit: '%',
        height: 100,
        aspect: 1 / 1
      });
    });
  };

  const onImageLoaded = useCallback((img) => {
    imgRef.current = img;
  }, []);

  return (
    <div>
      {props.inputImage &&
        <Dialog open={props.open}>
          <Box className={classes.dialog}>
            <Box className={classes.cropper}>
              <ReactCrop src={props.inputImage}
                onImageLoaded={onImageLoaded}
                crop={crop}
                onChange={newCrop => setCrop(newCrop)} />
            </Box>
            <Box display='flex' flex-direction='column' className={classes.controls}>
              <Box className={classes.control} >
                <Button fullWidth
                  variant='contained'
                  color='primary'
                  onClick={saveImage}>
                    Save
                </Button>
              </Box>
              <Box className={classes.control} >
                <Button fullWidth
                  variant='outlined'
                  color='primary'
                  onClick={props.close}>
                    Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Dialog>
      }
    </div>
  );
}