import config from './../../config';

const generateUrl = (baseUrl, resourceId) => {
  return baseUrl + resourceId;
};

const doRequest = (httpMethod, entity, url, resolve, reject, token, isJson = true) => {
  let xhr = new XMLHttpRequest();
  xhr.open(httpMethod, url);

  if(isJson)
    xhr.setRequestHeader('Content-type', 'application/json');

  if(token)
    xhr.setRequestHeader('Authorization', token);

  xhr.onload = () => {
    if (xhr.status >= 200 && xhr.status < 300) {
      if(xhr.response)
        resolve(JSON.parse(xhr.response));
      else
        resolve();
    } else if(xhr.status === 401){
      reject({
        errors:'Un-Authorized',
        statusCode:xhr.status
      });
    } else {
      var response = JSON.parse(xhr.response);
      reject({
        errors:response.errors,
        message:response.message,
        statusCode:xhr.status
      });
    }
  };
  xhr.onerror = () => {
    reject(xhr.statusText);
  };

  xhr.ontimeout = function (e) {
    reject('timeout');
  };

  if(entity)
    xhr.send(isJson ? JSON.stringify(entity) : entity);
  else
    xhr.send();
};

export default class ApiClient {
  static constitute () { }
  constructor() {
    this.baseUrl = config.apiBaseUrl;
  }
  signUp(name, email, password, country, timeZone){
    return new Promise((resolve, reject) => {
      doRequest('POST', {
        email,
        name,
        password,
        country,
        timeZone,
        type: 'CLIENT'
      }, generateUrl(this.baseUrl, 'users'), resolve, reject, null);
    });
  }
  signIn(email, password){
    return new Promise((resolve, reject) => {
      doRequest('POST', {
        email,
        password
      }, generateUrl(this.baseUrl, 'users/signin'), resolve, reject, null);
    });
  }
  deleteAccount(user, feedback){
    return new Promise((resolve, reject) => {
      doRequest('DELETE', { feedback }, generateUrl(this.baseUrl, `accounts/${user.id}`), resolve, reject, user.token);
    });
  }
  blockUser(user, userId){
    return new Promise((resolve, reject) => {
      doRequest('PUT', { userId }, generateUrl(this.baseUrl, 'users/block'), resolve, reject, user.token);
    });
  }
  saveInjury(user, type, activityType,date){
    return new Promise((resolve, reject) => {
      doRequest('POST', {
        type,
        date,
        activityType
      }, `${this.baseUrl}users/injury`, resolve, reject, user.token);
    });
  }
  saveCheckIn(user, message, sentiment){
    return new Promise((resolve, reject) => {
      doRequest('POST', {
        message,
        sentiment
      }, `${this.baseUrl}check-ins`, resolve, reject, user.token);
    });
  }
  getCheckIns(userId, user){
    return new Promise((resolve, reject) => {
      doRequest('GET', null, `${this.baseUrl}check-ins?userId=${userId}`, resolve, reject, user.token);
    });
  }
  getCheckIn(checkInId, user){
    return new Promise((resolve, reject) => {
      doRequest('GET', null, `${this.baseUrl}check-ins/${checkInId}`, resolve, reject, user.token);
    });
  }
  getComment(commentId, user){
    return new Promise((resolve, reject) => {
      doRequest('GET', null, `${this.baseUrl}comments/${commentId}`, resolve, reject, user.token);
    });
  }
  getCheckInComments(checkInId, user){
    return new Promise((resolve, reject) => {
      doRequest('GET', null, `${this.baseUrl}comments?checkInId=${checkInId}`, resolve, reject, user.token);
    });
  }
  searchCheckInsLikes({
    user,
    checkInId,
    createdAtLimit,
    page = 0
  }){
    return new Promise((resolve, reject) => {
      doRequest('GET', null, `${this.baseUrl}check-ins/${checkInId}/likes?page=${page}&createdAtLimit=${createdAtLimit}`, resolve, reject, user.token);
    });
  }
  saveComment(checkInId, message, parentCommentId, user){
    return new Promise((resolve, reject) => {
      doRequest('POST', { checkInId, message, parentCommentId }, `${this.baseUrl}comments`, resolve, reject, user.token);
    });
  }
  searchCheckIns({
    user,
    searchTerm,
    createdAtLimit,
    page = 0
  }){
    return new Promise((resolve, reject) => {
      doRequest('GET', null, `${this.baseUrl}check-ins?page=${page}&createdAtLimit=${createdAtLimit}&searchTerm=${searchTerm}`, resolve, reject, user.token);
    });
  }
  likeCheckIn(user, checkIn){
    return new Promise((resolve, reject) => {
      doRequest('POST', null, `${this.baseUrl}check-ins/${checkIn}/like`, resolve, reject, user.token);
    });
  }
  likeComment(user, commentId){
    return new Promise((resolve, reject) => {
      doRequest('POST', null, `${this.baseUrl}comments/${commentId}/like`, resolve, reject, user.token);
    });
  }
  
  savePlan(user, plan){
    return new Promise((resolve, reject) => {
      doRequest('POST', plan, `${this.baseUrl}recovery/plans`, resolve, reject, user.token);
    });
  }
  listPlans(user){
    return new Promise((resolve, reject) => {
      doRequest('GET', null, `${this.baseUrl}recovery/plans`, resolve, reject, user.token);
    });
  }
  createSessions(user, planId, plannedStartDate){
    return new Promise((resolve, reject) => {
      doRequest('POST', { planId, plannedStartDate }, `${this.baseUrl}recovery/sessions`, resolve, reject, user.token);
    });
  }
  searchSessions(user, date){
    return new Promise((resolve, reject) => {
      doRequest('GET', null, `${this.baseUrl}recovery/sessions/?plannedStartDate=${date}`, resolve, reject, user.token);
    });
  }
  deleteSession(user, sessionId){
    return new Promise((resolve, reject) => {
      doRequest('DELETE', null, `${this.baseUrl}recovery/sessions/${sessionId}`, resolve, reject, user.token);
    });
  }
  createProgram(user, name, planId, startDate, endDate, daysOfWeek, numberOfTimesPerDay){
    return new Promise((resolve, reject) => {
      doRequest('POST', { name, planId, startDate, endDate, daysOfWeek, numberOfTimesPerDay }, `${this.baseUrl}recovery/programs`, resolve, reject, user.token);
    });
  }
  updateProgram(user, programId, name, startDate, endDate, daysOfWeek, numberOfTimesPerDay){
    return new Promise((resolve, reject) => {
      doRequest('PUT', { name, startDate, endDate, daysOfWeek, numberOfTimesPerDay }, `${this.baseUrl}recovery/programs/${programId}`, resolve, reject, user.token);
    });
  }
  deleteProgram(user, programId){
    return new Promise((resolve, reject) => {
      doRequest('DELETE', null, `${this.baseUrl}recovery/programs/${programId}`, resolve, reject, user.token);
    });
  }
  getPrograms(user, id){
    return new Promise((resolve, reject) => {
      doRequest('GET', null, `${this.baseUrl}recovery/programs?userId=${id}`, resolve, reject, user.token);
    });
  }
  toggleCompleteSet(user, sessionId, exerciseId, setNumber){
    return new Promise((resolve, reject) => {
      doRequest('PUT', null, `${this.baseUrl}recovery/sessions/${sessionId}/exercises/${exerciseId}/sets/${setNumber}/complete`, resolve, reject, user.token);
    });
  }
  saveExerciseSentiment(user, sessionId, exerciseId, sentiment){
    return new Promise((resolve, reject) => {
      doRequest('PUT', { sentiment }, `${this.baseUrl}recovery/sessions/${sessionId}/exercises/${exerciseId}`, resolve, reject, user.token);
    });
  }
  getExerciseLibrary(user){
    return new Promise((resolve, reject) => {
      doRequest('GET', null, `${this.baseUrl}recovery/exercises/library`, resolve, reject, user.token);
    });
  }
  createExercise(user, name, tags){
    return new Promise((resolve, reject) => {
      doRequest('POST', { name, tags }, `${this.baseUrl}recovery/exercises`, resolve, reject, user.token);
    });
  }
  getProfile(user, userId){
    return new Promise((resolve, reject) => {
      doRequest('GET', null, `${this.baseUrl}profiles/${userId}`, resolve, reject, user.token);
    });
  }
  saveProfileImage(user, profileImage){
    return new Promise((resolve, reject) => {

      const formData = new FormData();
      formData.append('file', profileImage);

      doRequest('PUT', formData, `${this.baseUrl}users/image`, resolve, reject, user.token, false);
    });
  }
  saveMessageToken(user, messageToken){
    return new Promise((resolve, reject) => {
      doRequest('PUT', {
        messageToken
      }, `${this.baseUrl}users/messageToken`, resolve, reject, user.token);
    });
  }
  searchProfiles(user, searchTerm, createdAtLimit, page){
    var url = `${this.baseUrl}profiles/search?page=${page}&createdAtLimit=${createdAtLimit}&size=50`;

    if(searchTerm){
      url += `&searchTerm=${searchTerm}`;
    }

    return new Promise((resolve, reject) => {
      doRequest('GET', null, url, resolve, reject, user.token);
    });
  }
  getSessionStats(user, userId){
    return new Promise((resolve, reject) => {
      doRequest('GET', null, `${this.baseUrl}recovery/stats/sessions?userId=${userId}`, resolve, reject, user.token);
    });
  }
  getDailyStats(user, userId){
    return new Promise((resolve, reject) => {

      doRequest('GET', null, `${this.baseUrl}v1.1/recovery/stats/daily?userId=${userId}`, resolve, reject, user.token);
    });
  }
  searchNotifications({
    user,
    createdAtLimit,
    page = 0
  }){
    return new Promise((resolve, reject) => {
      doRequest('GET', null, `${this.baseUrl}notifications?page=${page}&createdAtLimit=${createdAtLimit}`, resolve, reject, user.token);
    });
  }
  markNotificationAsRead({
    user,
    notificationId
  }){
    return new Promise((resolve, reject) => {
      doRequest('PUT', null, `${this.baseUrl}notifications/${notificationId}/read`, resolve, reject, user.token);
    });
  }
}