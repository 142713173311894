import React, { useContext, useState } from 'react';

import { 
  Card,
  CardContent,
  Typography,
  Box,
  Button
} from '@mui/material';
import { UserContext } from '@context';
import { DeleteAccountDialog } from '@components/v2/organisms';
import { useDispatch } from 'react-redux';
import { deleteAccount } from './../../../actions/users';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { EventsContext } from '@context/events';

export default function Account(){
  const { handleLogout } = useContext(UserContext);
  var { trackEvent } = useContext(EventsContext);
  const [ isDeleteAccountOpen, setIsDeleteAccountOpen ] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleDelete = (feedback) => {
    trackEvent('Account:Deleted');
    return dispatch(deleteAccount(feedback))
      .then(() => {
        handleLogout(() => history.push('/'));
      });
  };

  return (
    <Card>
      <CardContent>
        <Box>
          <Typography color="text.secondary"
            variant='h5'
            gutterBottom>
              Account
          </Typography>
        </Box>
        <Box>
          <Button variant='outlined'
            color='error'
            size='small'
            onClick={() => setIsDeleteAccountOpen(true)}>
            Delete Account
          </Button>
        </Box>
      </CardContent>
      <DeleteAccountDialog
        deleteAccount={(feedback) => handleDelete(feedback)}
        isOpen={isDeleteAccountOpen} 
        close={() => setIsDeleteAccountOpen(false)}
      />
    </Card>
  );
}
