import React, { useCallback, useContext, useEffect, useState } from 'react';
import moment from 'moment';

import { 
  Typography,
  Box
} from '@mui/material';

import Page from './../../../components/page/page';
import { UserContext, BillingContext } from '@context';
import Goals from './goals';
import ToDo from './to-do/to-do';
import Community from './community';
import { useHistory } from 'react-router-dom';
import Invites from './invites';

function generateGreetings() {
  var currentHour = moment().format('HH');

  if (currentHour > 0 && currentHour <= 12) {
    return 'Good Morning';
  } else if (currentHour > 12 && currentHour <= 17) {
    return 'Good Afternoon';
  } else {
    return 'Good Evening';
  }
}

export default function Home(){
  const { user } = useContext(UserContext);
  const history = useHistory();
  const { isLoading: isLoadingSubscription, checkBillingStatus } = useContext(BillingContext);
  const [communityHeight, setCommunityHeight] = useState(null);

  useEffect(() => {
    if(!isLoadingSubscription){
      checkBillingStatus(history);
    }
  }, [isLoadingSubscription]);

  const communityRefContainer = useCallback((n) => {
    if (!n) return;
    const resizeObserver = new ResizeObserver((entries) => { 
      var height = entries[0].contentRect.height;
      if(height){
        setCommunityHeight( height);
      }
    });
    resizeObserver.observe(n);
  }, []);

  return (
    <Page showMenu
      shouldShowFeedbackRequest={true}
      header='Home'
      showOnboardingBanner>
      <Box sx={{ height: 'calc(100vh - 56px - 56px - env(safe-area-inset-top) - env(safe-area-inset-bottom))', display: 'flex', flexDirection: 'column' }}>
        <Box p={1}>
          <Typography component="div">
            <Box sx={{ fontSize: 'h5.fontSize', fontWeight:'medium', color: 'text.primary' }}>
              {generateGreetings()} {user?.name != null ? user?.name.split(' ')[0] : 'Guest'}
            </Box>
          </Typography>
        </Box>
        <Goals />
        <ToDo />
        <Box sx={{ flexGrow: 1, overflow: 'hidden' }} ref={communityRefContainer}>
          <Community height={communityHeight}/>
        </Box>
      </Box>
      <Invites />
    </Page>
  );
}

