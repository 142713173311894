import React from 'react';

import {
  Box,
  Grid,
  Typography,
  Card as MuiCard,
  Checkbox
} from '@mui/material';

import Card from './card';

export default function RadioCards(props) {
  return (
    <Card sx={{
      ...props.sx
    }}
    title={props.title}
    subTitle={props.subTitle}>
      <Grid container spacing={1}>
        {props.options.map((option) =>
          <Grid item
            key={option.title}
            xs={6}
            onClick={() => props.handleClick(option.value)} >
            <MuiCard variant='outlined'
              sx={{
                height: '100%',
                padding: 1,
                backgroundColor: option.isSelected ? theme => theme.palette.primary[50] : theme => theme.palette.background.paper,
                borderColor: option.isSelected ? 'primary.light' : theme => theme.palette.divider
              }}>
              <Box display='flex' alignItems="center">
                <Box flexGrow={1}>
                  <Typography sx={{ fontSize: 'body1.fontSize', fontWeight: 'medium', lineHeight: 1 }} color="text.primary">
                    {option.title}
                  </Typography>
                </Box>
                <Box>
                  <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }} checked={option.isSelected} target="div.checkBox" />
                </Box>
              </Box>
              <Box flexGrow={1}>
                <Typography sx={{ fontSize: 'caption.fontSize', lineHeight: 1.25, textAlign: 'center' }} color="text.secondary">
                  {option.subTitle}
                </Typography>
              </Box>
            </MuiCard>
          </Grid>
        )}
      </Grid>
      <Box paddingTop={1}>
        {props.children}
      </Box>
    </Card>
  );
}