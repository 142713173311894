import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import { Auth0Provider } from '@auth0/auth0-react';
import { Provider } from 'react-redux';
import store from './store';

import { 
  IconButton 
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { ServicesProvider } from '@context/services';
import { UserProvider } from '@context/user';
import { EventsProvider } from '@context/events';
import { FeaturesProvider } from '@context/features';
import { RecoveryProvider } from '@context/recovery';
import { BillingProvider } from '@context/billing';
import { NewPostProvider } from '@components/organisms';
import { DialogProvider } from '@context/dialogs';
import { AccountOnboardingProvider } from '@context/account-onboarding';

import CssBaseline from '@material-ui/core/CssBaseline';

import { SnackbarProvider } from 'notistack';

import ToggleColorMode from '@context/theme';

import config from '@config';

import { loadUser } from './actions/users';
import { openSuccessModal } from './actions/modal';

const notistackRef = React.createRef();
const onClickDismiss = key => () => { 
  notistackRef.current.closeSnackbar(key);
};

ReactDOM.render(
  <Provider store={store}>
    <ToggleColorMode shouldForceLight={true}>
      <ServicesProvider config={config}>
        <Auth0Provider
          domain={config.auth0Domain}
          clientId={config.auth0ClientId}
          cacheLocation='localstorage'
          useRefreshTokens= {true}
          authorizationParams={{
            audience: config.auth0Audience,
            redirect_uri: config.isMobileApp ?
              'com.bracesocial.app://auth.bracesocial.com/cordova/com.bracesocial.app/callback' :// window.location.origin
              window.location.origin
          }}>
          <UserProvider isMobileApp={true} loadUser={loadUser} openSuccessModal={openSuccessModal}>
          
            <FeaturesProvider>
              <BillingProvider>
                <EventsProvider config={config}>
                  <AccountOnboardingProvider openSuccessModal={openSuccessModal}>
                    <DialogProvider>
                      <SnackbarProvider 
                        autoHideDuration={3000}
                        ref={notistackRef}
                        action={(key) => (
                          <IconButton onClick={onClickDismiss(key)}>
                            <CloseIcon sx={{ color: '#fff' }}/>
                          </IconButton>
                        )}>
                        <NewPostProvider>
                          <RecoveryProvider>
                            <CssBaseline />
                            <App />
                          </RecoveryProvider>
                        </NewPostProvider>
                      </SnackbarProvider>
                    </DialogProvider>
                  </AccountOnboardingProvider>
                </EventsProvider>
              </BillingProvider>
            </FeaturesProvider>
            
          </UserProvider>
        </Auth0Provider>
      </ServicesProvider>
    </ToggleColorMode>
  </Provider>,
  document.getElementById('app')
);