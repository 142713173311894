import React from 'react';

import { 
  Box,
  IconButton,
  Typography
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import LoadableText from '../atoms/loadable-text';

export default function SubPage(props){
  const { children, title, isBackButtonEnabled, endButton, isLoading } = props;
  const history = useHistory();
  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {isBackButtonEnabled && 
          <IconButton data-testid='sub-page-back-button' onClick={() => history.goBack()}><ChevronLeftIcon /></IconButton>
        }
        <Box sx={{ flexGrow: 1, p: 1 }}>
          <Typography variant="h5" component="div"> 
            <LoadableText dataTestId='sub-page-title' text={title} isLoading={isLoading} />
          </Typography>
        </Box>
        {endButton}
      </Box>
      {children}
    </Box>
  );
}

