import React from 'react';

import { 
  Box,
  Card,
  Typography
} from '@mui/material';

import { getProgramCompletionPercentage, getProgramAdherencePercentage, getDaysLeft } from '@helpers/programs';
import { toShortDateString, getShortDateOfWeek, sortDaysOfWeek } from '@helpers/dates';
import { LoadableText } from '@components/atoms';
import { useHistory } from 'react-router-dom';

export default function Program(props) {
  const { program, isLoadingPrograms, type } = props;
  const history = useHistory();

  return (
    <Box sx={{ width: '100%', p: 1, cursor: 'pointer' }} onClick={() => history.push(`/recovery/programs/${program.id}`)} data-testid={`recovery-manage-programs-program-${program.id}`}>
      <Card variant='outlined' sx={{ p: 1 }}>
        <Box sx={{ display: 'flex', paddingBottom: 0.5, alignItems: 'end' }}>
          <Typography 
            sx={{ flexGrow: 1 }}
            variant='body1'
            fontWeight='medium'
            component='div'>
            <LoadableText dataTestId={`recovery-manage-programs-program-${program.id}-name`} isLoading={isLoadingPrograms} text={program.name}/>
          </Typography>
          {(type == 'ACTIVE') &&
              <Typography component='div' variant='body1'>
                <LoadableText isLoading={isLoadingPrograms} text={`${getProgramCompletionPercentage(program)}% Completed`}/>
              </Typography>
          }
          {(type == 'UPCOMING') &&
              <Typography component='div' variant='body1'>
                <LoadableText isLoading={isLoadingPrograms} text={`Starting: ${toShortDateString(program.startDate)}`}/>
              </Typography>
          }
        </Box>
        {(type == 'ACTIVE') &&
            <Box sx={{ display: 'flex' }}>
              <Typography component='div' variant='body2' sx={{ flexGrow: 1 }}>
                <LoadableText isLoading={isLoadingPrograms} text={`Adherence: ${program?.adherence}%`}/>
              </Typography>
              <Typography component='div' variant='body2'>
                <LoadableText isLoading={isLoadingPrograms} text={`Days Remaining: ${getDaysLeft(program)}`}/>
              </Typography>
            </Box>
        }
        {(type == 'UPCOMING') &&
            <Box>
              <Typography component='div' variant='body2' sx={{ flexGrow: 1 }}>
                <LoadableText isLoading={isLoadingPrograms} text={`Exercise Plan: ${program.plan?.name}`}/>
              </Typography>
            </Box>
        }
        {(type == 'COMPLETE') &&
            <Box sx={{ display: 'flex' }}>
              <Typography component='div' variant='body2' sx={{ flexGrow: 1 }}>
                <LoadableText isLoading={isLoadingPrograms} text={`Adherence: ${program?.adherence}%`}/>
              </Typography>
              <Typography component='div' variant='body2'>
                <LoadableText isLoading={isLoadingPrograms} text={`Session Completed: ${program.sessions.completeCount}`}/>
              </Typography>
            </Box>
        }
      </Card>
    </Box>
  );
}