import {
  SET_DIARY_ENTRIES } from './../actions/diary';

export default function diaryReducer(state = { diaryEntries: [] }, action) {
  switch (action.type) {
  case SET_DIARY_ENTRIES:
    state = { 
      ...state,
      diaryEntries: action.results
    };
    return state;
  default:
    return state;
  }
}