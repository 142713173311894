import React, { useEffect, useState } from 'react';
import { createClient } from 'contentful';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { 
  Dialog,
  Box,
  Button,
  Divider
} from '@mui/material';

import { DialogHeader, DialogTransition } from '@components/atoms';
import { isFinalStep, isStepComplete, getRemainingSteps } from '../helpers';
import { EmptyState } from '@components/molecules';
import { useTheme } from '@emotion/react';

var client = createClient({
  space: 'c6h6zfaud8lz',
  accessToken: 'RJUjCpg8pbrPfz-vxHdNYRUoSNuB4xm5R6XxPp3n64Y',
  host: 'cdn.contentful.com'
});

export default function CourseContentStep(props) {
  const { course, openStep, close, completeStep } = props;
  const [step, setStep] = useState(null);
  const [content, setContent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  var theme = useTheme();

  const loadContent = () => {
    setIsLoading(true);
    client.getEntry(step.id)
      .then(entry => {
        const rawRichTextField = entry.fields.body;
        let options = {
          renderNode: {
            'embedded-asset-block': (node) =>
              `<div class='embedded-image-container'><img class='mui-image-img embedded-image' src="https:${node.data.target.fields.file.url}"/></div>`
          }
        };

        setContent(documentToHtmlString(rawRichTextField, options));
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if(step){
      loadContent();
    }
  }, [step]);

  useEffect(() => {
    if(openStep != null){
      setStep(course.content.steps.filter(s => s.number == openStep)[0]);
    }else{
      setStep(step);
    }
  }, [openStep]);

  var buttonText = 'NEXT STEP';

  if(!isStepComplete(course, openStep)){
    if(getRemainingSteps(course) == 1){
      buttonText = 'COMPLETE COURSE';
    }else if(isFinalStep(course, openStep)){
      buttonText = 'COMPLETE STEP';
    }
  }else{
    if(isFinalStep(course, openStep)){
      buttonText = 'CLOSE';
    }
  }

  return (
    <Dialog
      maxWidth='xl'
      fullWidth
      open={openStep != null}
      onClose={close}
      TransitionComponent={DialogTransition}
      sx={{ width : '100%', ...props.sx }} >
      <DialogHeader close={close} />
      <Divider />
      {isLoading &&
        <EmptyState title='Loading Content' iconType='bicycle'/>
      }
      {!isLoading &&
        <>
          <Box sx={{ p: 1,  
            '.embedded-image-container' : {
              width: '100%',
              display: 'flex',
              justifyContent: 'center'
            },
            '.embedded-image' : {
              [theme.breakpoints.down('md')]: {
                maxWidth: '90%',
                maxHeight: '75vh',
                height: 'auto',
                width: 'auto'
                
              },
              [theme.breakpoints.up('md')]: {
                maxWidth: '50%',
                maxHeight: '50vh',
                height: 'auto',
                width: 'auto'
              }
            }
          }}>
            { <div dangerouslySetInnerHTML={{ __html: content }} /> }
          </Box>
          <Box p={1} px={4}>
            <Button onClick={completeStep}
              disabled={isLoading}
              variant='contained'
              
              fullWidth
              size='small'>
              {buttonText}
            </Button>
          </Box>
        </>
      }
    </Dialog>
  );
}