export function calculateSessionCompletionPercentage(session) {
  var totalSetCount = 0;
  var totalCompleteSetCount = 0;

  session.plan.exercises.forEach(e => {
    e.sets.forEach(s => {
      totalSetCount++;
      if(s.isComplete === true){
        totalCompleteSetCount++;
      }
    });
  });
  
  return Math.round((totalCompleteSetCount/totalSetCount) * 100, 0);
}