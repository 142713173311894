
export const getTotalSteps = (course) => {
  return course?.content?.steps.length ?? 0;
};

export const getCompletedSteps = (course) => {
  return course?.account?.stepsCompleted?.length ?? 0;
};

export const isStepComplete = (course, number) => {
  return course?.account?.stepsCompleted.filter(sc => sc.number == number).length == 1;
};

export const isFinalStep = (course, number) => {
  return number >= course?.content?.steps.length - 1;
};

export const getRemainingSteps = (course) => {
  return getTotalSteps(course) - getCompletedSteps(course);
};

export const getProgress = (course) => {
  var completeCount = getTotalSteps(course);
  if(completeCount > 0){
    return Math.round(getCompletedSteps(course) * 100 / getTotalSteps(course));
  }
  return 0;
};

