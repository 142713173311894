import {
  CLEAR_PROFILE,
  SET_PROFILE, 
  SET_SESSION_STATS,
  SET_DAILY_STATS,
  SET_CHECK_INS_LOADING,
  SET_CHECK_INS,
  SET_GOALS } from './../actions/profiles';

function calculateAdherence(stats){

  var averages = [];

  if(stats.sessions.completeCount > 0 || stats.sessions.incompleteCount > 0){
    averages.push(stats.sessions.completeCount / (stats.sessions.completeCount + stats.sessions.incompleteCount));
  }

  if(stats.exercises.completeCount > 0 || stats.exercises.incompleteCount > 0){
    averages.push(stats.exercises.completeCount / (stats.exercises.completeCount + stats.exercises.incompleteCount));
  }

  if(stats.sets.completeCount > 0 || stats.sets.incompleteCount > 0){
    averages.push(stats.sets.completeCount / (stats.sets.completeCount + stats.sets.incompleteCount));
  }

  if(averages.length === 0){
    return 0;
  }

  const total = averages.reduce((acc, c) => acc + c, 0);
  return total / averages.length * 100;
}

var initState = {
  isCheckInFeedLoading:false,
  goals: [],
  checkIns: []
};

export default function profilesReducer(state = initState, action) {
  switch (action.type) {
  case CLEAR_PROFILE:
    return initState;
  case SET_PROFILE:
    state = {
      ...state, 
      ...action.profile,
      isProfileImageLoading: true
    };
    return state;
  case SET_SESSION_STATS:
    state = {
      ...state, 
      sessionStats: action.stats,
      adherence: calculateAdherence(action.stats)
    };
    return state;
  case SET_GOALS:
    state = {
      ...state, 
      goals: action.goals
    };
    return state;
  case SET_DAILY_STATS:
    state = {
      ...state, 
      daily: action.stats
    };
    return state;
  case SET_CHECK_INS_LOADING:
    state = {
      ...state, 
      checkIns: [],
      isCheckInFeedLoading: true
    };
    return state;
  case SET_CHECK_INS:
    state = {
      ...state, 
      checkIns: action.checkIns,
      isCheckInFeedLoading: false
    };
    return state;
  default:
    return state;
  }
}