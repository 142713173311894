import React from 'react';

import {
  Box,
  Typography,
  Button
} from '@mui/material';
import { useTheme } from '@emotion/react';

export default function Step1(props){
  const { handleNext } = props;
  const theme = useTheme();

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box sx={{ flexGrow: 1, px: 2, paddingBottom: 2,  fontWeight: 'medium', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', textAlign: 'center', color: theme.palette.braceGreenDark.main }}>
        <Typography variant='h4' color='braceGreenLight.main' fontWeight='bold'>
        Recovery is a Tough Journey
        </Typography>
        <Typography variant='h4' color='braceGreenDark.main'>
        It can be lonely, uncertain and hard to stay on track
        </Typography>
        <Typography variant='h4' color='braceGreenLight.main' fontWeight='bold'>
          {'Don\'t  worry, you\'re not alone.'}
        </Typography>
        <Typography variant='h4' color='braceGreenDark.main'>
        Brace is empowering our community with the physical and mental health supports to come back stronger, together.
        </Typography>
      </Box>
      <Button 
        size='large'
        variant='contained'
        color='braceGreenLight'
        data-testid={'onboarding-button-next'}
        sx={{ py: 1, px: 4, fontSize: 'h6.fontSize', width: '66%', minWidth: '250px' }}
        onClick={handleNext}>
        See Supports
      </Button>
    </Box>
  );
}

