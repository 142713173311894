import config from '../../config';

const LogLevel = Object.freeze({
  Trace: 0,
  Debug: 1,
  Info: 2,
  Warn: 3,
  Error: 4
});

// AI values
// export enum SeverityLevel
//     {
//         Verbose = 0,
//         Information = 1,
//         Warning = 2,
//         Error = 3,
//         Critical = 4,
//     }

class Logger {
  constructor(aiLogger){
    this.aiLogger = aiLogger;
  }
  log(level, message){
    if(level < config.logLevel)
      return;

    switch(level){
    case LogLevel.Trace:
      aiLogger.log('TRACE : ' + message);
      break;
    case LogLevel.Debug:
      this.aiLogger.trackTrace({
        message: message,
        severityLevel:0
      });

      if(config.consoleLoggingEnabled)
        console.log(`DEBUG : ${message}`);

      break;
    case LogLevel.Info:
      this.aiLogger.trackTrace({
        message: message,
        severityLevel:1
      });

      if(config.consoleLoggingEnabled)
        console.log(`INFO : ${message}`);

      break;
    case LogLevel.Warn:
      this.aiLogger.trackTrace({
        message: message,
        severityLevel:2
      });

      if(config.consoleLoggingEnabled)
        console.warn('WARN : ' + message);

      break;
    case LogLevel.Error:
      this.aiLogger.trackTrace({
        message: message,
        severityLevel:3
      });
        
      if(config.consoleLoggingEnabled)
        console.error('ERROR : ' + message);

      break;
    }
  }
  info(message){
    this.log(LogLevel.Info, message);
  }
  warning(message){
    this.log(LogLevel.Warn, message);
  }
  error(message, error){
    this.log(LogLevel.Error, message);
    this.aiLogger.trackException({ exception: error });
  }
}

export {
  Logger,
  LogLevel
};