import React, { useContext } from 'react';

import {
  Box,
  Typography
} from '@mui/material';
import StarRateIcon from '@mui/icons-material/StarRate';

import { LoadableText } from '@components/atoms';
import { AccountOnboardingContext } from '@context';
import AccountOnboardingLoadingBar from './loading-bar';

export default function OnboardingBanner(props) {
  const { openDialog } = props;
  var { accountOnboarding, isLoadingAccountOnboarding } = useContext(AccountOnboardingContext);
  if(accountOnboarding == null || accountOnboarding?.isComplete === true){
    return <></>;
  }

  return (
    <Box data-testid='account-onboarding-banner'>
      <AccountOnboardingLoadingBar />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: 2 }}
        onClick={() => {
          if(openDialog){
            openDialog();
          }

        }}>
        <Box>
          <Typography variant='h5' component='div' fontWeight='medium'>
            <LoadableText minWidth='27px' isLoading={isLoadingAccountOnboarding} text={`${accountOnboarding.completeCount}/${accountOnboarding.totalCount}`} />
          </Typography>
          
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant='h5' fontWeight='medium'>
            Become A Brace Pro
          </Typography>
          
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row-reverse', justifyContent:'center', lineHeight: 1 }}>
          <StarRateIcon sx={{ fontSize: 30 }} />
          {/* <Icon trigger='none'
            type='svg/crown'
            size='30px'
            secondaryColor={theme.palette.braceGreenDark.main}
            forceDark={true}/> */}
        </Box>
      </Box>
    </Box>
  );
}