import React, { useContext, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter, useParams } from 'react-router';

import moment from 'moment';

import { loadUser } from './../../actions/users';
import { refreshNotifications } from './../../actions/notifications';
import { openDialog } from './../../actions/check-ins';
import { getProfile, getStats, getCheckIns } from './../../actions/profiles';
import { EventsContext } from '@context/events';

import { UserContext } from '@context/user';

import NewHome from './new';

const Index = (props) => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const { trackEvent } = useContext(EventsContext);
  const { user: userFromContext } = useContext(UserContext);

  useEffect(() => {
    if(userFromContext){
      if(userFromContext.status == 'PENDING')
        props.history.push('/index');

      trackEvent('Home:Open');
    }
  }, [userFromContext]);

  useEffect(() => {
    props.loadUser()
      .then((user) => {
        if(user || id){ 
          dispatch(getProfile(id ?? user.id));
          dispatch(getStats(id ?? user.id));
        }

        if(id === undefined){
          dispatch(refreshNotifications(moment().toISOString(), 0));
        }
      });
  }, [userFromContext, id]);

  return <NewHome />;
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadUser:() => dispatch(loadUser()),
    openDialog:() => dispatch(openDialog()),
    getCheckIns:() => dispatch(getCheckIns())
  };
};

const IndexContainer = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Index));

export default IndexContainer;