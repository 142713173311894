import jwt_decode from 'jwt-decode';

export const SAVE_USER = 'SAVE_USER';
export const LOG_OUT_USER = 'LOG_OUT_USER';

export const loadUser = () => {
  return(dispatch, getState, services) => {
    if(getState().user.id){
      return Promise.resolve(getState().user);
    }

    return services.storage.get('user')
      .then((user) => {
        if(user){
          var jwtToken = jwt_decode(user.token);
          if(jwtToken.exp  < Math.floor(Date.now() / 1000)){
            services.logger.info(`Expired Token. User : ${user.id}`);
            return null;
          }

          dispatch(saveUser(user));
          return user;
        }
        else{
          return null;
        }
      });
  };
};

export const saveFeedback = (like, change, nps) => {
  return(dispatch, getState, services) => {
    var user = getState().user;
    return new Promise((resolve, reject) => {
      setTimeout(()=>{
        services.logger.info(`User Feedback: UserId:${user.id} Like: ${like}, Change: ${change}, NPS: ${nps}`);
        resolve();
      }, 500);
    });
  };
};

export const saveOnboardingOption = (selection) => {
  return(dispatch, getState, services) => {
    var user = getState().user;
    return new Promise((resolve, reject) => {
      setTimeout(()=>{
        services.logger.info(`Onboarding Option Selection:${selection}, UserId:${user.id}, UserType:${user.role}`);
        resolve();
      }, 500);
    });
  };
};

export const updateUser = (updatedUser) => {
  return(dispatch, getState, services) => {
    var user = getState().user;
    return new Promise((resolve) => {
      updatedUser.token = user.token;
      services.storage.save('user', updatedUser)
        .then(() => {
          dispatch(saveUser(updatedUser));
          resolve();
        });
    });
  };
};

export const signIn = (email, password) => {
  return(dispatch, getState, services) => {
    return new Promise((resolve, reject) => {
      services.apiClient.signIn(email, password)
        .then(user => {
          services.storage.save('user', user)
            .then(() => {
              dispatch(saveUser(user));
              resolve();
            });
        })
        .catch(error => {
          reject(error);
        });
    });
  };
};

export const resetPassword = (email) => {
  return(dispatch, getState, services) => {
    return new Promise((resolve, reject) => {
      services.logger.info(`Password Reset Requested, Email: ${email}`);
    });
  };
};

export const logOut = () => {
  return(dispatch, getState, services) => {
    dispatch({
      type:LOG_OUT_USER
    });
    return services.storage.delete('user');
  };
};

export const deleteAccount = (feedback) => {
  return(dispatch, getState, services) => {
    var user = getState().user;
    return services.apiClient.deleteAccount(user, feedback)
      .then(() => {
        return services.storage.delete('user');
      });
  };
};

export const blockUser = (userId) => {
  return(dispatch, getState, services) => {
    var user = getState().user;
    return new Promise((resolve, reject) => {
      return services.apiClient.blockUser(user, userId)
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });};
};

export const report = (type, id, reason) => {
  return(dispatch, getState, services) => {
    services.logger.info(`Report: Type:${type}, Id: ${id}, Reason:${reason}`);
  };
};

export const saveInjury = (type, activityType, date) => {
  return(dispatch, getState, services) => {
    var user = getState().user;
    return services.apiClient.saveInjury(user, type, activityType, date)
      .then((updatedUser) => {
        updatedUser.token = user.token;
        services.storage.save('user', updatedUser)
          .then(() => {
            dispatch(saveUser(updatedUser));
          });
      });
  };
};

export const saveUser = (user) => {
  return {
    type:SAVE_USER,
    user
  };
};