import React from 'react';
import { Link } from 'react-router-dom';

import { 
  Box,
  Avatar,
  Skeleton,
  Badge
} from '@mui/material';

import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';

import config from './../../config';

export default function BraceAvatar(props) {
  return (
    <>
      { !props.userId  && 
        <Avatar 
          sx={{ width: props.size, height: props.size, borderRadius: 1 }} >
          <PersonIcon fontSize={ props.iconSize ?? 'large'}/>
        </Avatar>
      }
      { props.userId  && 
      <Box>
        {props.isLoading ? (
          <Skeleton variant="rectangular" sx={{ borderRadius: 2 }}>
            <Avatar sx={{ width: props.size, height: props.size, borderRadius: 1 }}/>
          </Skeleton>
        ) : (
          <Link to={`/profile/${props.userId}`}>
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              badgeContent={
                props.userType === 'PROFESSIONAL' ? 
                  <AddIcon sx={{ color: '#fff', fontSize: props.size ? props.size / 4 : 14, backgroundColor: 'primary.main', borderRadius: 1 }} />:
                  <></>
              }
            >
              <Avatar 
                src={props.hasNoImage ? '' : `${config.profileImagesBaseUrl}images/user-profile-image-${props.userId}`}
                sx={{ width: props.size, height: props.size, borderRadius: 1 }}
                imgProps={{ loading: 'lazy', decoding:'async' }} >
                <PersonIcon fontSize={ props.iconSize ?? 'large'}/>
              </Avatar>
            </Badge>
            
          </Link>
        )}
      </Box>
      }
    </>
  );
}