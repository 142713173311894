import { SAVE_USER, LOG_OUT_USER } from './../actions/users';
import { SET_PROFILE_IMAGE_MODIFIED } from './../actions/profiles';

export default function usersReducer(state = { userImageModified: new Date().getTime() }, action) {
  switch (action.type) {
  case SAVE_USER:
    state = {
      ...state, 
      ...action.user
    };
    return state;
  case LOG_OUT_USER:
    state = { userImageModified: new Date().getTime() };
    return state;
  case SET_PROFILE_IMAGE_MODIFIED:
    state = {
      ...state, 
      userImageModified: new Date().getTime()
    };
  default:
    return state;
  }
}