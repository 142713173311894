import config from '@config';

class AppConfig {
  constructor() {
    this.version = '0.0.2';
    this.defaultInputsEnabled = config.defaultInputsEnabled;
    this.apiBaseUrl = config.apiBaseUrl;
    this.profileImagesBaseUrl = config.profileImagesBaseUrl;
    this.appInsightsKey = config.appInsightsKey;
    this.consoleLoggingEnabled = config.consoleLoggingEnabled;
    this.mixPanelKey = config.mixPanelKey;
    this.logLevel = config.logLevel;
  }
} 

export default new AppConfig();