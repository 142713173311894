export const OPEN_MODAL = 'OPEN_MODAL';
export const CLEAR_MODAL = 'CLEAR_MODAL';

export const OPEN_COUNTDOWN_TIMER_MODAL = 'OPEN_COUNTDOWN_TIMER_MODAL';
export const CLEAR_COUNTDOWN_TIMER_MODAL = 'CLEAR_COUNTDOWN_TIMER_MODAL';

export const OPEN_NEW_POST_MODAL = 'OPEN_NEW_POST_MODAL';
export const CLOSE_NEW_POST_MODAL = 'CLOSE_NEW_POST_MODAL';

export const OPEN_NEW_GOAL_MODAL = 'OPEN_NEW_GOAL_MODAL';
export const CLOSE_NEW_GOAL_MODAL = 'CLOSE_NEW_GOAL_MODAL';

export const openNewPostModal = ({ action, metadata }) => {
  return {
    type: OPEN_NEW_POST_MODAL,
    metadata,
    action
  };
};

export const closeNewPostModal = () => {
  return {
    type: CLOSE_NEW_POST_MODAL
  };
};

export const openSuccessModal = (title, message, icon, action) => {
  return(dispatch, getState, services) => {
    return dispatch(openModal('SUCCESS', title, message, icon, action));
  };
};

export const openModal = (modalType, title, message, icon, action) => {
  return {
    type: OPEN_MODAL,
    modalType,
    title,
    message,
    icon,
    action
  };
};

export const openCountdownTimerModal = (initialTime) => {
  return {
    type: OPEN_COUNTDOWN_TIMER_MODAL,
    initialTime
  };
};

export const closeCountdownTimerModal = () => {
  return {
    type: CLEAR_COUNTDOWN_TIMER_MODAL
  };
};

export const openNewGoalModal = (userId) => {
  return {
    type: OPEN_NEW_GOAL_MODAL,
    userId
  };
};

export const closeNewGoalModal = () => {
  return {
    type: CLOSE_NEW_GOAL_MODAL
  };
};

export const closeModal = () => {
  return {
    type: CLEAR_MODAL
  };
};