import mixpanel from 'mixpanel-browser';

import { calculateSessionCompletionPercentage } from './../utils/completion-calculator';

export const SET_RECOVERY_DAILY_DATA = 'SET_RECOVERY_DAILY_DATA';
export const SET_RECOVERY_DAILY_DATE = 'SET_RECOVERY_DAILY_DATE';
export const DELETE_SESSION = 'DELETE_SESSION';
export const DELETE_PROGRAM = 'DELETE_PROGRAM';
export const SET_PLANS = 'SET_PLANS';
export const SET_PROGRAMS = 'SET_PROGRAMS';
export const SET_PROGRAMS_LOADING = 'SET_PROGRAMS_LOADING';
export const COMPLETE_SET = 'COMPLETE_SET';
export const SET_EXERCISE_SENTIMENT = 'SET_EXERCISE_SENTIMENT';
export const PROGRAM_UPDATED = 'PROGRAM_UPDATED';

export const loadRecoveryDailyProgress = (date) => {
  return(dispatch, getState, services) => {
    return new Promise((resolve, reject) => {
      dispatch(setRecoveryDate(date));
      return services.apiClient.searchSessions(getState().user, date.clone().startOf('day').toISOString())
        .then((sessions) => {
          dispatch(setRecoveryData(sessions.results));
        });
    });
  };
};

export const loadPlans = () => {
  return(dispatch, getState, services) => {
    var user = getState().user;

    return services.apiClient.listPlans(user)
      .then((plans) => {
        // Not going to update plans if they are already in the state
        if(plans.length !== getState().recovery.plans.length)
          dispatch(setPlans(plans.results));
      });
  };
};

export const loadPrograms = (id) => {
  return(dispatch, getState, services) => {
    var user = getState().user;

    dispatch({ type: SET_PROGRAMS_LOADING });

    if(!id){
      id = user.id;
    }

    return services.apiClient.getPrograms(user, id)
      .then((programs) => {

        if(programs.results.length > 0){
          dispatch(setPrograms(
            programs.results.sort(function(a,b){
              return new Date(b.startDate) - new Date(a.startDate);
            }))
          );
        }
        else{
          dispatch(setPrograms([]));
        }
      });
  };
};

export const createSession = (planId, date) => {
  return(dispatch, getState, services) => {
    if(!date){
      date = getState().recovery.currentDate.startOf('day').toISOString();
    }else{
      date = date.startOf('day').toISOString();
    }

    return services.apiClient.createSessions(getState().user, planId, date)
      .then((session) => {
        mixpanel.track('Recovery:Session:Created');
        dispatch(loadRecoveryDailyProgress(getState().recovery.currentDate));
      });
  };
};

export const createProgram = (name, planId, startDate, endDate, daysOfWeek, timesPerDay) => {
  return(dispatch, getState, services) => {
    return services.apiClient.createProgram(
      getState().user, 
      name, 
      planId, 
      startDate.startOf('day').toISOString(), 
      endDate.startOf('day').toISOString(), 
      daysOfWeek,
      timesPerDay)
      .then((session) => {
        mixpanel.track('Recovery:Program:Created');
        dispatch(loadRecoveryDailyProgress(getState().recovery.currentDate));
      });
  };
};

export const updateProgram = (programId, name, startDate, endDate, daysOfWeek, timesPerDay) => {
  return(dispatch, getState, services) => {
    return services.apiClient.updateProgram(
      getState().user, 
      programId,
      name, 
      startDate.startOf('day').toISOString(), 
      endDate.startOf('day').toISOString(), 
      daysOfWeek,
      timesPerDay)
      .then((program) => {
        mixpanel.track('Recovery:Program:Updated');
        dispatch(programUpdated(program));
        dispatch(loadRecoveryDailyProgress(getState().recovery.currentDate));
      });
  };
};

export const requestDeleteSession = (sessionId) => {
  return(dispatch, getState, services) => {
    return new Promise((resolve, reject) => {
      return services.apiClient.deleteSession(getState().user, sessionId)
        .then(() =>{
          dispatch(deleteSession(sessionId));
          resolve();
        });
    });
  };
};

export const requestDeleteProgram = (programId) => {
  return(dispatch, getState, services) => {
    return new Promise((resolve, reject) => {
      return services.apiClient.deleteProgram(getState().user, programId)
        .then(() =>{
          dispatch(deleteProgram(programId));
          resolve();
        });
    });
  };
};

export const toggleCompleteSet = (sessionId, exerciseDefinitionId, setNumber) => {
  return(dispatch, getState, services) => {
    mixpanel.track('Recovery:Session:Set:Completed');
    var session = getState().recovery.sessions.filter(s => s.id === sessionId)[0];
    var wasSessionComplete = calculateSessionCompletionPercentage(session) === 100;
    dispatch(completeSet(sessionId, exerciseDefinitionId, setNumber));
    return services.apiClient.toggleCompleteSet(getState().user, sessionId, exerciseDefinitionId, setNumber)
      .then(() => {
        return !wasSessionComplete && 
          calculateSessionCompletionPercentage(getState().recovery.sessions.filter(s => s.id === sessionId)[0]) === 100;
      });
  };
};

export const completeAllSets = (sessionId, exerciseDefinitionId) => {
  return(dispatch, getState, services) => {
    mixpanel.track('Recovery:Session:Set:CompleteAll');
    
    var session = getState().recovery.sessions.filter(s => s.id === sessionId)[0];
    var wasSessionComplete = calculateSessionCompletionPercentage(session) === 100;
    var exercise = session.plan.exercises.filter(e => e.definition.id == exerciseDefinitionId)[0];
    var promises = [];
    exercise.sets.forEach(set => {
      if(!set.isComplete){
        dispatch(completeSet(sessionId, exerciseDefinitionId, set.number));
        promises.push(services.apiClient.toggleCompleteSet(getState().user, sessionId, exerciseDefinitionId, set.number));
      }
    });
    return Promise.all(promises)
      .then(() => {
        return !wasSessionComplete && 
          calculateSessionCompletionPercentage(getState().recovery.sessions.filter(s => s.id === sessionId)[0]) === 100;
      });
  };
};

export const saveExerciseSentiment = (sessionId, exerciseDefinitionId, sentiment) => {
  return(dispatch, getState, services) => {
    return services.apiClient.saveExerciseSentiment(getState().user, sessionId, exerciseDefinitionId, sentiment)
      .then(() => {
        mixpanel.track('Recovery:Exercise:Sentiment:Created');
        dispatch(setExerciseSentiment(sessionId, exerciseDefinitionId, sentiment));
      });
  };
};

export const setRecoveryData = (sessions) => {
  return {
    type:SET_RECOVERY_DAILY_DATA,
    sessions
  };
};

export const setRecoveryDate = (date) => {
  return {
    type:SET_RECOVERY_DAILY_DATE,
    date
  };
};

export const setPlans = (plans) => {
  return {
    type:SET_PLANS,
    plans
  };
};

export const setPrograms = (programs) => {
  return {
    type:SET_PROGRAMS,
    programs
  };
};

export const programUpdated = (program) => {
  return {
    type:PROGRAM_UPDATED,
    program
  };
};

export const deleteSession = (sessionId) => {
  return {
    type:DELETE_SESSION,
    sessionId
  };
};

export const deleteProgram = (programId) => {
  return {
    type:DELETE_PROGRAM,
    programId
  };
};

export const completeSet = (sessionId, exerciseDefinitionId, setNumber) => {
  return {
    type:COMPLETE_SET,
    sessionId,
    exerciseDefinitionId,
    setNumber
  };
};

export const setExerciseSentiment = (sessionId, exerciseDefinitionId, sentiment) => {
  return {
    type:SET_EXERCISE_SENTIMENT,
    sessionId,
    exerciseDefinitionId,
    sentiment
  };
};

