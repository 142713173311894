import { createStore, applyMiddleware, combineReducers } from 'redux';

import usersReducer from './reducers/users';
import checkInsReducer from './reducers/check-ins';
import recoveryReducer from './reducers/recovery';
import profilesReducer from './reducers/profiles';
import communityReducer from './reducers/community';
import notificationsReducer from './reducers/notifications';
import modalReducer from './reducers/modal';
import diaryReducer from './reducers/diary';
import appReducer from './reducers/app';

import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import createServices from './services';

let store = createStore(
  combineReducers({
    user: usersReducer,
    checkIn: checkInsReducer,
    recovery: recoveryReducer,
    profile: profilesReducer,
    community: communityReducer,
    notifications: notificationsReducer,
    modal: modalReducer,
    diary: diaryReducer,
    app: appReducer
  }),
  composeWithDevTools(
    applyMiddleware(thunk.withExtraArgument(createServices()))
  )
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;