import { HIDE_APP_BAR, SHOW_APP_BAR } from './../actions/app';

export default function appReducer(state = {  }, action) {
  switch (action.type) {
  case HIDE_APP_BAR:
    state = {
      ...state, 
      isAppBarHidden: true
    };
    return state;
  case SHOW_APP_BAR:
    state = {
      ...state, 
      isAppBarHidden: false
    };
    return state;
  default:
    return state;
  }
}