import React from 'react';

import {
  Box,
  Button,
  Stack
}from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@emotion/react';

export default function Buttons(props) {
  const { next, nextText, isNextDisabled, isNextLoading, back, backText, isBackDisabled } = props;
  const theme = useTheme();

  var activeButtons = 0;
  if(next){
    activeButtons += 1;
  }
  if(back){
    activeButtons += 1;
  }

  return (
    <Box sx={{ 
      position: 'absolute', 
      bottom: 'calc(env(safe-area-inset-bottom))', 
      paddingBottom: 1,
      width: '100%' }}>
      <Stack direction='row' spacing={1} sx={{ width: '100%', justifyContent: 'center' }}>
       
        {back && 
        <Button 
          disabled={isBackDisabled}
          size="large"
          sx={{ width: activeButtons == 1 ? '50%' : '40%', backgroundColor: theme.palette.background.default }}
          onClick={back}
          color='secondary'
          variant='outlined'>
          {backText ?? 'Back'}
        </Button>
        }
        {next && 
        <LoadingButton 
          size="large"
          data-testid='recovery-create-session-next-button'
          disabled={isNextDisabled}
          loading={isNextLoading}
          sx={{ width: activeButtons == 1 ? '50%' : '40%' }}
          onClick={next}
          color='primary'
          variant='contained'>
          {nextText ?? 'Next'}
        </LoadingButton>
        }
       
      </Stack>
    </Box>
  );
}

